import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import { useStore } from 'react-redux';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function FacilitiesSupport({
  fetchFacilities,
  eventId,
  handleSetup,
  handleParking,
  handleFacilityPresence,
  handleBreakdown,
  handleOvernight,
  handleLocationSetup,
  handleAddToArray,
  handleRemoveLocation
}) {
  const store = useStore();
  const state = store?.getState();
  const accessToken = state?.authentication.userAuth.jwt;
  const [locationArray, setLocationArray] = useState([]);
  const [rooms, setRooms] = useState();
  const [value, setValue] = useState(0);
  const [submitting] = useState(false);
  const [setup, setSetup] = useState(false);
  const [presence, setPresence] = useState(false);
  const [breakdown, setBreakdown] = useState(false);
  const [overnight, setOvernight] = useState(false);
  const [parking, setParking] = useState(false);
  const [detailsCompleted] = useState(true);
  const [locationSetups, setLocationSetups] = useState([
    {
      locationId: '',
      instructions: '',
      includeSandwichSign: false
    }
  ]);

  const addAnotherLocation = () => {
    setLocationArray([
      ...locationArray,
      { id: locationArray.length + 1, value: Math.floor(Math.random()) }
    ]);
    locationSetups[locationArray.length] = {
      locationId: null,
      instructions: null,
      includeSandwichSign: false
    };
    setLocationSetups(locationSetups);
    handleAddToArray();
  };

  const removeLocation = (index) => {
    locationArray.splice(index, 1);
    setLocationArray([...locationArray]);
    locationSetups.splice(index, 1);
    handleRemoveLocation();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: 'https://forms-dev.winsor.edu/api/events/location', // move to .env
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(function (response) {
      if (response.status === 200) {
        setRooms(response.data);
      }
    });
  }, []);

  useEffect(() => {
    if (eventId && fetchFacilities) {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      };
      fetch(
        `https://forms-dev.winsor.edu/api/events/${eventId}/facilities`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setSetup(data.setup);
          setBreakdown(data.breakdown);
          setOvernight(data.overnight);
          setParking(data.parking);
          setLocationSetups(data.locations);
          setLocationArray(data.locations);
          setPresence(data.presence);
        });
    }
    // eslint-disable-next-line
  }, []);

  if (!rooms) {
    return (
      <Box sx={{ width: 300, position: 'absolute', left: '45%', top: '48%' }}>
        <Box marginLeft={4}>
          <CircularProgress />
        </Box>
        <Box>
          <Typography>Please be patient...</Typography>
        </Box>
      </Box>
    );
  } else {
    return (
      <Stack
        sx={{
          width: 'fit-content',
          border: 'solid 1px lightgray',
          borderRadius: 4,
          padding: 3
        }}
        margin={2}>
        <Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            marginBottom={3}>
            <Box>
              <Checkbox
                checked={setup}
                value={setup}
                onChange={(e) => {
                  handleSetup(e.target.checked);
                  setSetup(e.target.checked);
                }}
              />
            </Box>
            <Box>
              <Typography>Require Setup</Typography>
            </Box>
            <Box>
              <Checkbox
                checked={parking}
                value={parking}
                onChange={(e) => {
                  setParking(e.target.checked);
                  handleParking(e.target.checked);
                }}
              />
            </Box>
            <Box>
              <Typography>Require Parking Attendant</Typography>
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            marginBottom={3}>
            <Box>
              <Checkbox
                checked={presence}
                value={presence}
                onChange={(e) => {
                  handleFacilityPresence(e.target.checked);
                  setPresence(e.target.checked);
                }}
              />
            </Box>
            <Box>
              <Typography>Require Presence During Event</Typography>
            </Box>
            <Box>
              <Checkbox
                checked={overnight}
                value={overnight}
                onChange={(e) => {
                  setOvernight(e.target.checked);
                  handleOvernight(e.target.checked);
                }}
              />
            </Box>
            <Box>
              <Typography>This is an Overnight Event</Typography>
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            marginBottom={3}>
            <Box>
              <Checkbox
                checked={breakdown}
                value={breakdown}
                onChange={(e) => {
                  handleBreakdown(e.target.checked);
                  setBreakdown(e.target.checked);
                }}
              />
            </Box>
            <Box>
              <Typography>Require Breakdown After Event</Typography>
            </Box>
          </Box>
          {submitting &&
          !breakdown &&
          !overnight &&
          !presence &&
          !parking &&
          !setup ? (
            <Typography fontSize={9} color={'red'}>
              At least one option should be selected
            </Typography>
          ) : null}
        </Box>
        <Box>
          <Button onClick={(e) => addAnotherLocation(e)}>
            Add a Location with Set Up Instructions or Sandwich Sign
          </Button>
          {locationArray.length > 0 &&
            locationArray.map((array, index1) => {
              if (array !== null) {
                return (
                  <Box key={index1}>
                    <Box>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label='basic tabs example'>
                          <Tab label='On Campus Location' {...a11yProps(0)} />
                          {/* <Tab
                            label="New On Campus Location"
                            {...a11yProps(1)}
                          /> */}
                        </Tabs>
                      </Box>
                      <TabPanel value={value} index={0}>
                        <Box>
                          <Autocomplete
                            disablePortal
                            id='combo-box-demo'
                            onChange={(event, newValue) => {
                              setLocationSetups(
                                locationSetups?.map((oneLocation, index) => {
                                  if (index === index1) {
                                    handleLocationSetup(
                                      newValue?.id,
                                      'locationId'
                                    );
                                    return {
                                      ...oneLocation,
                                      locationId: newValue?.id
                                    };
                                  } else {
                                    return oneLocation;
                                  }
                                })
                              );
                            }}
                            value={rooms.find(
                              (option) =>
                                option.id === locationSetups[index1]?.locationId
                            )}
                            options={rooms}
                            sx={{ width: 300 }}
                            getOptionLabel={(option) => {
                              if (typeof option === 'string') {
                                return option;
                              }
                              if (option.label) {
                                return option.label;
                              }
                              return option.label;
                            }}
                            renderOption={(props, option) => (
                              <li {...props}>{option.label}</li>
                            )}
                            renderInput={(params) => (
                              <TextField {...params} label='' />
                            )}
                          />
                        </Box>
                        {submitting &&
                        detailsCompleted &&
                        !locationSetups[index1].instructions ? (
                          <Typography fontSize={12} color={'red'}>
                            Must include instructions and Location
                          </Typography>
                        ) : null}
                      </TabPanel>
                      <TabPanel value={value} index={1}></TabPanel>
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        marginBottom={3}>
                        <Checkbox
                          checked={locationSetups[index1].includeSandwichSign}
                          value={locationSetups[index1].includeSandwichSign}
                          onChange={(e) => {
                            setLocationSetups(
                              locationSetups?.map((oneLocation, index) => {
                                if (index === index1) {
                                  handleLocationSetup(
                                    e.target.checked,
                                    'includeSandwichSign',
                                    index
                                  );
                                  return {
                                    ...oneLocation,
                                    includeSandwichSign: e.target.checked
                                  };
                                } else {
                                  return oneLocation;
                                }
                              })
                            );
                          }}
                        />
                        <Box>
                          <Typography>
                            Need a Sandwich Sign (Text in Instructions) Location
                            Setup Instructions (Mandatory)
                          </Typography>
                        </Box>
                      </Box>
                      <Box marginTop={2} marginBottom={2}>
                        <textarea
                          className='description'
                          cols={50}
                          rows={5}
                          style={{
                            fontFamily: `'Times New Roman', Times, serif`,
                            fontSize: 'large',
                            backgroundColor: '#f3f5f8',
                            borderRadius: '12px',
                            padding: '15px',
                            border: 'solid 1px lightgray'
                          }}
                          value={locationSetups[index1]?.instructions || ''}
                          onChange={(e) => {
                            setLocationSetups(
                              locationSetups?.map((oneLocation, index) => {
                                if (index === index1) {
                                  handleLocationSetup(
                                    e.target.value,
                                    'instructions',
                                    index
                                  );
                                  return {
                                    ...oneLocation,
                                    instructions: e.target.value
                                  };
                                } else {
                                  return oneLocation;
                                }
                              })
                            );
                          }}
                        />
                      </Box>

                      {submitting &&
                      detailsCompleted &&
                      !locationSetups[index1].instructions ? (
                        <Typography fontSize={12} color={'red'}>
                          Must include instructions
                        </Typography>
                      ) : null}
                    </Box>

                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      marginBottom={3}>
                      <Button
                        onClick={() => removeLocation(index1)}
                        startIcon={<DeleteIcon />}
                        variant='outlined'
                        color='error'>
                        Remove Location
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        onClick={(e) => addAnotherLocation(e)}
                        variant='contained'>
                        Add Another Location
                      </Button>
                    </Box>
                  </Box>
                );
              } else {
                return 'No Array';
              }
            })}
        </Box>
      </Stack>
    );
  }
}

export default FacilitiesSupport;
