import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function a11yProps(index) {
  return {
    id: `student-calendar-tab-tab-${index}`,
    'aria-controls': `student-calendar-tab-panel-${index}`
  };
}

export const StudentCalendarTab = ({ onToggle }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onToggle(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='Student Calendar Tab'>
          <Tab label='Monthly View' {...a11yProps(0)} />
          <Tab label='Weekly View' {...a11yProps(1)} />
        </Tabs>
      </Box>
    </Box>
  );
};
