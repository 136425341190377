import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React, { useEffect, useState } from 'react';
import MiddleHeader from '../../../components/headers/MiddleHeader';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import AttachmentIcon from '@mui/icons-material/Attachment';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import FacilitiesSupport from '../../../components/forms/support-forms/FacilitiesSupport';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorDialog from '../../../components/dialogs/ErrorDialog';
import { UpsertFacility } from '../../../helpers/HttpRequestHelpers';
import Autocomplete from '@mui/material/Autocomplete';
import { useStore } from 'react-redux';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 2, ml: 2 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function RentalForm() {
  const store = useStore();
  const state = store?.getState();
  const accessToken = state?.authentication.userAuth.jwt;

  const [submitting, setSubmitting] = useState();
  const [eventDetails, setEventDetails] = useState();
  const [eventName, setEventName] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [locationArray, setLocationArray] = useState([]);
  const [rooms, setRooms] = useState();
  const [roomDynamic, setRoomDynamic] = useState([]);
  const [room, setRoom] = useState();
  const [customDynamicRoom, setCustomDyamicRoom] = useState([]);
  const [roomValidation, setRoomValidation] = useState(false);
  const [customRoomValidation, setCustomRoomValidation] = useState(false);
  const [locationValue, setLocationValue] = useState(0);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedItemIds, setSelectedItemsIds] = useState([]);
  const [custom, setCustom] = useState();
  const [customName, setCustomName] = useState();
  const [isPublic, setIsPublic] = useState(false);
  const [file, setFile] = useState();
  const [leader, setLeader] = useState('');
  const [employees, setEmployees] = useState();
  const [contacts, setContacts] = useState();
  const [divisionHead, setDivisionHead] = useState();
  const [preaprovalDate, setPreaprovalDate] = useState();
  const [attendeeCount, setAttendeeCount] = useState(0);
  const [showSubmitError, setShowSubmitError] = useState(false);
  const [menu, setMenu] = useState([]);
  const [hasFacilitiesInfo, sethasFacilitiesInfo] = useState(false);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [serverErrorStatus, setServerErrorStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [serverError, setServerError] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [fetchFacilities, setFetchFacilities] = useState(false);
  /* Facilities Support */
  const [setup, setSetup] = useState(false);
  const [facilityPresence, setFacilityPresence] = useState(false);
  const [breakdown, setBreakdown] = useState(false);
  const [overnight, setOvernight] = useState(false);
  const [parking, setParking] = useState(false);
  const [locationArrayFacility, setLocationArrayFacility] = useState([]);
  const [locationSetups, setLocationSetups] = useState([
    {
      locationId: '',
      instructions: '',
      includeSandwichSign: false
    }
  ]);

  let { eventId } = useParams();
  const navigate = useNavigate();

  const handleSetup = (e) => {
    setSetup(e);
  };
  const handleParking = (e) => {
    setParking(e);
  };
  const handleFacilityPresence = (e) => {
    setFacilityPresence(e);
  };
  const handleBreakdown = (e) => {
    setBreakdown(e);
  };
  const handleOvernight = (e) => {
    setOvernight(e);
  };
  const handleAddToArray = (e) => {
    setLocationArrayFacility([
      ...locationArrayFacility,
      {
        id: locationArrayFacility.length + 1,
        value: Math.floor(Math.random())
      }
    ]);
    locationSetups[locationArrayFacility.length] = {
      locationId: '',
      instructions: '',
      includeSandwichSign: false
    };
    setLocationSetups(locationSetups);
  };
  const handleRemoveLocation = (index) => {
    locationArrayFacility.splice(index, 1);
    setLocationArrayFacility([...locationArrayFacility]);
    locationSetups.splice(index, 1);
  };
  const handleLocationSetup = (e, element) => {
    locationArrayFacility.map((item, indexOutter) => {
      if (element === 'locationId') {
        setLocationSetups(
          locationSetups?.map((oneLocation, indexId) => {
            if (indexId === indexOutter) {
              return {
                ...oneLocation,
                locationId: e
              };
            } else {
              return oneLocation;
            }
          })
        );
      }
      if (element === 'instructions') {
        setLocationSetups(
          locationSetups?.map((oneLocation, indexId) => {
            if (indexId === indexOutter) {
              return {
                ...oneLocation,
                instructions: e
              };
            } else {
              return oneLocation;
            }
          })
        );
      }
      if (element === 'includeSandwichSign') {
        setLocationSetups(
          locationSetups?.map((oneLocation, indexId) => {
            if (indexId === indexOutter) {
              return {
                ...oneLocation,
                includeSandwichSign: e
              };
            } else {
              return oneLocation;
            }
          })
        );
      }
      return null;
    });
    return null;
  };

  const handleChange = (value, newValue, find) => {
    locationArray.map((row, index) => {
      if (index === find) {
        setLocationValue(newValue);
      }
      return null;
    });
  };

  const handleSetFile = (event) => {
    setFile(event.target?.files[0]);
  };

  const handleFileUpload = () => {
    const formData = new FormData();
    formData.append('file', file);

    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      body: formData
    };

    fetch(
      `https://forms-dev.winsor.edu/api/events/${eventId}/file-upload`,
      requestOptions
    )
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
    loadData();
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    axios({
      method: 'get',
      url: 'https://forms-dev.winsor.edu/api/events/location/custom', // move to .env
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(function (response) {
      if (response.status === 200) {
        setCustom(response.data);
      }
    });
  }, []);

  const loadData = () => {
    if (eventId) {
      axios({
        method: 'get',
        url: `https://forms-dev.winsor.edu/api/events/${eventId}`, // move to .env
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }).then(function (response) {
        if (response.status === 200) {
          setEventDetails(response.data);
          setEventName(response.data?.summary);
          setDescription(response.data?.description);
          setLeader(response.data.leaderId);
          response.data.selectedLocations.length >
          response.data.selectedCustomLocations.length
            ? setLocationArray(response.data.selectedLocations)
            : setLocationArray(response.data.selectedCustomLocations);
          setRoomDynamic(response.data.selectedLocations);
          setCustomDyamicRoom(response.data.selectedCustomLocations);
          setStartDate(response.data.start.substring(0, 10));
          setStartTime(response.data.start.substring(11, 16));
          setEndDate(response.data.end.substring(0, 10));
          setEndTime(response.data.end.substring(11, 16));
          setPreaprovalDate(response.data.preaprovalDate);
          setFetchFacilities(response.data.hasFacilitiesInfo);
          sethasFacilitiesInfo(response.data.hasFacilitiesInfo);
          setDivisionHead(true);
        }
      });
    }
  };

  const handleRoomChange = (event, index) => {
    selectedLocations[index] = event.id;
    setSelectedItemsIds(selectedLocations);
    roomDynamic[index] = event.id;
    setRoomDynamic(roomDynamic);
  };

  const handleSecondRoomChange = (event, index) => {
    customDynamicRoom[index] = event.id;
    setCustomDyamicRoom(customDynamicRoom);
  };

  const addAnotherLocation = () => {
    setLocationArray([
      ...locationArray,
      {
        id: locationArray.length + 1
      }
    ]);
    roomDynamic[locationArray.length] = '';
    setRoom(roomDynamic);
    customDynamicRoom[locationArray.length] = '';
    setCustomDyamicRoom(customDynamicRoom);
  };

  const handleSaveCustomLocation = (e) => {
    const data = new URLSearchParams();
    data.append('name', customName);
    data.append('isPublic', isPublic);
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(data)
    };

    fetch(`https://forms-dev.winsor.edu/api/events/location`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((err) => console.log(err));
  };

  const removeLocation = (index) => {
    locationArray.splice(index, 1);
    setLocationArray([...locationArray]);
    selectedLocations.splice(index, 1);
    setSelectedLocations(selectedLocations);
    roomDynamic.splice(index, 1);
    setRoomDynamic(roomDynamic);
    customDynamicRoom.splice(index, 1);
    setCustomDyamicRoom(customDynamicRoom);
  };

  const handleSubmit = () => {
    console.log('submitting');
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: 'https://forms-dev.winsor.edu/api/events/location', // move to .env
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(function (response) {
      if (response.status === 200) {
        setRooms(response.data);
      }
    });
  }, []);

  useEffect(() => {
    axios({
      method: 'get',
      url: 'https://forms-dev.winsor.edu/api/users/employees', // move to .env
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(function (response) {
      if (response.status === 200) {
        setEmployees(response.data);
      }
    });
  }, []);

  useEffect(() => {
    axios({
      method: 'get',
      url: 'https://forms-dev.winsor.edu/api/users/self/contacts', // move to .env
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(function (response) {
      if (response.status === 200) {
        setContacts(response.data);
      }
    });
  }, []);

  const submit = () => {
    var data = {
      summary: eventName,
      description: description,
      type: 'Rental',
      start: startDate + 'T' + startTime + ':00',
      end: endDate + 'T' + endTime + ':00',
      creatorId: localStorage.getItem('currentUserId'),
      leaderId: leader,
      preaprovalDate: preaprovalDate,
      attendeeCount: parseInt(attendeeCount),
      selectedLocations: selectedLocations,
      selectedCustomLocations:
        customDynamicRoom[0] !== '' ? customDynamicRoom : []
    };
    setSubmitting(true);

    if (
      divisionHead &&
      eventName &&
      description &&
      startDate &&
      endDate &&
      leader &&
      preaprovalDate &&
      endTime &&
      startTime
    ) {
      if (eventId && localStorage.getItem('is_template') === 'false') {
        setShowLoading(true);

        const requestOptions = {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify(data)
        };
        fetch(
          `https://forms-dev.winsor.edu/api/events/${eventId}`,
          requestOptions
        )
          .then((response) => {
            if (response.status !== 200) {
              setShowLoading(true);
              setServerErrorStatus(response.status);
              handleClickOpen();
              return response.json();
            } else {
              setShowLoading(true);
              return response.json();
            }
          })
          .then((res) => {
            setServerError(
              serverErrorStatus === 500
                ? 'An email detailing the problem has been sent to you.'
                : res.error
            );
            if (hasFacilitiesInfo && locationSetups[0].locationId) {
              upsertFacilities(res.id);
            }
            completeUpdate(res.id);
          })
          .catch((error) => console.log('err ', error));
      } else {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify(data)
        };
        fetch(`https://forms-dev.winsor.edu/api/events`, requestOptions)
          .then((response) => {
            if (response.status !== 200) {
              setShowLoading(true);
              setServerErrorStatus(response.status);
              handleClickOpen();
              return response.json();
            } else {
              setShowLoading(true);
              return response.json();
            }
          })
          .then((res) => {
            setServerError(
              serverErrorStatus === 500
                ? 'An email detailing the problem has been sent to you.'
                : res.error
            );
            if (hasFacilitiesInfo && locationSetups[0].locationId) {
              upsertFacilities(res.id);
            }
            completeSubmission(res.id);
          })
          .catch((error) => console.log('err ', error));
      }
      if (file) {
        handleFileUpload();
      }
      setShowSubmitError(false);
    } else {
      setShowSubmitError(true);
    }
  };

  const upsertFacilities = (eventId) => {
    const args = {
      setup: setup,
      breakdown: breakdown,
      overnight: overnight,
      parking: parking,
      locationSetups: locationSetups,
      presence: facilityPresence
    };
    UpsertFacility(args, eventId);
  };

  const completeUpdate = (id) => {
    if (id) {
      axios({
        method: 'get',
        url: `https://forms-dev.winsor.edu/api/events/${id}/complete-update`, // move to .env
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }).then(function (response) {
        if (response.status === 200) {
          setShowLoading(false);
          navigate('/event-forms');
        }
      });
    }
  };
  const completeSubmission = (id) => {
    if (id) {
      axios({
        method: 'get',
        url: `https://forms-dev.winsor.edu/api/events/${id}/complete-submission`, // move to .env
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }).then(function (response) {
        if (response.status === 200) {
          setShowLoading(false);
          navigate('/event-forms');
        }
      });
    }
  };

  const handleClickOpen = () => {
    setShowLoading(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!contacts || !employees || !rooms || !menu || showLoading) {
    return (
      <Box sx={{ width: 300, position: 'absolute', left: '45%', top: '48%' }}>
        <Box marginLeft={4}>
          <CircularProgress />
        </Box>
        <Box>
          <Typography>Please be patient...</Typography>
        </Box>
      </Box>
    );
  } else {
    return (
      <Grid>
        <MiddleHeader title={eventName ? eventName : 'Rental'} variant='h4' />
        <MiddleHeader title='Basic Event Details' />
        <ErrorDialog
          handleClose={handleClose}
          serverError={serverError}
          open={open}
        />
        <Stack marginBottom={10}>
          <Stack>
            <Box
              sx={{
                border: 'solid 1px lightgray',
                padding: 3,
                borderRadius: 4,
                width: '90%'
              }}
              marginBottom={2}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                marginBottom={3}>
                <Box width={'10%'}>
                  <Typography marginRight={5}>
                    Event Name<span style={{ color: 'red' }}>*</span>
                  </Typography>
                  {submitting && !eventName ? (
                    <Typography fontSize={9} color={'red'}>
                      Required
                    </Typography>
                  ) : null}
                </Box>
                <TextField
                  margin='normal'
                  required
                  id='event-name'
                  style={{
                    width: '55%',
                    borderColor: 'lightgray',
                    fontFamily: `'Times New Roman', Times, serif`,
                    backgroundColor: '#f3f5f8'
                  }}
                  autoFocus
                  size='small'
                  value={eventName || ''}
                  onChange={(e) => setEventName(e.target.value)}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                marginBottom={3}>
                <Box width={'10%'}>
                  <Typography marginRight={5}>
                    Description<span style={{ color: 'red' }}>*</span>
                  </Typography>
                  {submitting && !description ? (
                    <Typography fontSize={9} color={'red'}>
                      Required
                    </Typography>
                  ) : null}
                </Box>
                <textarea
                  className='description'
                  style={{
                    fontFamily: `'Times New Roman', Times, serif`,
                    fontSize: 'large',
                    backgroundColor: '#f3f5f8',
                    borderRadius: '12px',
                    padding: '15px',
                    border: 'solid 1px lightgray'
                  }}
                  cols={95}
                  rows={10}
                  onChange={(e) => setDescription(e.target.value)}
                  value={description || ''}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                marginBottom={1}
                marginLeft={'10%'}>
                <Typography>Start Date</Typography>
                <Box width={'15%'} marginRight={3}>
                  <input
                    type='date'
                    className='date'
                    value={startDate || ''}
                    style={{ backgroundColor: '#f3f5f8' }}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                      setEndDate(e.target.value);
                    }}
                  />
                  {submitting && !startDate ? (
                    <Typography fontSize={9} color={'red'}>
                      Required
                    </Typography>
                  ) : null}
                </Box>
                <Typography>Time</Typography>
                <Box width={'15%'}>
                  <input
                    type='time'
                    className='time'
                    value={startTime || ''}
                    style={{ backgroundColor: '#f3f5f8' }}
                    onChange={(e) => {
                      setStartTime(e.target.value);
                    }}
                  />
                  {submitting && !startTime ? (
                    <Typography fontSize={9} color={'red'}>
                      Required
                    </Typography>
                  ) : null}
                </Box>
                <Typography>End Date</Typography>
                <Box width={'15%'} marginRight={3}>
                  <input
                    type='date'
                    className='date'
                    value={endDate || ''}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                  {submitting && !endDate ? (
                    <Typography fontSize={9} color={'red'}>
                      Required
                    </Typography>
                  ) : null}
                </Box>
                <Typography>Time</Typography>
                <Box width={'15%'}>
                  <input
                    type='time'
                    className='time'
                    value={endTime || ''}
                    style={{ backgroundColor: '#f3f5f8' }}
                    onChange={(e) => setEndTime(e.target.value)}
                  />
                  {submitting && !endTime ? (
                    <Typography fontSize={9} color={'red'}>
                      Required
                    </Typography>
                  ) : null}
                </Box>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                marginBottom={3}>
                <Button onClick={(e) => addAnotherLocation(e)}>
                  Add Location
                </Button>
              </Box>
              {locationArray?.length > 0 &&
                locationArray.map((array, index) => {
                  if (array !== null) {
                    return (
                      <Box
                        sx={{
                          border: 'solid 1px lightgray',
                          borderRadius: '4px',
                          marginBottom: 2,
                          width: '63%'
                        }}
                        marginBottom={3}
                        key={index}>
                        <Box sx={{ width: '92%', padding: 3 }}>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                              value={locationValue}
                              onChange={(e, y) => handleChange(e, y, index)}
                              aria-label='basic tabs example'>
                              <Tab
                                label='On Campus Location'
                                {...a11yProps(0)}
                              />
                              <Tab
                                label='My Custom Locations'
                                {...a11yProps(1)}
                              />
                              <Tab
                                label='New Custom Location'
                                {...a11yProps(2)}
                              />
                            </Tabs>
                          </Box>
                          <TabPanel value={locationValue} index={0}>
                            <Stack direction={'row'}>
                              <Autocomplete
                                disablePortal
                                id='combo-box-demo'
                                onChange={(event, newValue) => {
                                  setRoomValidation(false);
                                  handleRoomChange(newValue, index);
                                }}
                                value={rooms.find(
                                  (option) => option.id === roomDynamic[index]
                                )}
                                options={rooms}
                                sx={{ width: 300 }}
                                getOptionLabel={(option) => {
                                  if (typeof option === 'string') {
                                    return option;
                                  }
                                  if (option.label) {
                                    return option.label;
                                  }
                                  return option.label;
                                }}
                                renderOption={(props, option) => (
                                  <li {...props}>{option.label}</li>
                                )}
                                renderInput={(params) => (
                                  <TextField {...params} label='' />
                                )}
                              />
                              <Box marginLeft={2}>
                                <Button
                                  onClick={(e) => {
                                    if (roomDynamic[index]) {
                                      setRoomValidation(false);
                                      // addLocationRoom(e, index);
                                      handleSubmit();
                                    } else {
                                      setRoomValidation(true);
                                    }
                                  }}>
                                  Add Location
                                </Button>
                              </Box>
                            </Stack>
                            {roomValidation ? (
                              <Box>
                                <div style={{ color: 'red' }}>Required!</div>
                              </Box>
                            ) : null}
                          </TabPanel>
                          <TabPanel value={locationValue} index={1}>
                            <Stack direction={'row'}>
                              <Autocomplete
                                disablePortal
                                id='combo-box-demo'
                                onChange={(event, newValue) => {
                                  setCustomRoomValidation(false);
                                  handleSecondRoomChange(newValue, index);
                                }}
                                value={custom.find(
                                  (option) =>
                                    option.id === customDynamicRoom[index]
                                )}
                                options={custom}
                                sx={{ width: 300 }}
                                getOptionLabel={(option) => {
                                  if (typeof option === 'string') {
                                    return option;
                                  }
                                  if (option.label) {
                                    return option.label;
                                  }
                                  return option.label;
                                }}
                                renderOption={(props, option) => (
                                  <li {...props}>{option.label}</li>
                                )}
                                renderInput={(params) => (
                                  <TextField {...params} label='' />
                                )}
                              />
                              <Box marginLeft={2}>
                                <Button
                                  onClick={(e) => {
                                    if (customDynamicRoom[index]) {
                                      setCustomRoomValidation(false);
                                      handleSubmit();
                                    } else {
                                      setCustomRoomValidation(true);
                                    }
                                  }}>
                                  Add Location
                                </Button>
                              </Box>
                            </Stack>
                            {customRoomValidation ? (
                              <Box>
                                <div style={{ color: 'red' }}>Required!</div>
                              </Box>
                            ) : null}
                          </TabPanel>
                          <TabPanel value={locationValue} index={2}>
                            <Box
                              display={'flex'}
                              flexDirection={'row'}
                              alignItems={'center'}
                              marginBottom={1}>
                              <Box width={'30%'}>
                                <Typography component={'div'} marginRight={5}>
                                  Location Name
                                </Typography>
                              </Box>
                              <TextField
                                margin='normal'
                                required
                                id='event-name'
                                style={{
                                  width: '55%',
                                  borderColor: 'lightgray'
                                }}
                                autoFocus
                                size='small'
                                // value={customName}
                                onChange={(e) => setCustomName(e.target.value)}
                              />
                            </Box>
                            <Box
                              display={'flex'}
                              flexDirection={'row'}
                              alignItems={'center'}
                              marginBottom={1}></Box>
                            <Box
                              display={'flex'}
                              flexDirection={'row'}
                              alignItems={'center'}
                              marginBottom={1}>
                              <Box>
                                <Checkbox
                                  label='Label'
                                  value={isPublic}
                                  onChange={(e) =>
                                    setIsPublic(e.target.checked)
                                  }
                                />
                                {/* {techSupport ? (
                                    <Typography component={'div'}>Form </Typography>
                                  ) : null}{' '} */}
                              </Box>
                              <Box>
                                <Typography component={'div'}>
                                  Make This Location Public
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              display={'flex'}
                              flexDirection={'row'}
                              alignItems={'center'}
                              justifyContent={'center'}
                              marginBottom={1}>
                              <Button
                                size='small'
                                onClick={(e) => handleSaveCustomLocation(e)}>
                                Add Location Info
                              </Button>
                            </Box>
                          </TabPanel>
                        </Box>
                        <Box
                          sx={{ width: '50%' }}
                          marginBottom={1}
                          marginLeft={5}>
                          <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            marginBottom={1}>
                            <Button
                              size='small'
                              onClick={() => removeLocation(index)}
                              startIcon={<DeleteIcon />}
                              color='error'
                              variant='outlined'>
                              Remove Location
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    );
                  } else {
                    return 'No Array';
                  }
                })}
            </Box>
            <Box
              sx={{
                border: 'solid 1px lightgray',
                padding: 3,
                borderRadius: 4,
                width: '90%'
              }}
              marginBottom={2}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                marginBottom={1}>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginBottom={1}>
                  <Box width={'50%'}>
                    <Typography component={'div'}>Attachment</Typography>
                  </Box>
                  <Box width={'300px'}>
                    <input
                      variant='outlined'
                      size='small'
                      onChange={(e) => handleSetFile(e)}
                      type='file'
                      className='file-upload'
                      accept='image/png, image/jpeg, .pdf, .txt, .doc, docx'
                    />
                  </Box>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginBottom={1}
                  marginLeft={17}>
                  <Box width={'100%'}>
                    <Typography component={'div'}>
                      Existing Attachment
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  border: 'solid 1px lightgray',
                  borderRadius: '4px',
                  width: 'fit-content',
                  padding: '15px',
                  marginBottom: 3,
                  marginTop: 3
                }}>
                {eventDetails?.attachments.length > 0
                  ? eventDetails?.attachments.map((files, fileIndex) => {
                      return (
                        <Box
                          key={fileIndex}
                          display={'flex'}
                          flexDirection={'row'}
                          alignItems={'center'}
                          marginBottom={1}>
                          <Box marginRight={1}>
                            <AttachmentIcon sx={{ color: 'GrayText' }} />{' '}
                          </Box>
                          <Box>
                            <div>{files.fileName}</div>
                          </Box>
                        </Box>
                      );
                    })
                  : null}
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                marginBottom={3}>
                <Box width={'12%'}>
                  <Typography>Leader</Typography>
                </Box>
                <Box>
                  <Autocomplete
                    disablePortal
                    id='combo-box-demo'
                    onChange={(event, newValue) => setLeader(newValue?.id)}
                    value={employees.find((option) => option.id === leader)}
                    options={employees}
                    sx={{ width: 300 }}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.firstName + option.lastName) {
                        return option.firstName + ' ' + option.lastName;
                      }
                      return option.firstName + ' ' + option.lastName;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>
                        {option.firstName + ' ' + option.lastName}
                      </li>
                    )}
                    renderInput={(params) => <TextField {...params} label='' />}
                  />
                </Box>
                {submitting && !leader ? (
                  <Typography fontSize={9} color={'red'}>
                    Required
                  </Typography>
                ) : null}
                <Box width={'100%'} marginLeft={25}>
                  <Button>Add a second Leader</Button>
                </Box>
              </Box>
              <Box
                sx={{
                  border: 'solid 1px lightgray',
                  width: 'fit-content',
                  padding: '3px',
                  borderRadius: '5px',
                  backgroundColor: '#F0F7FF',
                  height: '40px',
                  wordWrap: '-moz-initial'
                }}
                marginBottom={3}>
                <Typography marginTop={1} marginLeft={1}>
                  Note that Event Forms will not be sent for approval until Room
                  use has been cleared by the Registrar
                </Typography>
              </Box>
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Box>
                  <Checkbox
                    label='Label'
                    value={divisionHead}
                    checked={divisionHead}
                    onChange={(e) => setDivisionHead(e.target.checked)}
                  />
                </Box>
                <Box>
                  <Typography>Division Head Approved</Typography>
                </Box>
                <Box>
                  <Checkbox label='Label' disabled={true} checked={true} />
                </Box>
                <Box marginRight={8}>
                  <Typography>Room Use Approved</Typography>
                </Box>
                <Box width={'fit-content'}>
                  <Typography>Date of Calendar Approval</Typography>
                </Box>
                <Box marginRight={2}>
                  <input
                    type='date'
                    className='date'
                    value={preaprovalDate || Date()}
                    style={{ backgroundColor: '#f3f5f8' }}
                    onChange={(e) => {
                      setPreaprovalDate(e.target.value);
                    }}
                  />
                </Box>
                {submitting && !preaprovalDate ? (
                  <Typography fontSize={9} color={'red'}>
                    Required
                  </Typography>
                ) : null}
                <Box marginRight={1} marginLeft={1}>
                  <Typography>Expected Number of Attendees</Typography>
                </Box>
                <Box>
                  <TextField
                    margin='normal'
                    required
                    id='event-name'
                    style={{ width: '100%', borderColor: 'lightgray' }}
                    autoFocus
                    type={'number'}
                    size='small'
                    value={
                      attendeeCount
                        ? attendeeCount
                        : eventDetails?.attendeeCount || ''
                    }
                    onChange={(e) => setAttendeeCount(e.target.value)}
                  />
                </Box>
              </Box>
              {submitting && !divisionHead ? (
                <Typography fontSize={10} color={'red'} marginLeft={2}>
                  Division Head Approval Required
                </Typography>
              ) : null}
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    value={hasFacilitiesInfo || false}
                    checked={hasFacilitiesInfo || false}
                    onChange={(e) => {
                      sethasFacilitiesInfo(e.target.checked);
                    }}
                  />
                }
                label='Add Facility Info'
              />
            </Box>
            {hasFacilitiesInfo ? (
              <Box>
                <MiddleHeader title='Facilities Details' variant='h4' />
                <FacilitiesSupport
                  eventId={eventId}
                  fetchFacilities={fetchFacilities}
                  handleSetup={handleSetup}
                  handleParking={handleParking}
                  handleFacilityPresence={handleFacilityPresence}
                  handleBreakdown={handleBreakdown}
                  handleOvernight={handleOvernight}
                  handleLocationSetup={handleLocationSetup}
                  handleAddToArray={handleAddToArray}
                  handleRemoveLocation={handleRemoveLocation}
                />
              </Box>
            ) : null}

            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              marginBottom={4}>
              {' '}
              <Box>
                <Button variant='outlined' onClick={() => submit()}>
                  Submit
                </Button>
              </Box>
              {submitting && showSubmitError ? (
                <Typography fontSize={9} color={'red'}>
                  Must Complete all Required Fields
                </Typography>
              ) : null}
            </Grid>
          </Stack>
        </Stack>
      </Grid>
    );
  }
}

export default RentalForm;
