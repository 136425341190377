import {
  Checkbox,
  Grid,
  Stack,
  Typography,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import MiddleHeader from '../../headers/MiddleHeader';
import VirtualForm from './VirtualForm';
import { useStore } from 'react-redux';

function TechnologySupport({
  eventId,
  fetchTech,
  showZoom,
  handlePresence,
  handleEquipment,
  handleHelp,
  handleDetails,
  submitting,
  handleRegistration,
  handleChatEnabled,
  handleQAEnabled,
  handleRecording,
  handleRemider,
  handleTranscript,
  handleRegistrantList,
  handleZoomLink,
  handleQASupportPerson,
  handleContactId,
  handlePanelList
}) {
  const store = useStore();
  const state = store?.getState();
  const accessToken = state?.authentication.userAuth.jwt;

  const [details, setDetails] = useState({
    presence: false,
    equipment: false,
    help: false,
    details: ''
  });
  const [hasZoom, setHasZoom] = useState(false);

  useEffect(() => {
    if (showZoom) {
      setHasZoom(true);
    }
    if (eventId && fetchTech) {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      };
      fetch(
        `https://forms-dev.winsor.edu/api/events/${eventId}/technology`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setDetails({
            ...details,
            presence: data.presence,
            equipment: data.equipment,
            help: data.help,
            details: data.details
          });
        })
        .catch((err) => {
          console.log('error: ', err);
        });
    }
    // eslint-disable-next-line
  }, [eventId]);

  return (
    <Grid>
      <MiddleHeader title={'Technology Details'} variant='h5' />

      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        marginBottom={1}
        sx={{
          border: 'solid 1px lightgray',
          width: 'fit-content',
          height: 'fit-content',
          borderRadius: 4,
          padding: 3
        }}>
        <Stack>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            marginBottom={1}>
            <Checkbox
              label='Label'
              checked={details?.help || false}
              value={details?.help || undefined}
              onChange={(e) => {
                setDetails({ ...details, help: e.target.checked });
                handleHelp(e.target.checked);
              }}
            />
            <Box>
              <Typography>Require Help from Tech</Typography>
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            marginBottom={1}>
            <Checkbox
              label='Label'
              checked={details?.presence || false}
              value={details?.presence || undefined}
              onChange={(e) => {
                setDetails({ ...details, presence: e.target.checked });
                handlePresence(e.target.checked);
              }}
            />
            <Box>
              <Typography>
                Require A Tech Person Present (Requires explicit approval)
              </Typography>
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            marginBottom={1}>
            <Checkbox
              label='Label'
              checked={details?.equipment || false}
              value={details?.equipment || undefined}
              onChange={(e) => {
                setDetails({ ...details, equipment: e.target.checked });
                handleEquipment(e.target.checked);
              }}
            />
            <Box>
              <Typography>
                Require Specific Equipment (Include in Details)
              </Typography>
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            marginBottom={1}>
            <Box margin={3}>
              <textarea
                className='description'
                style={{
                  border: 'solid 1px lightgray',
                  borderRadius: '12px',
                  backgroundColor: '#f3f5f8'
                }}
                cols={50}
                rows={7}
                value={details?.details || ''}
                onChange={(e) => {
                  setDetails({ ...details, details: e.target.value });
                  handleDetails(e.target.value);
                }}
              />
              {submitting && !details.details ? (
                <Typography fontSize={9} color={'red'}>
                  Notes Required!
                </Typography>
              ) : null}
            </Box>
          </Box>
        </Stack>
      </Box>
      {hasZoom ? (
        <Box>
          <VirtualForm
            eventId={eventId}
            showZoom={showZoom}
            handleRegistration={handleRegistration}
            handleChatEnabled={handleChatEnabled}
            handleQAEnabled={handleQAEnabled}
            handleRecording={handleRecording}
            handleRemider={handleRemider}
            handleTranscript={handleTranscript}
            handleRegistrantList={handleRegistrantList}
            handleZoomLink={handleZoomLink}
            handleQASupportPerson={handleQASupportPerson}
            handleContactId={handleContactId}
            handlePanelList={handlePanelList}
          />
        </Box>
      ) : null}
      <Box>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={hasZoom}
                onChange={(e) => {
                  setHasZoom(e.target.checked);
                }}
              />
            }
            label='This Event Has a Zoom Component'
          />
        </FormGroup>
      </Box>
    </Grid>
  );
}

export default TechnologySupport;
