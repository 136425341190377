import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

function Master() {
  return (
    <Grid justifyContent={'center'} alignItems={'center'} width={'100%'}>
      <Box sx={{ width: 300, position: 'absolute', left: '45%', top: '48%' }}>
        <Typography variant="h4">Coming Soon!</Typography>
      </Box>
    </Grid>
  );
}

export default Master;
