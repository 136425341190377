import { Box, Stack, Typography, Button } from '@mui/material';
import React from 'react';

function MyAssessments({
  myAssessments,
  showAssessmentDetails,
  deleteAssessment,
  onEditAssessment
}) {
  return (
    <Box>
      {myAssessments?.map((top, topIndex) => {
        return (
          <div key={topIndex} style={{ marginBottom: '7%' }}>
            <Stack direction={'row'} marginBottom={2}>
              <Box minWidth={'300px'}>
                <Typography>
                  <strong>{top.course}</strong>
                </Typography>
              </Box>
              <Stack direction={'row'} marginTop={-1}>
                <Button onClick={() => onEditAssessment(top?.course)}>
                  Edit
                </Button>
                <Button color='error' onClick={() => deleteAssessment(top.id)}>
                  Delete
                </Button>
              </Stack>
            </Stack>
            {myAssessments[topIndex]?.assessments.map((item, index) => {
              return (
                <div key={index}>
                  <Stack
                    direction={'row'}
                    style={{
                      border: 'solid 1px lightgray',
                      borderRadius: '12px',
                      padding: '10px',
                      marginBottom: '12px'
                    }}>
                    <Stack>
                      <Stack direction={'row'} marginBottom={2}>
                        <Box marginRight={2}>
                          <span>
                            <strong>Date:</strong>{' '}
                          </span>
                          <span
                            style={{ cursor: 'pointer', color: '#1976d2' }}
                            onClick={() => showAssessmentDetails(item)}>
                            {new Date(item.assessmentDateTime).toDateString()}
                          </span>
                        </Box>
                        <Box marginRight={6} marginLeft={6}>
                          <Typography>
                            <strong>Overlaps:</strong>{' '}
                          </Typography>
                        </Box>
                        <Box marginRight={2}>
                          <span>{item.student?.student.conflictCount}</span>
                        </Box>
                      </Stack>

                      <Stack direction={'row'}>
                        <Box marginRight={2} minWidth={'120px'}>
                          <span>
                            <strong>Block:</strong> {item.block}{' '}
                          </span>
                        </Box>
                        {/* <Box marginLeft={4} marginTop={-1}>
                          <Button variant="outlined" sx={{ height: '30px' }}>
                            Mark Late
                          </Button>
                        </Box> */}
                      </Stack>
                    </Stack>
                  </Stack>
                </div>
              );
            })}
          </div>
        );
      })}
    </Box>
  );
}

export default MyAssessments;