import dayjs from 'dayjs';
import { days } from '../screens/assessment-calendar/AssessmentCalendarStyles';

const isWeekDay = (dateOfMonth) => {
  if (!dateOfMonth) {
    return dateOfMonth;
  }
  const dateToArray = dateOfMonth
    .toDate()
    .toDateString()
    .toLowerCase()
    .split(' ');
  const dayOfWeek = dateToArray[0];
  const isAvailable = ['sun', 'sat'].includes(dayOfWeek);
  return !isAvailable;
};

export const generateDate = (
  month = dayjs().month(),
  year = dayjs().year()
) => {
  const firstDateOfMonth = dayjs().year(year).month(month).startOf('month');
  const lastDateOfMonth = dayjs().year(year).month(month).endOf('month');

  const arrayOfDate = [];

  // generate prefix
  for (let i = 1; i < firstDateOfMonth.day(); i++) {
    arrayOfDate.push({ date: firstDateOfMonth.day(i), show: false });
  }

  // generate current date
  for (let i = firstDateOfMonth.date(); i <= lastDateOfMonth.date(); i++) {
    const isWeekday = isWeekDay(firstDateOfMonth.date(i));
    isWeekday &&
      arrayOfDate.push({ date: firstDateOfMonth.date(i), show: true });
  }

  // generate suffix
  // if the length is less than the 25 then add the difference of 25
  // if the length is equals to 25 then add 0
  // if the length is great the 25 then add the negative value of the difference
  // thus the goal is to keep the calendar in 5x5 matrix form
  const remaining =
    arrayOfDate.length < 25
      ? 25 - arrayOfDate.length
      : arrayOfDate.length === 25
      ? 0
      : -(arrayOfDate.length - 25);

  for (
    let i = lastDateOfMonth.date() + 1;
    i <= lastDateOfMonth.date() + remaining;
    i++
  ) {
    arrayOfDate.push({ date: lastDateOfMonth.date(i), show: false });
  }

  return arrayOfDate;
};

// to decomision
export const range = (end) => {
  const { result } = Array.from({ length: end }).reduce(
    ({ result, current }) => ({
      result: [...result, current],
      current: current + 1
    }),
    { result: [], current: 1 }
  );

  return result;
};
export const getDaysInMonth = (month, year) => {
  return new Date(year, month + 1, 0).getDate();
};

export const getSortedDays = (month, year) => {
  const dayIndex = new Date(year, month, 1).getDay();
  return [...days.slice(dayIndex), ...days.slice(0, dayIndex)];
};

export const getDateObj = (day, month, year) => {
  return new Date(year, month, day);
};

export const areDatesTheSame = (first, second) => {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
};

export const getAssessmentStartEnd = (month, year) => {
  console.log('current month', month, year);
};