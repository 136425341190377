/* eslint-disable no-unused-vars */
/* eslint-disable  */
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import { useStore } from 'react-redux';

function VirtualForm({
  eventId,
  showZoom,
  handleRegistration,
  handleChatEnabled,
  handleQAEnabled,
  handleRemider,
  handleTranscript,
  handleRegistrantList,
  handleZoomLink,
  handleQASupportPerson,
  handleContactId,
  handlePanelList,
  submitting,
  handleDeleteContact
}) {
  const store = useStore();
  const state = store?.getState();
  const accessToken = state?.authentication.userAuth.jwt;
  const [registration, setRegistration] = useState(false);
  const [chatEnabled, setChatEnabled] = useState(false);
  const [qaEnabled, setQaEnabled] = useState(false);
  const [recording, setRecording] = useState(false);
  const [reminder, setReminder] = useState(false);
  const [transcript, setTranscript] = useState(false);
  const [registrantList, setRegistrantList] = useState(false);
  const [zoomLink, setZoomLink] = useState(false);
  const [qaSupportPerson, setqaSupportPerson] = useState('');
  const [qaSupportPersonCheckbox, setQaSupportPersonCheckbox] = useState(false);
  const [hostContactId, sethostContactId] = useState('');
  const [panelistIds, setpanelistIds] = useState([]);
  const [contacts, setContacts] = useState();
  const [webinar, setWebinar] = useState(false);
  const [anotherContact, setAnotherContact] = useState(false);
  const [contactList, setContactList] = useState([]);

  const addAnotherContact = () => {
    setAnotherContact(!anotherContact);
  };

  const deleteContact = (event, index) => {
    console.log('event ', event);
    panelistIds?.splice(index, 1);
    setpanelistIds(panelistIds);
    sethostContactId('');
    handleDeleteContact(index);
    setContactList(contactList.filter((contact) => contact !== event));
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: 'https://forms-dev.winsor.edu/api/users/self/contacts', // move to .env
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(function (response) {
      if (response.status === 200) {
        setContacts(response.data);
        console.log('contacts ', contacts);
      }
    });
  }, []);

  useEffect(() => {
    if (eventId && showZoom) {
      axios({
        method: 'get',
        url: `https://forms-dev.winsor.edu/api/events/${eventId}/technology/virtual-event`, // move to .env
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }).then(function (response) {
        if (response.status === 200) {
          setWebinar(response.data?.webinar);
          setRegistration(response.data?.registration);
          setChatEnabled(response.data?.chatEnabled);
          setQaEnabled(response.data?.qaEnabled);
          setRecording(response.data?.recording);
          setReminder(response.data?.reminder);
          setTranscript(response.data?.transcript);
          setRegistrantList(response.data?.registrantList);
          setZoomLink(response.data?.zoomLink);
          setqaSupportPerson(response.data?.qaSupportPerson);
          sethostContactId(response.data.hostContact?.id);
          setpanelistIds(response.data?.panelistIds);
        }
      });
    }
  }, []);
  return (
    <Grid
      sx={{
        border: 'solid 1px lightgray',
        width: 'fit-content',
        padding: 3,
        borderRadius: 4
      }}>
      <Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          marginBottom={3}
          // marginLeft={40}
        >
          <Box>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={registration || false}
                    value={registration || false}
                    onChange={(e) => {
                      setRegistration(e.target.checked);
                      handleRegistration(e.target.checked);
                    }}
                  />
                }
                label='Participant Registration'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chatEnabled || false}
                    value={chatEnabled || false}
                    onChange={(e) => {
                      setChatEnabled(e.target.checked);
                      handleChatEnabled(e.target.checked);
                    }}
                  />
                }
                label='Chat Enabled'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={qaEnabled || false}
                    value={qaEnabled}
                    onChange={(e) => {
                      setQaEnabled(e.target.checked);
                      handleQAEnabled(e.target.checked);
                    }}
                  />
                }
                label='Q&A Enabled'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={qaSupportPersonCheckbox || false}
                    checked={qaSupportPersonCheckbox || false}
                    onChange={(e) => {
                      setQaSupportPersonCheckbox(e.target.checked);
                    }}
                  />
                }
                label='Q&A Support Person'
              />
            </FormGroup>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={zoomLink || false}
                    value={zoomLink || false}
                    onChange={(e) => {
                      setZoomLink(e.target.checked);
                      handleZoomLink(e.target.checked);
                    }}
                  />
                }
                label='Zoom Recording'
              />
              <FormControlLabel
                required
                control={
                  <Checkbox
                    checked={reminder || false}
                    value={reminder || false}
                    onChange={(e) => {
                      setReminder(e.target.checked);
                      handleRemider(e.target.checked);
                    }}
                  />
                }
                label='Registrant Reminder (24hr)'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={transcript || false}
                    value={transcript || false}
                    onChange={(e) => {
                      setTranscript(e.target.checked);
                      handleTranscript(e.target.checked);
                    }}
                  />
                }
                label='Keep Chat Transcript'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={registrantList || false}
                    value={registrantList || false}
                    onChange={(e) => {
                      setRegistrantList(e.target.checked);
                      handleRegistrantList(e.target.checked);
                    }}
                  />
                }
                label='List of Registrants'
              />
            </FormGroup>
          </Box>
        </Box>
        {qaSupportPersonCheckbox ? (
          <Box marginLeft={40}>
            <TextField
              label='Q&A Support Person'
              value={qaSupportPerson}
              onChange={(e) => {
                setqaSupportPerson(e.target.value);
                handleQASupportPerson(e.target.value);
              }}
            />
          </Box>
        ) : null}
        <Box marginTop={2}>
          <Typography variant='h6'>Select From Contact List</Typography>
        </Box>
        {!anotherContact ? (
          <Box>
            <Autocomplete
              disablePortal
              id='combo-box-demo'
              // value={hostContactId}
              onChange={(event, newValue) => {
                sethostContactId(newValue.id);
                handleContactId(newValue.id);
                handlePanelList(newValue.id);
                contactList.push(newValue.firstName + ' ' + newValue.lastName);
                setContactList(contactList);
              }}
              options={contacts}
              sx={{ width: 300 }}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                if (option?.label) {
                  return option?.firstName + ' ' + option?.lastName;
                }
                return option?.firstName + ' ' + option?.lastName;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option?.firstName + ' ' + option?.lastName}</li>
              )}
              renderInput={(params) => <TextField {...params} label='' />}
            />
            {submitting && !hostContactId ? (
              <Typography fontSize={12} color={'red'}>
                Contact Required!
              </Typography>
            ) : null}
            <Box marginBottom={3}>
              <Button onClick={(e) => addAnotherContact(e)}>New Contact</Button>
            </Box>
            <Box marginTop={2}>
              <Typography variant='h6'>Panelist</Typography>
            </Box>

            <Box
              style={{
                border: 'solid 1px lightgray',
                padding: 6,
                borderRadius: 4,
                marginBottom: 8
              }}
              width={'60%'}>
              {contactList.length > 0 ? (
                <div>
                  {contactList.map((c, i) => {
                    return (
                      <Box flexDirection={'column'} key={i}>
                        <Stack direction={'row'}>
                          <Box width={'90%'} padding={1}>
                            <label>{c}</label>
                          </Box>
                          <Box>
                            <Button
                              variant='text'
                              color='error'
                              onClick={() => {
                                //console.log('trying ', c, i);
                                deleteContact(c, i);
                              }}>
                              Delete
                            </Button>
                          </Box>
                        </Stack>
                      </Box>
                    );
                  })}
                </div>
              ) : (
                <Box>
                  <span style={{ color: 'lightgray' }}>No Contacts</span>
                </Box>
              )}
            </Box>
            <Box width={'15vw'} marginBottom={2} marginLeft={5}></Box>
          </Box>
        ) : null}
        {anotherContact ? (
          <Stack
            direction={'row'}
            sx={{
              border: 'solid 1px lightgray',
              padding: '10px',
              marginBottom: '10px',
              borderRadius: '4px'
            }}
            width={'50%'}>
            <Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                marginBottom={1}>
                <Box width={'40%'}>
                  <Typography marginRight={5}>First Name</Typography>
                </Box>
                <TextField
                  margin='normal'
                  required
                  id='event-name'
                  style={{ width: '55%', borderColor: 'lightgray' }}
                  autoFocus
                  size='small'
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                marginBottom={1}>
                <Box width={'40%'}>
                  <Typography marginRight={5}>Last Name</Typography>
                </Box>
                <TextField
                  margin='normal'
                  required
                  id='event-name'
                  style={{ width: '55%', borderColor: 'lightgray' }}
                  autoFocus
                  size='small'
                />
              </Box>
            </Box>
            <Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                marginBottom={1}>
                <Box width={'30%'}>
                  <Typography marginRight={5}>Email</Typography>
                </Box>
                <TextField
                  margin='normal'
                  required
                  id='event-name'
                  style={{ width: '55%', borderColor: 'lightgray' }}
                  autoFocus
                  size='small'
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                marginBottom={1}>
                <Box width={'30%'}>
                  <Typography marginRight={5}>Cellphone</Typography>
                </Box>
                <TextField
                  margin='normal'
                  required
                  id='event-name'
                  style={{ width: '55%', borderColor: 'lightgray' }}
                  autoFocus
                  size='small'
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                marginBottom={1}>
                <Checkbox />
                <Box>
                  <Typography>Keep Contact Private</Typography>
                </Box>
              </Box>
              <Box marginBottom={1}>
                <Button onClick={(e) => addAnotherContact(e)}>
                  Save New Contact
                </Button>
                <Button onClick={(e) => addAnotherContact(e)}>Cancel</Button>
              </Box>
            </Box>
          </Stack>
        ) : null}
      </Box>
    </Grid>
  );
}

export default VirtualForm;
