import { Box, Button, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { cleanUpAuthentication } from 'screens/login/slice';
import MiddleHeader from '../headers/MiddleHeader';
import Home from 'assets/images/home.png';
import { getUserRoles } from 'utils/permissions';

const MainNavigationBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roles = useSelector((state: any) => state?.authentication?.roles);
  const userProfile = useSelector(
    (state: any) => state?.authentication?.userProfile
  );
  const userRoles = getUserRoles(roles);

  const handleLogout = () => {
    dispatch(cleanUpAuthentication());
    navigate('/');
  };

  return (
    <Grid
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      marginBottom={10}>
      <Box sx={{ width: '40%', display: 'flex' }}>
        <a href='/home'>
          <img src={Home} alt={'Logo'} loading='lazy' width={50} />
        </a>
        <MiddleHeader title='Winsor Forms' variant='h4' color='#a21024' />
      </Box>
      <Grid
        container
        direction='row'
        justifyContent='flex-end'
        alignItems='baseline'>
        {userRoles?.isEventsAdmin ? (
          <Grid margin={2}>
            <Box style={{ color: '#a21024', cursor: 'pointer' }}>
              <a
                href='/events-admin'
                style={{ textDecoration: 'none', color: '#a21024' }}>
                <Typography>Events Admin</Typography>
              </a>
            </Box>
          </Grid>
        ) : null}
        {userRoles?.isEventsAdmin ? (
          <Grid margin={2}>
            <Box style={{ color: '#a21024' }}>
              <a
                href='/master-events'
                style={{ textDecoration: 'none', color: '#a21024' }}>
                <Typography>Master Events List</Typography>
              </a>
            </Box>
          </Grid>
        ) : null}
        <Grid>
          <Grid>
            <Typography>{userProfile?.firstName}</Typography>
          </Grid>
          <Grid
            container
            justifyContent={'flex-end'}
            alignItems='baseline'
            direction='row'
            marginTop={1}>
            <a href='/' style={{ textDecoration: 'none', color: '#a21024' }}>
              <Button
                variant='outlined'
                style={{ color: '#a21024', borderColor: '#a21024' }}
                size='small'
                onClick={handleLogout}>
                Logout
              </Button>
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MainNavigationBar;