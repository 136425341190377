import React, { useEffect, useState } from 'react';
import {
  Button,
  Box,
  Checkbox,
  Divider,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import Tabs from '@mui/material/Tabs';

import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useStore } from 'react-redux';
import 'screens/event-forms/generic-event-form/GenericEventForm.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function CateringSupport({
  budgetCodes,
  eventId,
  food,
  handleServers,
  handleCleanup,
  handleBudgetCode,
  handleAddToList
}) {
  const store = useStore();
  const state = store?.getState();
  const accessToken = state?.authentication.userAuth.jwt;

  const [showForm, setShowForm] = useState(false);
  const [foodData, setFoodData] = useState();
  const [value, setValue] = useState(0);
  const [servers, setServers] = useState(false);
  const [cleanup, setCleanup] = useState(false);
  const [budgetCode, setBudgetCode] = useState();
  const [budgetName, setBudgetName] = useState();
  const [budgetAccount, setBudgetAccount] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [menuSelections, setMenuSelections] = useState();

  const [cost, setCost] = useState(0);
  const [quantitiesFullList, setQuantitiesFullList] = useState({
    itemId: '',
    quantity: ''
  });

  const handleShowForm = () => {
    setShowForm(!showForm);
    // setBeverageArray(beverageArray);
  };
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const saveNewBudgetCode = (e) => {
    setSubmitting(true);
    if (budgetName && budgetAccount) {
      var data = {
        name: budgetName,
        accountNumber: budgetAccount
      };

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(data)
      };
      fetch(`https://forms-dev.winsor.edu/api/budget-codes`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log('err ', error));
    } else {
      alert('Submit an event first');
    }
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: 'https://forms-dev.winsor.edu/api/events/catering/menu', // move to .env
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(function (response) {
      if (response.status === 200) {
        setFoodData(response.data);
        var hold = response.data?.map((top, topIndex) => {
          <div key={topIndex}></div>;
          return top.items?.map((inner, innerIndex) => {
            <div key={innerIndex}></div>;
            return (quantitiesFullList.quantity = {
              itemId: inner.id,
              quantity: 0
            });
          });
        });
        setQuantitiesFullList(hold);
      }
    });
  }, []);

  useEffect(() => {
    if (food) {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      };
      fetch(
        `https://forms-dev.winsor.edu/api/events/${eventId}/catering`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // setBudgetCodes(data);
          setServers(data.servers);
          setCleanup(data.cleanup);
          setMenuSelections(data.menuSelections);
          setBudgetCode(data.budgetCode.codeId);
          setCost(data.cost);
        })
        .catch((err) => {
          throw Error(err.message);
        });
    }
  }, [food, eventId]);

  if (!foodData) {
    return (
      <Box sx={{ width: 300, position: 'absolute', left: '45%', top: '48%' }}>
        <Box marginLeft={4}>
          <CircularProgress />
        </Box>
        <Box>
          <Typography>Please be patient...</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Grid>
      <Stack direction={'column'}>
        <Box
          sx={{
            padding: 3,
            width: '50%',
          }}
          margin={2}>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              marginBottom={3}>
              <Checkbox
                label='Label'
                checked={servers}
                onChange={(e) => {
                  setServers(e.target.checked);
                  handleServers(e.target.checked);
                }}
              />
              <Box>
                <Typography>Servers Required</Typography>
              </Box>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              marginBottom={3}>
              <Checkbox
                label='Label'
                checked={cleanup}
                onChange={(e) => {
                  setCleanup(e.target.checked);
                  handleCleanup(e.target.checked);
                }}
              />
              <Box>
                <Typography>Cleanup Required</Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography variant='h5'>Budget Codes</Typography>
          </Box>
          {!showForm ? (
            <Box>
              <Box marginRight={2} marginBottom={2}>
                {' '}
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Age'
                  value={budgetCode || ''}
                  onChange={(e) => {
                    setBudgetCode(e.target.value);
                    handleBudgetCode(e.target.value);
                  }}
                  sx={{ width: 300 }}
                  size='small'>
                  {budgetCodes &&
                    budgetCodes?.map((e, index) => {
                      return (
                        <MenuItem
                          value={e.codeId}
                          key={
                            index
                          }>{`${e.name} ${e.accountNumber}`}</MenuItem>
                      );
                    })}
                </Select>
              </Box>

              <Button variant='outlined' onClick={(e) => handleShowForm(e)}>
                Add a New BudgetCode
              </Button>
            </Box>
          ) : null}
          {showForm ? (
            <>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                marginBottom={3}>
                <Box marginRight={2}>
                  <TextField
                    margin='normal'
                    id='event-name'
                    label='Name'
                    style={{ borderColor: 'lightgray' }}
                    autoFocus
                    onChange={(e) => setBudgetName(e.target.value)}
                  />
                </Box>
                <Box>
                  <TextField
                    margin='normal'
                    id='event-name'
                    label='Account Number'
                    style={{ borderColor: 'lightgray' }}
                    autoFocus
                    onChange={(e) => setBudgetAccount(e.target.value)}
                  />
                </Box>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                marginBottom={3}>
                <Box marginRight={2}>
                  <Button
                    variant='contained'
                    onClick={(e) => saveNewBudgetCode(e)}>
                    Save
                  </Button>
                </Box>
                <Box>
                  <Button variant='outlined' onClick={(e) => handleShowForm(e)}>
                    Cancel
                  </Button>
                </Box>
              </Box>
            </>
          ) : null}
          <Box width={'100%'}>
            <Typography>
              Evenings and Weekends will require an additional personnel charge
              to defray the cost of overtime labor. This will include time for
              setup, service, and breakdown of the event. Charges may run from
              $25 to $32 per hour.
            </Typography>
          </Box>
        </Box>
        <Box marginTop={2}>
          <Box sx={{ width: '70%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label='basic tabs example'
                variant='scrollable'
                scrollButtons='auto'>
                {foodData?.map((category, index) => {
                  return (
                    <Tab
                      key={index}
                      label={
                        <Typography
                          color={
                            menuSelections?.find(
                              (f) => f.item.category === category.name
                            )
                              ? '#a21024'
                              : null
                          }
                          fontSize={13}
                          fontWeight={500}>
                          {category.name}
                        </Typography>
                      }
                      {...a11yProps(0)}
                      wrapped
                    />
                  );
                })}
              </Tabs>
            </Box>
            <Box
              margin={2}
              sx={{
                padding: 3,
                width: 'fit-content'
              }}>
              <Stack direction={'row'} marginBottom={1}>
                <Box width={'69%'}>
                  <Typography variant='h6'>Item</Typography>
                </Box>
                <Box width={'23%'}>
                  <Typography variant='h6'>Quantity</Typography>
                </Box>
                <Box width={'5%'}>
                  <Typography variant='h6'>Price</Typography>
                </Box>
              </Stack>
              <Divider variant='light' />
              {foodData?.map((tab, innerIndex) => {
                return tab.items?.map((details, deepInnerIndex) => {
                  var createdObject = menuSelections?.map(
                    (menuItem, menuIndex) => {
                      <div key={menuIndex}></div>;
                      return (createdObject = {
                        itemId: menuItem.item.id,
                        quantity: menuItem.quantity
                      });
                    }
                  );
                  return (
                    <TabPanel value={value} index={innerIndex}>
                      <div key={innerIndex}>
                        <div key={deepInnerIndex}></div>
                        <Stack direction={'row'}>
                          <Box p={0} width={'80%'} sx={{ padding: '0px' }}>
                            <Typography component={'span'} color={'gray'}>
                              {details.name}
                            </Typography>
                          </Box>
                          <Box width={'10%'} p={0}>
                            <TextField
                              variant='standard'
                              type='number'
                              placeholder={
                                quantitiesFullList[innerIndex][deepInnerIndex][
                                  'quantity'
                                ] ||
                                createdObject
                                  ?.map((u, index312) => {
                                    if (
                                      u['itemId'] ===
                                      quantitiesFullList[innerIndex][
                                        deepInnerIndex
                                      ]?.itemId
                                    ) {
                                      quantitiesFullList[innerIndex][
                                        deepInnerIndex
                                      ]['quantity'] = parseInt(u['quantity']);
                                      <div key={index312}></div>;
                                      return u['quantity'];
                                    }
                                    return null;
                                  })
                                  .join(' ')
                                  .trim() ||
                                '0'
                              }
                              size='small'
                              className='number-class'
                              onChange={(e) => {
                                quantitiesFullList[innerIndex][deepInnerIndex][
                                  'quantity'
                                ] = e.target.value;
                                setQuantitiesFullList(quantitiesFullList);
                                handleAddToList(
                                  quantitiesFullList,
                                  innerIndex,
                                  deepInnerIndex,
                                  e.target.value
                                );
                              }}
                            />
                          </Box>
                          <Box width={'10%'} marginLeft={15} p={0}>
                            <Typography
                              component={'span'}
                              color={'gray'}>{`$${Number(details.pricePerPerson)
                              .toFixed(2)
                              .replace(
                                /\d(?=(\d{3})+\.)/g,
                                '$&,'
                              )}`}</Typography>
                          </Box>
                        </Stack>
                      </div>
                    </TabPanel>
                  );
                });
              })}
            </Box>

            <Stack flexDirection={'row'}>
              <Box marginLeft={1} marginTop={2}>
                <Typography component={'span'} color={'seagreen'} variant='h6'>
                  Total: ${cost}
                </Typography>
              </Box>
              <Box marginLeft={3} marginTop={2}>
                {submitting && !budgetCode ? (
                  <Typography fontSize={9} color={'red'}>
                    Budget Code Required!
                  </Typography>
                ) : null}
              </Box>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Grid>
  );
}

export default CateringSupport;
