import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import StudentList from './sub-views/StudentList';
import { rtkApiSlice } from 'utils/rtk-api-slices';

function TeacherView() {
  const [userSchedule, setUserSchedue] = useState([]);
  const [lateWorkBySection, setLateWorkBySection] = useState([]);
  const [showStudents, setShowStudets] = useState(true);
  const [showEditElements, setShowEditElements] = useState(false);
  const [studentNotes, setStudentNotes] = useState('');
  const [studentIds, setStudentIds] = useState([]);
  const [showAddNew, setShowAddNew] = useState(false);
  const [sectionId, setSectionId] = useState('');
  const [studentIndex, setStudentIndex] = useState(0);

  //Action
  const [assessmentCalendarAction, assessmentCalendarActionReponse] =
    rtkApiSlice.useAssessmentCalendarActionMutation();
  console.log(
    'assessmentCalendarActionReponse',
    assessmentCalendarActionReponse?.data,
    assessmentCalendarActionReponse?.error,
    assessmentCalendarActionReponse?.status,
    assessmentCalendarActionReponse?.originalArgs?.url
  );
  useEffect(() => {
    // assessmentCalendarActionReponse?.isSuccess && window.location.reload();
  }, [assessmentCalendarActionReponse?.isSuccess]);

  // getLateWorkBySection
  const [getLateWorkBySectionId, setGetLateWorkBySectionId] = useState();
  const getLateWorkBySectionReponse = rtkApiSlice.useAssessmentCalendarQuery({
    url: `/assessment-calendar/late-work/section/${getLateWorkBySectionId}?includeResolved=true`
  });
  useEffect(() => {
    setLateWorkBySection(getLateWorkBySectionReponse?.data);
  }, [getLateWorkBySectionReponse?.data]);
  const getLateWork = id => {
    setGetLateWorkBySectionId(id);
  };

  // getUserAcademicSchedule
  const getUserAcademicScheduleReponse = rtkApiSlice.useAssessmentCalendarQuery(
    {
      url: `/schedule/academics?detailed=true`
    }
  );
  useEffect(() => {
    setUserSchedue(getUserAcademicScheduleReponse?.data);
  }, [getUserAcademicScheduleReponse?.data]);

  const showList = () => {
    setShowStudets(false);
  };
  const hideList = () => {
    setShowStudets(true);
  };
  const newLateWork = sectionId => {
    setShowEditElements(true);
    getLateWork(sectionId);
  };
  const handleStudentNotes = e => {
    setStudentNotes(e);
  };
  const handleStudentIds = (studentId, checked) => {
    if (checked) {
      studentIds.push(studentId);
      setStudentIds(studentIds);
    } else if (!checked) {
      setStudentIds(studentIds.filter(x => x !== studentId));
    }
  };

  // markAsResolved
  const [{ isAssessment, id}, setMarkAsResolvedInput] = useState({
    isAssessment:'',
    id:''
  });
  const markAsResolvedReponse = isAssessment
    ? rtkApiSlice.useAssessmentLateWorkMarkedQuery({params:`/${id}/resolve`}, {
        refetchOnMountOrArgChange:true
      })
    : rtkApiSlice.usePatternLateWorkMarkedQuery({params:`/${id}/resolve`}, {
        refetchOnMountOrArgChange:true
      });
  const markAsResolved = (isAssessment, id) => {
    setMarkAsResolvedInput({ isAssessment, id });
  };
  const handleShowAddNew = () => {
    setShowAddNew(true);
  };
  const handleHideAddNew = () => {
    setShowAddNew(false);
  };
  const handleSectionId = e => {
    setSectionId(e);
  };
  const createNewLateWork = async () => {
    const data = {
      details: studentNotes,
      studentIds: studentIds
    };
    const { isSuccess } = await assessmentCalendarAction({
      body: data,
      url: `/late-work/section/${studentIds}`
    });
    isSuccess && getLateWork(sectionId);
  };

  return (
    <Box>
      {showStudents ? (
        <>
          {userSchedule?.map((row, index) => {
            return (
              <div key={index}>
                <Box>
                  <Stack direction={'row'}>
                    <Box minWidth={'350px'}>
                      <Typography>
                        <span
                          style={{ cursor: 'pointer', color: '#1976d2' }}
                          onClick={() => {
                            getLateWork(row.sectionId);
                            showList();
                            handleHideAddNew();
                          }}>
                          {row.displayName}
                        </span>
                      </Typography>
                    </Box>
                    <Box minWidth={'200px'}>
                      <Typography>
                        {row.block === 'Not Scheduled'
                          ? row.block
                          : row.block + ' Block'}{' '}
                      </Typography>
                    </Box>
                    <Box>
                      <Button
                        onClick={() => {
                          newLateWork(row.sectionId);
                          handleShowAddNew();
                          showList();
                          handleSectionId(row.sectionId);
                          setStudentIndex(index);
                        }}>
                        New Late Work
                      </Button>
                    </Box>
                  </Stack>
                </Box>
              </div>
            );
          })}
        </>
      ) : (
        <StudentList
          hideList={hideList}
          lateWorkBySection={lateWorkBySection}
          showEditElements={showEditElements}
          markAsResolved={markAsResolved}
          createNewLateWork={createNewLateWork}
          handleStudentNotes={handleStudentNotes}
          handleStudentIds={handleStudentIds}
          showAddNew={showAddNew}
          handleHideAddNew={handleHideAddNew}
          userSchedule={userSchedule}
          studentIndex={studentIndex}
        />
      )}
    </Box>
  );
}

export default TeacherView;
