import { Grid, Skeleton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import '../../../screens/event-forms/generic-event-form/GenericEventForm.css';
import { useStore } from 'react-redux';

function TheaterSupport({
  handleNotes,
  eventId,
  hasTheater,
  handleTheaterArray,
  submitting
}) {
  const store = useStore();
  const state = store?.getState();
  const accessToken = state?.authentication.userAuth.jwt;

  const [theater, setTheater] = useState();
  const [notes, setNotes] = useState('');
  const [itemIds, setItemIds] = useState([]);
  const [items, setItems] = useState([]);

  const handleFileUpload = (event) => {
    const file = event.target?.files[0];
    const formData = new FormData();
    formData.append('file', file);

    axios
      .post('https://httpbin.org/post', formData)
      .then(() => {})
      .catch(() => {});
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: 'https://forms-dev.winsor.edu/api/events/theater/menu',
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(function (response) {
      if (response.status === 200) {
        setTheater(response.data);
      }
    });
  }, []);

  useEffect(() => {
    if (hasTheater) {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      };
      fetch(
        `https://forms-dev.winsor.edu/api/events/${eventId}/theater`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setNotes(data.notes);
          setItems(data.items);
          data.items.map((e) => {
            if (!itemIds.includes(e.id)) {
              itemIds.push(e.id);
              setItemIds(itemIds);
            }
            return null;
          });
        });
    }
    // eslint-disable-next-line 
  }, [eventId]);

  if (!theater) {
    return (
      <Box sx={{ width: 300, position: 'absolute', left: '45%', top: '48%' }}>
        <Skeleton />
        <Skeleton animation='wave' />
        <Skeleton />
      </Box>
    );
  }

  return (
    <Grid>
      <Box marginBottom={3}>
        <Stack>
          <Box
            width={'76%'}
            marginBottom={1}
            paddingTop={"100px"}
            sx={{
              border: 'solid 1px lightgray',
              padding: 3,
              borderRadius: 4
            }}>
            {theater?.map((completeMenu, index1) => (
              <div key={index1} style={{ paddingLeft:"20px"}}>
                <Typography variant='h6' padding={1} key={index1}>
                  {completeMenu.name}
                </Typography>
                <Stack direction={'row'}>
                  {completeMenu.items?.map((m, index2) => (
                    <div key={index2}>
                      <TableContainer key={index2}>
                        <Table aria-label='simple table'>
                          <TableBody>
                            <TableRow
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                  padding: 0
                                }
                              }}>
                              <TableCell align='left' key={index1}>
                                <input
                                  type='checkbox'
                                  defaultChecked={items.find(function (
                                    post,
                                    index
                                  ) {
                                    if (m.name === post.name) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  })}
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      itemIds.push(m.id);
                                      setItemIds(itemIds);
                                      handleTheaterArray(itemIds);
                                    } else if (e.target.checked === false) {
                                      // eslint-disable-next-line array-callback-return
                                      itemIds.map((a, i) => {
                                        if (a === m.id) {
                                          itemIds.splice(i, 1);
                                          setItemIds(itemIds);
                                        }
                                      });
                                    }
                                  }}
                                />
                                {m.name}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  ))}
                </Stack>
              </div>
            ))}
          </Box>
          <Box marginBottom={1}>
            <Box>
              <textarea
                className='description'
                style={{
                  border: 'solid 1px lightgray',
                  borderRadius: '12px',
                  backgroundColor: '#f3f5f8'
                }}
                cols={50}
                rows={7}
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                  handleNotes(e);
                }}
              />
              {submitting && !notes ? (
                <Typography fontSize={12} color={'red'}>
                  Notes Required!
                </Typography>
              ) : null}
            </Box>
          </Box>
          <Box marginBottom={3}>
            <Box width={'300px'}>
              <input
                variant='outlined'
                size='small'
                onClick={(e) => handleFileUpload(e)}
                type='file'
                className='file-upload'
                accept='image/png, image/jpeg, .pdf, .txt, .doc, docx'
                multiple
              />
            </Box>
          </Box>
        </Stack>
      </Box>
    </Grid>
  );
}

export default TheaterSupport;
