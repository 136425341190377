import { Badge, Box, Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';

function StudentLateWork({ markAsResolved, lateWork, getLateWork, studentId }) {
  const [isResolved, setIsResolved] = useState(true);
  return (
    <Box marginTop={3} minWidth={'600px'}>
      <Stack direction={'row'}>
        {lateWork?.length > 0 ? (
          <Box marginBottom={3}>
            <Box>
              <Typography>Student Late Work</Typography>
            </Box>
          </Box>
        ) : (
          <Box marginBottom={3}>
            <Typography>No Late Work Found.</Typography>
          </Box>
        )}
        <Box marginLeft={5} marginTop={-1}>
          {}
          <Button
            onClick={() => {
              getLateWork(studentId, !isResolved);
              setIsResolved(!isResolved);
            }}
            variant="outlined"
          >
            {isResolved ? 'Show Unresolved' : 'Show Resolved'}
          </Button>
        </Box>
      </Stack>
      <Box>
        {lateWork?.map((row, index) => {
          return (
            <Stack
              direction={'row'}
              marginBottom={1}
              sx={{
                backgroundColor: `${index % 2 === 1 ? 'lightgray' : null}`,
                padding: 2,
                borderRadius: 4,
              }}
            >
              <Box minWidth={'20px'}>{index + 1 + '.'}</Box>
              <Stack marginBottom={4}>
                <Box minWidth={'600px'} maxWidth={'600px'}>
                  {row.details}
                </Box>
                {!row.isResolved ? (
                  <Box>
                    <Button
                      onClick={() => {
                        markAsResolved(row.isAssessment, row.id);
                      }}
                    >
                      Mark As Resolved
                    </Button>
                  </Box>
                ) : null}
              </Stack>
              <Stack marginBottom={4}>
                <Box marginLeft={2}>{row.markedDates}</Box>
                <Box marginLeft={7}>
                  {row.isResolved ? (
                    <Badge badgeContent={'Resolved'} color="warning" />
                  ) : null}
                </Box>
              </Stack>
            </Stack>
          );
        })}
      </Box>
    </Box>
  );
}

export default StudentLateWork;
