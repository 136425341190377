import { store } from '../store';
const state = store?.getState();
const accessToken = state?.authentication.userAuth.jwt;

export const UpsertTheater = (args, eventId) => {
  console.log('upsert theater accessToken: ', accessToken);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(args)
  };
  fetch(
    `https://forms-dev.winsor.edu/api/events/${eventId}/theater`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .catch((error) => console.log('error: ', error));
};

export const UpsertTechSupport = (args, eventId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(args)
  };
  fetch(
    `https://forms-dev.winsor.edu/api/events/${eventId}/technology`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .catch((error) => console.log('error: ', error));
};

export const UpsertVirtualInfo = (args, eventId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(args)
  };
  fetch(
    `https://forms-dev.winsor.edu/api/events/${eventId}/technology/virtual-event`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log(data);
    })
    .catch((err) => console.log(err));
};
export const UpsertFacility = (args, eventId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(args)
  };
  fetch(
    `https://forms-dev.winsor.edu/api/events/${eventId}/facilities`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .catch((error) => console.log('err ', error));
};
export const UpsertCommunication = (args, eventId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(args)
  };
  fetch(
    `https://forms-dev.winsor.edu/api/events/${eventId}/marcom`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .catch((error) => console.log('err ', error));
};
export const UpsertCatering = (args, eventId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(args)
  };
  fetch(
    `https://forms-dev.winsor.edu/api/events/${eventId}/catering`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .catch((error) => console.log('err ', error));
};
