import Grid from '@mui/material/Grid';
import ItemCard from '../../components/cards/ItemCard';
import '../home/Home.css';
import { Link } from 'react-router-dom';
import { CircularProgress, Typography } from '@mui/material';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import EventNoteTwoToneIcon from '@mui/icons-material/EventNoteTwoTone';
import AdminPanelSettingsTwoToneIcon from '@mui/icons-material/AdminPanelSettingsTwoTone';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import Diversity3TwoToneIcon from '@mui/icons-material/Diversity3TwoTone';
import DevicesOtherTwoToneIcon from '@mui/icons-material/DevicesOtherTwoTone';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import ReduceCapacityTwoToneIcon from '@mui/icons-material/ReduceCapacityTwoTone';
import SosTwoToneIcon from '@mui/icons-material/SosTwoTone';
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccountsTwoTone';
import { useNavigate } from 'react-router-dom';
import { getUserRoles } from 'utils/permissions';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { rtkApiSlice } from 'utils/rtk-api-slices';
import { Box } from '@mui/system';

const Home = () => {
  const navigate = useNavigate();
  const { data = [], isLoading, isError } = rtkApiSlice.useRolesQuery();
  const roles = useSelector((state: any) => state.authentication.roles);
  const userRoles = getUserRoles(roles);

  if (isError) {
    toast('Sorry, an error occurred');
    navigate('/');
  }

  if (data.length === 0 || isLoading) {
    return (
      <Box className='loading-progress'>
        <Box marginLeft={4}>
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  return (
    <div className='background'>
      <Grid
        container
        spacing={2}
        marginBottom={3}
        direction='row'
        justifyContent='center'
        alignItems='center'>
        <Grid
          item
          xs={12}
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          marginBottom={3}
          marginTop={3}>
          <Typography variant='h6' gutterBottom>
            Faculty Tools
          </Typography>
        </Grid>
        {userRoles.isTeacher || userRoles.isNonTeachingStaff ? (
          <Grid item xs={3}>
            <a
              href='https://forms.winsor.edu/Directory/Default.aspx'
              style={{ textDecoration: 'none', color: 'black' }}>
              <ItemCard
                name='Student Directory'
                image={
                  <Diversity3TwoToneIcon color='error' sx={{ fontSize: 60 }} />
                }
                titleMargin={5}
              />
            </a>
          </Grid>
        ) : null}

        <Grid item xs={3}>
          <a
            href='https://forms.winsor.edu/Gadgets/Default.aspx'
            style={{ textDecoration: 'none', color: 'black' }}>
            <ItemCard
              name='Gadgets'
              image={
                <DevicesOtherTwoToneIcon color='error' sx={{ fontSize: 60 }} />
              }
              titleMargin={8}
            />
          </a>
        </Grid>

        {userRoles.isTeacher || userRoles.isStudent || userRoles.isAdvisor ? (
          <Grid item xs={3}>
            <Link
              to={`${
                userRoles.isStudent
                  ? '/student-calendar'
                  : '/assessment-calendar'
              }`}
              style={{ textDecoration: 'none', color: 'black' }}>
              <ItemCard
                name='Assessment Calendar'
                image={<TodayTwoToneIcon color='error' sx={{ fontSize: 60 }} />}
                titleMargin={4}
              />
            </Link>
          </Grid>
        ) : null}
        {userRoles.isTeacher ||
        userRoles.isNonTeachingStaff ||
        userRoles.isSecurity ? (
          <Grid
            item
            xs={12}
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            marginBottom={3}
            marginTop={3}>
            <Typography variant='h6' gutterBottom>
              Event Forms
            </Typography>
          </Grid>
        ) : null}
        {userRoles.isTeacher ||
        userRoles.isNonTeachingStaff ||
        userRoles.isSecurity ? (
          <Grid item xs={3}>
            <a
              href='https://forms.winsor.edu/EventForms/Calendar.aspx'
              style={{ textDecoration: 'none', color: 'black' }}>
              <ItemCard
                name='Event Calendar'
                image={
                  <CalendarMonthTwoToneIcon
                    color='error'
                    sx={{ fontSize: 60 }}
                  />
                }
                titleMargin={7}
              />
            </a>
          </Grid>
        ) : null}

        {userRoles.isTeacher || userRoles.isNonTeachingStaff ? (
          <Grid item xs={3}>
            <Link
              to={'/event-forms'}
              style={{ textDecoration: 'none', color: 'black' }}>
              <ItemCard
                name='Event Forms'
                image={
                  <EventNoteTwoToneIcon color='error' sx={{ fontSize: 60 }} />
                }
                titleMargin={7}
              />
            </Link>
          </Grid>
        ) : null}

        {userRoles.isWinsorAdminAssistant || userRoles.isWinsorRegistrar ? (
          <Grid item xs={3}>
            <Link
              to={'/events-admin'}
              style={{ textDecoration: 'none', color: 'black' }}>
              <ItemCard
                name='Event Admin'
                image={
                  <AdminPanelSettingsTwoToneIcon
                    color='error'
                    sx={{ fontSize: 60 }}
                  />
                }
                titleMargin={7}
              />
            </Link>
          </Grid>
        ) : null}
        <Grid
          item
          xs={12}
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          marginBottom={3}
          marginTop={3}>
          <Typography variant='h6' gutterBottom>
            Book Orders
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <a
            href='https://forms.winsor.edu/BookOrders/DepartmentView.aspx'
            style={{ textDecoration: 'none', color: 'black' }}>
            <ItemCard
              name='Department View'
              image={
                <ReduceCapacityTwoToneIcon
                  color='error'
                  sx={{ fontSize: 60 }}
                />
              }
              titleMargin={7}
            />
          </a>
        </Grid>
        <Grid item xs={3}>
          <a
            href='https://forms.winsor.edu/BookOrders/Bookstore.aspx'
            style={{ textDecoration: 'none', color: 'black' }}>
            <ItemCard
              name='Book Store'
              image={
                <MenuBookTwoToneIcon color='error' sx={{ fontSize: 60 }} />
              }
              titleMargin={7}
            />
          </a>
        </Grid>
        {userRoles.isSystemAdmin ? (
          <Grid
            item
            xs={12}
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            marginBottom={3}
            marginTop={3}>
            <Typography variant='h6' gutterBottom>
              Management Tools
            </Typography>
          </Grid>
        ) : null}
        {userRoles.isSystemAdmin ? (
          <Grid item xs={3}>
            <a
              href='https://forms.winsor.edu/Helpdesk/Search.aspx'
              style={{ textDecoration: 'none', color: 'black' }}>
              <ItemCard
                name='Help Desk'
                image={<SosTwoToneIcon color='error' sx={{ fontSize: 60 }} />}
                titleMargin={7}
              />
            </a>
          </Grid>
        ) : null}
        {userRoles.isSystemAdmin ? (
          <Grid item xs={3}>
            <a
              href='https://forms.winsor.edu/SystemManagement/LogView.aspx'
              style={{ textDecoration: 'none', color: 'black' }}>
              <ItemCard
                name='System Management'
                image={
                  <ManageAccountsTwoToneIcon
                    color='error'
                    sx={{ fontSize: 60 }}
                  />
                }
                titleMargin={4}
              />
            </a>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

export default Home;