import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import { useStore } from 'react-redux';

export default function CommunicationSupport({
  communication,
  eventId,
  leader,
  handlePrintInvite,
  handleDigitalInvite,
  handleNewsletter,
  handleEmail,
  handlePrintProgram,
  handleMedia,
  handlePhotographer,
  handleScript,
  handleDigitalProgram,
  handleInviteContact,
  handleDeleteContact
}) {
  const store = useStore();
  const state = store?.getState();
  const accessToken = state?.authentication.userAuth.jwt;
  const [contacts, setContacts] = useState();
  const [anotherContact, setAnotherContact] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [submitting] = useState(false);
  const [printInvite, setPrintInvite] = useState(false);
  const [digitalInvite, setDigitalInvite] = useState(false);
  const [newsletterReminder, setNewsletterReminder] = useState(false);
  const [emailReminder, setEmailReminder] = useState(false);
  const [printedProgram, setPrintedProgram] = useState(false);
  const [needCreatedMedia, setNeedCreatedMedia] = useState(false);
  const [needPhotographer, setNeedPhotographer] = useState(false);
  const [inviteContactIds, setInviteContactIds] = useState([]);
  const [scriptHelp, setScriptHelp] = useState(false);
  const [digitalProgram, setDigitalProgram] = useState(false);

  const addAnotherContact = (event) => {
    setAnotherContact(!anotherContact);
  };

  const deleteContact = (event, index) => {
    inviteContactIds.splice(index, 1);
    setInviteContactIds(inviteContactIds);
    handleDeleteContact(index);
    setContactList(contactList.filter((contact) => contact.id !== event));
  };

  useEffect(() => {
    if (eventId && communication) {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      };
      fetch(
        `https://forms-dev.winsor.edu/api/events/${eventId}/marcom`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data.inviteList);
          setContactList(data.inviteList);
          setDigitalInvite(data.digitalInvite);
          setEmailReminder(data.emailReminder);
          setNeedCreatedMedia(data.needCreatedMedia);
          setNeedPhotographer(data.needPhotographer);
          setPrintedProgram(data.printedProgram);
          setPrintInvite(data.printInvite);
          setNewsletterReminder(data.newsletterReminder);
          setInviteContactIds([]);
          setScriptHelp(data.scriptHelp);
          setDigitalProgram(data.digitalProgram);
        })
        .catch((error) => console.log(error));
    }
    // eslint-disable-next-line
  }, [eventId]);

  React.useEffect(() => {
    axios({
      method: 'get',
      url: 'https://forms-dev.winsor.edu/api/users/self/contacts', // move to .env
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(function (response) {
      if (response.status === 200) {
        setContacts(response.data);
      }
    });
  }, []);

  return (
    <Grid container>
      <Stack
        sx={{
          border: 'solid 1px lightgray',
          width: 'fit-content',
          padding: 3,
          borderRadius: 4
        }}
        margin={2}>
        <Box width={'100%'} marginBottom={3}>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value={printInvite || false}
                    checked={printInvite || false}
                    onChange={(e) => {
                      handlePrintInvite(e.target.checked);
                      setPrintInvite(e.target.checked);
                    }}
                  />
                }
                label='Printed Invitation'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={scriptHelp || false}
                    checked={scriptHelp || false}
                    onChange={(e) => {
                      setScriptHelp(e.target.checked);
                      handleScript(e.target.checked);
                    }}
                  />
                }
                label='Help with a Script'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={needPhotographer || false}
                    checked={needPhotographer || false}
                    onChange={(e) => {
                      handlePhotographer(e.target.checked);
                      setNeedPhotographer(e.target.checked);
                    }}
                  />
                }
                label='Photographer'
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value={digitalInvite || false}
                    checked={digitalInvite || false}
                    onChange={(e) => {
                      setDigitalInvite(e.target.checked);
                      handleDigitalInvite(e.target.checked);
                    }}
                  />
                }
                label='Digital Invitation'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={printedProgram || false}
                    checked={printedProgram || false}
                    onChange={(e) => {
                      setPrintedProgram(e.target.checked);
                      handlePrintProgram(e.target.checked);
                    }}
                  />
                }
                label='Printed Program'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={newsletterReminder || false}
                    checked={newsletterReminder || false}
                    onChange={(e) => {
                      setNewsletterReminder(e.target.checked);
                      handleNewsletter(e.target.checked);
                    }}
                  />
                }
                label='Reminder in Newsletter'
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value={digitalProgram || false}
                    checked={digitalProgram || false}
                    onChange={(e) => {
                      setDigitalProgram(e.target.checked);
                      handleDigitalProgram(e.target.checked);
                    }}
                  />
                }
                label='Digital Program'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={emailReminder || false}
                    checked={emailReminder || false}
                    onChange={(e) => {
                      setEmailReminder(e.target.checked);
                      handleEmail(e.target.checked);
                    }}
                  />
                }
                label='Email Reminder'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={needCreatedMedia || false}
                    checked={needCreatedMedia || false}
                    onChange={(e) => {
                      setNeedCreatedMedia(e.target.checked);
                      handleMedia(e.target.checked);
                    }}
                  />
                }
                label='Created Media'
              />
            </FormGroup>
          </Box>
        </Box>
        <Box>
          {!anotherContact ? (
            <Box>
              {/* <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={leader}
                onChange={(e) => {
                  setInviteContactIds([...inviteContactIds, e.target.value.id]);
                  handleInviteContact(e.target.value.id);
                  setContactList([...contactList, e.target.value]);
                }}
                sx={{ width: 300 }}
                size="small"
              >
                {contacts &&
                  contacts.map((c, index2) => {
                    return (
                      <MenuItem key={index2} value={c}>
                        {`${c.firstName} ${c.lastName}`}
                      </MenuItem>
                    );
                  })}
              </Select> */}
              <Autocomplete
                disablePortal
                id='combo-box-demo'
                onChange={(event, newValue) => {
                  setInviteContactIds([...inviteContactIds, newValue.id]);
                  handleInviteContact(newValue?.id);
                  setContactList([...contactList, newValue]);
                }}
                // value={roomDynamic[index]}
                options={contacts}
                sx={{ width: 300 }}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option.label) {
                    return option.firstName + ' ' + option.lastName;
                  }
                  return option.firstName + ' ' + option.lastName;
                }}
                renderOption={(props, option) => (
                  <li {...props}>{option.firstName + ' ' + option.lastName}</li>
                )}
                renderInput={(params) => <TextField {...params} label='' />}
              />
              {submitting && !inviteContactIds ? (
                <Typography fontSize={12} color={'red'}>
                  Contact Required!
                </Typography>
              ) : null}

              <Box marginBottom={3}>
                <Button onClick={(e) => addAnotherContact(e)}>
                  New Contact
                </Button>
              </Box>
              <Box
                style={{
                  border: 'solid 1px lightgray',
                  padding: 6,
                  borderRadius: 4,
                  marginBottom: 8
                }}
                width={'60%'}>
                {contactList.length > 0 ? (
                  <div>
                    {contactList.map((c, i) => {
                      return (
                        <Box flexDirection={'column'} key={i}>
                          <Stack direction={'row'}>
                            <Box width='100%' padding={1}>
                              <label>{c.firstName + ' ' + c.lastName}</label>
                            </Box>
                            <Box marginRight={0}>
                              <Button
                                variant='text'
                                color='error'
                                onClick={() => deleteContact(c.id, i)}>
                                Delete
                              </Button>
                            </Box>
                          </Stack>
                        </Box>
                      );
                    })}
                  </div>
                ) : (
                  <span style={{ color: 'lightgray' }}>No Contacts</span>
                )}
              </Box>
            </Box>
          ) : null}
          {anotherContact ? (
            <Stack
              direction={'row'}
              sx={{
                border: 'solid 1px lightgray',
                padding: '10px',
                marginBottom: '10px'
              }}>
              <Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginBottom={1}>
                  <Box width={'40%'}>
                    <Typography marginRight={5}>First Name</Typography>
                  </Box>
                  <TextField
                    margin='normal'
                    required
                    id='event-name'
                    style={{ width: '55%', borderColor: 'lightgray' }}
                    autoFocus
                    size='small'
                  />
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginBottom={1}>
                  <Box width={'40%'}>
                    <Typography marginRight={5}>Last Name</Typography>
                  </Box>
                  <TextField
                    margin='normal'
                    required
                    id='event-name'
                    style={{ width: '55%', borderColor: 'lightgray' }}
                    autoFocus
                    size='small'
                  />
                </Box>
              </Box>
              <Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginBottom={1}>
                  <Box width={'30%'}>
                    <Typography marginRight={5}>Email</Typography>
                  </Box>
                  <TextField
                    margin='normal'
                    required
                    id='event-name'
                    style={{ width: '55%', borderColor: 'lightgray' }}
                    autoFocus
                    size='small'
                  />
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginBottom={1}>
                  <Box width={'30%'}>
                    <Typography marginRight={5}>Cellphone</Typography>
                  </Box>
                  <TextField
                    margin='normal'
                    required
                    id='event-name'
                    style={{ width: '55%', borderColor: 'lightgray' }}
                    autoFocus
                    size='small'
                  />
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginBottom={1}>
                  <Checkbox />
                  <Box>
                    <Typography>Keep Contact Private</Typography>
                  </Box>
                </Box>
                <Box marginBottom={1}>
                  <Button onClick={(e) => addAnotherContact(e)}>
                    Save New Contact
                  </Button>
                  <Button onClick={(e) => addAnotherContact(e)}>Cancel</Button>
                </Box>
              </Box>
            </Stack>
          ) : null}
        </Box>
      </Stack>
    </Grid>
  );
}
