import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
} from '@mui/material';
import React from 'react';

function AssessmentNotes({
  hideNotes,
  saveNotes,
  handleClasses,
  handleNewNote,
  handleNewDate,
  newNote,
  newDate,
  classesAffected,
  noteId,
}) {
  return (
    <Box
      minWidth={'45%'}
      height={'55%'}
      marginRight={2}
      sx={{ borderRadius: 4, border: 'solid 1px lightgray', padding: 3 }}>
      <Stack spacing={2}>
        <textarea
          cols={50}
          rows={5}
          style={{
            fontFamily: `'Times New Roman', Times, serif`,
            fontSize: 'large',
            backgroundColor: '#f3f5f8',
            borderRadius: '12px',
            padding: '15px',
            border: 'solid 1px lightgray'
          }}
          onChange={e => handleNewNote(e.target.value)}
          value={newNote || ''}
        />
        <input
          type='date'
          className='date'
          value={newDate || ''}
          style={{ backgroundColor: '#f3f5f8' }}
          onChange={e => handleNewDate(e.target.value)}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={classesAffected?.find(x => x === 'Class V')}
                onChange={e => {
                  if (e.target.checked === true) {
                    handleClasses('Class V', true);
                  } else {
                    handleClasses('Class V', false);
                  }
                }}
              />
            }
            label='Class V'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={classesAffected?.find(x => x === 'Class VI')}
                onChange={e => {
                  if (e.target.checked === true) {
                    handleClasses('Class VI', true);
                  } else {
                    handleClasses('Class VI', false);
                  }
                }}
              />
            }
            label='Class VI'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={classesAffected?.find(x => x === 'Class VII')}
                onChange={e => {
                  if (e.target.checked === true) {
                    handleClasses('Class VII', true);
                  } else {
                    handleClasses('Class VII', false);
                  }
                }}
              />
            }
            label='Class VII'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={classesAffected?.find(x => x === 'Class VIII')}
                onChange={e => {
                  if (e.target.checked === true) {
                    handleClasses('Class VIII', true);
                  } else {
                    handleClasses('Class VIII', false);
                  }
                }}
              />
            }
            label='Class VIII'
          />
        </FormGroup>
        <Button onClick={() => hideNotes()} variant='outlined'>
          Cancel
        </Button>
        <Button onClick={() => saveNotes(noteId)} variant='contained'>
          Save
        </Button>
      </Stack>
    </Box>
  );
}

export default AssessmentNotes;
