import styled from 'styled-components';

export const CalendarBody = styled.div`
  height: 600px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(6, 1fr);
  @media (max-width: 700px) {
    width: 200vw;
  }
`;
export const StyledDay = styled.span`
  border: 1px solid lightgray;
  text-align: left;
  padding: 5px;
  position: relative;
  color: black;
  font-size: 14px;
`;
export const StyledDayNumber = styled.span`
  border: 1px solid;
  text-align: right;
  padding: 5px;
`;
export const CalendarHead = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 24px;
  background: #a21024;
  color: white;
  border-radius: 5px 5px 0px 0px;
  padding-top: 10px;
  @media (max-width: 700px) {
    width: 200vw;
  }
`;
export const SevenColGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  height: 27px;
  @media (max-width: 700px) {
    width: 200vw;
  }
`;
export const HeadDay = styled.span`
  text-align: center;
  background: #a21024;
  color: white;
  font-size: 1.2rem;
`;
export const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
export const fiveDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday'
];
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
