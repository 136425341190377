/* eslint-disable  */
import { Divider, Grid, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { Box, Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import MiddleHeader from '../../../components/headers/MiddleHeader';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useParams } from 'react-router-dom';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';

import './GenericEventForm.css';
import TheaterSupport from '../../../components/forms/support-forms/TheaterSupport';
import TechnologySupport from '../../../components/forms/support-forms/TechnologySupport';
import FacilitiesSupport from '../../../components/forms/support-forms/FacilitiesSupport';
import CommunicationSupport from '../../../components/forms/support-forms/CommunicationSupport';
import CateringSupport from '../../../components/forms/support-forms/CateringSupport';
import ErrorDialog from '../../../components/dialogs/ErrorDialog';
import {
  UpsertCatering,
  UpsertCommunication,
  UpsertFacility,
  UpsertTechSupport,
  UpsertTheater,
  UpsertVirtualInfo
} from '../../../helpers/HttpRequestHelpers';
import { useStore } from 'react-redux';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function GenericEventForm() {
  const store = useStore();
  const state = store?.getState();
  const accessToken = state?.authentication.userAuth.jwt;
  // const [room, setRoom] = useState();
  const [roomDynamic, setRoomDynamic] = useState([]);
  const [customDynamicRoom, setCustomDyamicRoom] = useState([]);
  const [roomValidation, setRoomValidation] = useState(false);
  const [customRoomValidation, setCustomRoomValidation] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [customName, setCustomName] = useState();
  const [leader, setLeader] = useState('');
  const [locationArray, setLocationArray] = useState([]);
  const [theater, setTheater] = useState();
  const [techSupport, setTechSupport] = useState();
  const [facilitiesSupport, setFacilitiesSupport] = useState();
  const [communication, setCommunication] = useState();
  const [food, setFood] = useState();
  const [locationValue, setLocationValue] = useState(0);
  const [employees, setEmployees] = useState();
  const [rooms, setRooms] = useState();
  const [custom, setCustom] = useState();
  const [eventName, setEventName] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [budgetCodes, setBudgetCodes] = useState();
  const [endDate, setEndDate] = useState();
  const [endTime, setEndTime] = useState();
  const [divisionHead, setDivisionHead] = useState(false);
  const [preaprovalDate, setPreaprovalDate] = useState();
  const [attendeeCount, setAttendeeCount] = useState();
  const [itemIds, setItemIds] = useState([]);
  const [selectedItemIds, setSelectedItemsIds] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [submitting, setSubmitting] = useState();
  const [showErrors, setShowErrors] = useState();
  const [eventDetails, setEventDetails] = useState();
  // const [file, setFile] = useState();
  const [serverErrorStatus, setServerErrorStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [serverError, setServerError] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  /* theater support */
  const [theaterNotes, setTheaterNotes] = useState('');
  const [theaterItemIds, setTheaterItemIds] = useState([]);
  /* tech support */
  const [presence, setPresence] = useState(false);
  const [equipment, setEquipment] = useState(false);
  const [help, setHelp] = useState(false);
  const [details, setDetails] = useState('');
  /* tech support - Zoom Info */
  const [registration, setRegistration] = useState(false);
  const [chatEnabled, setChatEnabled] = useState(false);
  const [qaEnabled, setQaEnabled] = useState(false);
  const [recording, setRecording] = useState(false);
  const [reminder, setReminder] = useState(false);
  const [transcript, setTranscript] = useState(false);
  const [registrantList, setRegistrantList] = useState(false);
  const [zoomLink, setZoomLink] = useState(false);
  const [qaSupportPerson, setqaSupportPerson] = useState('');
  const [hostContactId, sethostContactId] = useState('');
  const [panelistIds, setpanelistIds] = useState([]);
  /* Catering support */
  const [servers, setServers] = useState(false);
  const [cleanup, setCleanup] = useState(false);
  const [budgetCode, setBudgetCode] = useState();
  const [finalArray] = useState([]);
  const [quantitiesFullList, setQuantitiesFullList] = useState({
    itemId: '',
    quantity: ''
  });

  /* Communication support */
  const [printInvite, setPrintInvite] = useState(false);
  const [digitalInvite, setDigitalInvite] = useState(false);
  const [newsletterReminder, setNewsletterReminder] = useState(false);
  const [emailReminder, setEmailReminder] = useState(false);
  const [printedProgram, setPrintedProgram] = useState(false);
  const [needCreatedMedia, setNeedCreatedMedia] = useState(false);
  const [needPhotographer, setNeedPhotographer] = useState(false);
  const [scriptHelp, setScriptHelp] = useState(false);
  const [digitalProgram, setDigitalProgram] = useState(false);
  const [inviteContactIds, setInviteContactIds] = useState([]);

  /* facilities support */
  const [setup, setSetup] = useState(false);
  const [facilityPresence, setFacilityPresence] = useState(false);
  const [breakdown, setBreakdown] = useState(false);
  const [overnight, setOvernight] = useState(false);
  const [parking, setParking] = useState(false);
  const [locationArrayFacility, setLocationArrayFacility] = useState([]);
  const [locationSetups, setLocationSetups] = useState([
    {
      locationId: '',
      instructions: '',
      includeSandwichSign: false
    }
  ]);

  let { eventId } = useParams();
  const navigate = useNavigate();

  /* Catering Support Functions */
  const handleServers = (e) => {
    setServers(e);
  };
  const handleCleanup = (e) => {
    setCleanup(e);
  };
  const handleBudgetCode = (e) => {
    setBudgetCode(e);
  };
  const handleAddToList = (fullList, innerIndex, deepInnerIndex, e) => {
    fullList[innerIndex][deepInnerIndex]['quantity'] = e;
    setQuantitiesFullList(fullList);
  };
  const handleCleanUpList = (e) => {
    quantitiesFullList?.map((outter, outterIndex) => {
      <div key={outterIndex}></div>;
      outter?.map((inner) => {
        if (inner.quantity > 0) {
          finalArray.push({
            itemId: inner.itemId,
            quantity: parseInt(inner.quantity)
          });
        }
        return null;
      });
      return null;
    });
  };
  /* Theater Support Functions */
  const theaterArray = (ids, checked) => {
    if (checked === true) {
      setItemIds([...itemIds, ids.id]);
    }
  };
  const handleTheaterArray = (e) => {
    setTheaterItemIds(e);
  };

  const handleNotes = (e) => {
    setTheaterNotes(e.target.value);
  };
  /* Tech Support Functions */
  const handlePresence = (e) => {
    setPresence(e);
  };
  const handleEquipment = (e) => {
    setEquipment(e);
  };
  const handleHelp = (e) => {
    setHelp(e);
  };
  const handleDetails = (e) => {
    setDetails(e);
  };
  /* Tech Support Functions - Zoom */
  const handleRegistration = (e) => {
    setRegistration(e);
  };
  const handleChatEnabled = (e) => {
    setChatEnabled(e);
  };
  const handleQAEnabled = (e) => {
    setQaEnabled(e);
  };
  const handleRecording = (e) => {
    setRecording(e);
  };
  const handleRemider = (e) => {
    setReminder(e);
  };
  const handleTranscript = (e) => {
    setTranscript(e);
  };
  const handleRegistrantList = (e) => {
    setRegistrantList(e);
  };
  const handleZoomLink = (e) => {
    setZoomLink(e);
  };
  const handleQASupportPerson = (e) => {
    setqaSupportPerson(e);
  };
  const handleContactId = (e) => {
    sethostContactId(e);
  };
  const handlePanelList = (e) => {
    setpanelistIds([...panelistIds, e]);
  };
  /* Communication Support Functions */
  const handlePrintInvite = (e) => {
    setPrintInvite(e);
  };
  const handleDigitalInvite = (e) => {
    setDigitalInvite(e);
  };
  const handleNewsletter = (e) => {
    setNewsletterReminder(e);
  };
  const handleEmail = (e) => {
    setEmailReminder(e);
  };
  const handlePrintProgram = (e) => {
    setPrintedProgram(e);
  };
  const handleMedia = (e) => {
    setNeedCreatedMedia(e);
  };
  const handlePhotographer = (e) => {
    setNeedPhotographer(e);
  };
  const handleScript = (e) => {
    setScriptHelp(e);
  };
  const handleDigitalProgram = (e) => {
    setDigitalProgram(e);
  };
  const handleInviteContact = (e) => {
    setInviteContactIds([...inviteContactIds, e]);
  };
  const handleDeleteContact = (index) => {
    inviteContactIds.splice(index, 1);
    setInviteContactIds(inviteContactIds);
  };
  /* Facility Support Functions - Zoom */
  const handleSetup = (e) => {
    setSetup(e);
  };
  const handleParking = (e) => {
    setParking(e);
  };
  const handleFacilityPresence = (e) => {
    setFacilityPresence(e);
  };
  const handleBreakdown = (e) => {
    setBreakdown(e);
  };
  const handleOvernight = (e) => {
    setOvernight(e);
  };
  const handleAddToArray = (e) => {
    setLocationArrayFacility([
      ...locationArrayFacility,
      {
        id: locationArrayFacility.length + 1,
        value: Math.floor(Math.random())
      }
    ]);
    locationSetups[locationArrayFacility.length] = {
      locationId: '',
      instructions: '',
      includeSandwichSign: false
    };
    setLocationSetups(locationSetups);
  };
  const handleRemoveLocation = (index) => {
    locationArrayFacility.splice(index, 1);
    setLocationArrayFacility([...locationArrayFacility]);
    locationSetups.splice(index, 1);
  };
  const handleLocationSetup = (e, element) => {
    locationArrayFacility.map((item, indexOutter) => {
      if (element === 'locationId') {
        setLocationSetups(
          locationSetups?.map((oneLocation, indexId) => {
            if (indexId === indexOutter) {
              return {
                ...oneLocation,
                locationId: e
              };
            } else {
              return oneLocation;
            }
          })
        );
      }
      if (element === 'instructions') {
        setLocationSetups(
          locationSetups?.map((oneLocation, indexId) => {
            if (indexId === indexOutter) {
              return {
                ...oneLocation,
                instructions: e
              };
            } else {
              return oneLocation;
            }
          })
        );
      }
      if (element === 'includeSandwichSign') {
        setLocationSetups(
          locationSetups?.map((oneLocation, indexId) => {
            if (indexId === indexOutter) {
              return {
                ...oneLocation,
                includeSandwichSign: e
              };
            } else {
              return oneLocation;
            }
          })
        );
      }
      return null;
    });
    return null;
  };

  const handleSubmit = () => {
    setSubmitting(true);
    const data = {
      summary: eventName,
      description: description,
      type: 'Default',
      start: startDate + 'T' + startTime + ':00',
      end: endDate + 'T' + endTime + ':00',
      leaderId: leader,
      creatorId: localStorage.getItem('currentUserId'),
      preaprovalDate: preaprovalDate,
      attendeeCount: attendeeCount || 0,
      selectedLocations: selectedItemIds,
      selectedCustomLocations:
        customDynamicRoom[0] !== '' ? customDynamicRoom : []
    };
    if (
      eventName &&
      description &&
      startDate &&
      endDate &&
      endTime &&
      startTime &&
      preaprovalDate &&
      leader &&
      divisionHead
    ) {
      setShowErrors(false);
      if (eventId && localStorage.getItem('is_template') === 'false') {
        setShowLoading(true);

        const requestOptions = {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify(data)
        };
        fetch(
          `https://forms-dev.winsor.edu/api/events/${eventId}`,
          requestOptions
        )
          .then((response) => {
            if (response.status !== 200) {
              setServerErrorStatus(response.status);
              handleClickOpen();
              return response.json();
            } else {
              return response.json();
            }
          })
          .then((res) => {
            setServerError(
              serverErrorStatus === 500
                ? 'An email detailing the problem has been sent to you.'
                : res.error
            );
            if (theater && theaterNotes) {
              upsertTheaterSupport(res.id);
            }
            if (techSupport && details) {
              upsertTech(res.id);
            }
            if (eventDetails?.hasZoom) {
              upsertVitual(res.id);
            }
            if (facilitiesSupport && locationSetups[0]?.locationId) {
              upsertFacilities(res.id);
            }
            if (communication && inviteContactIds[0]) {
              upsertCommunication(res.id);
            }
            if (food && budgetCode) {
              upsertCatering(res.id);
            }
            completeUpdate(res.id);
          })
          .catch((error) => console.log('err ', error));
      } else {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify(data)
        };
        fetch(`https://forms-dev.winsor.edu/api/events`, requestOptions)
          .then((response) => {
            if (response.status !== 200) {
              setShowLoading(true);
              setServerErrorStatus(response.status);
              handleClickOpen();
              return response.json();
            } else {
              setShowLoading(true);
              return response.json();
            }
          })
          .then((res) => {
            setServerError(
              serverErrorStatus === 500
                ? 'An email detailing the problem has been sent to you.'
                : res.error
            );

            if (theater && theaterNotes) {
              upsertTheaterSupport(res.id);
            }
            if (techSupport && details) {
              upsertTech(res.id);
            }
            if (eventDetails?.hasZoom) {
              upsertVitual(res.id);
            }
            if (facilitiesSupport && locationSetups[0]?.locationId) {
              upsertFacilities(res.id);
            }
            if (communication && inviteContactIds[0]) {
              upsertCommunication(res.id);
            }
            if (food && budgetCode) {
              upsertCatering(res.id);
            }
            completeSubmission(res.id);
          })
          .catch((error) => {
            setShowLoading(false);
          });
      }
    } else {
      setShowErrors(true);
    }
  };

  /* Upsert Catering */
  const upsertCatering = (eventId) => {
    handleCleanUpList();
    const args = {
      servers: servers,
      cleanup: cleanup,
      budgetCodeId: budgetCode,
      selectedItemIds: finalArray
    };
    UpsertCatering(args, eventId);
  };
  /* Upsert Helpers */
  const upsertCommunication = (eventId) => {
    const args = {
      printInvite: printInvite,
      digitalInvite: digitalInvite,
      newsletterReminder: newsletterReminder,
      emailReminder: emailReminder,
      scriptHelp: scriptHelp,
      printedProgram: printedProgram,
      digitalProgram: digitalProgram,
      needCreatedMedia: needCreatedMedia,
      needPhotographer: needPhotographer,
      inviteContactIds: inviteContactIds
    };
    UpsertCommunication(args, eventId);
  };
  const upsertFacilities = (eventId) => {
    const args = {
      setup: setup,
      breakdown: breakdown,
      overnight: overnight,
      parking: parking,
      locationSetups: locationSetups,
      presence: facilityPresence
    };
    UpsertFacility(args, eventId);
  };
  const upsertTheaterSupport = (eventId) => {
    const args = {
      itemIds: theaterItemIds,
      notes: theaterNotes
    };
    UpsertTheater(args, eventId);
  };
  const upsertTech = (eventId) => {
    const args = {
      presence: presence,
      equipment: equipment,
      help: help,
      details: details
    };
    UpsertTechSupport(args, eventId);
  };
  const upsertVitual = (eventId) => {
    const args = {
      // webinar: webinar,
      registration: registration,
      chatEnabled: chatEnabled,
      qaEnabled: qaEnabled,
      recording: recording,
      reminder: reminder,
      transcript: transcript,
      registrantList: registrantList,
      zoomLink: zoomLink,
      qaSupportPerson: qaSupportPerson,
      hostContactId: hostContactId,
      panelistIds: panelistIds ? panelistIds : []
    };
    UpsertVirtualInfo(args, eventId);
  };

  /* Complete Updates */
  const completeUpdate = (id) => {
    if (id) {
      axios({
        method: 'get',
        url: `https://forms-dev.winsor.edu/api/events/${id}/complete-update`, // move to .env
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }).then(function (response) {
        if (response.status === 200) {
          setShowLoading(false);
          navigate('/event-forms');
        }
      });
    }
  };
  const completeSubmission = (id) => {
    if (id) {
      axios({
        method: 'get',
        url: `https://forms-dev.winsor.edu/api/events/${id}/complete-submission`, // move to .env
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            setShowLoading(false);
            navigate('/event-forms');
          } else {
            setServerErrorStatus(response.status);
            handleClickOpen();
            return response.json();
          }
        })
        .then((res) => {
          setShowLoading(false);
          setServerError(
            serverErrorStatus === 500
              ? 'An email detailing the problem has been sent to you.'
              : res.error
          );
        })
        .catch(() => {});
    }
  };
  /* Complete Updates */

  const handleChange = (value, newValue, find) => {
    locationArray.map((row, index) => {
      if (index === find) {
        setLocationValue(newValue);
      }
      return null;
    });
  };

  const handleRoomChange = (event, index) => {
    selectedLocations[index] = event?.id;
    setSelectedItemsIds(selectedLocations);
    roomDynamic[index] = event?.label;
    setRoomDynamic(roomDynamic);
  };

  const handleSecondRoomChange = (event, index) => {
    customDynamicRoom[index] = event?.id;
    setCustomDyamicRoom(customDynamicRoom);
  };

  const addAnotherLocation = () => {
    setLocationArray([
      ...locationArray,
      {
        id: locationArray.length + 1
      }
    ]);
    roomDynamic[locationArray.length] = '';
    // setRoom(roomDynamic);
    customDynamicRoom[locationArray.length] = '';
    setCustomDyamicRoom(customDynamicRoom);
  };

  const removeLocation = (index) => {
    locationArray.splice(index, 1);
    setLocationArray([...locationArray]);
    selectedLocations.splice(index, 1);
    setSelectedLocations(selectedLocations);
    roomDynamic.splice(index, 1);
    setRoomDynamic(roomDynamic);
    customDynamicRoom.splice(index, 1);
    setCustomDyamicRoom(customDynamicRoom);
  };

  const communicationCheckbox = (data, row, index) => {
    switch (
      row
      // case 'Printed Invitation':
      //   setPrintedInvite(data.target.checked);
      //   break;
      // case 'Help with a Script':
      //   setScriptHelp(data.target.checked);
      //   break;
      // case 'Digital Program':
      //   setDigitalProgram(data.target.checked);
      //   break;
      // default:
      //   break;
    ) {
    }
  };

  const foodArray = (e) => {};

  const handleSaveCustomLocation = (e) => {
    const data = new URLSearchParams();
    data.append('name', customName);
    data.append('isPublic', isPublic);
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(data)
    };

    fetch(`https://forms-dev.winsor.edu/api/events/location`, requestOptions)
      .then((response) => response.json())
      .then(() => {})
      .catch((err) => console.log(err));
  };

  const handleFileUpload = (event) => {
    // setFile(event.target?.files[0]);
    const formData = new FormData();
    formData.append('file', event.target?.files[0]);

    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      body: formData
    };

    fetch(
      `https://forms-dev.winsor.edu/api/events/${eventId}/file-upload`,
      requestOptions
    )
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
    loadData();
  };

  const loadData = () => {
    if (eventId) {
      axios({
        method: 'get',
        url: `https://forms-dev.winsor.edu/api/events/${eventId}`, // move to .env
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }).then(function (response) {
        if (response.status === 200) {
          setEventDetails(response.data);
          setLeader(response.data.leaderId);
          response.data.selectedLocations.length >
          response.data.selectedCustomLocations.length
            ? setLocationArray(response.data.selectedLocations)
            : setLocationArray(response.data.selectedCustomLocations);
          setRoomDynamic(response.data.selectedLocations);
          setCustomDyamicRoom(response.data.selectedCustomLocations);
          setFood(response.data.hasCatering);
          setTheater(response.data.hasTheaterRequest);
          setCommunication(response.data.hasMarCom);
          setFacilitiesSupport(response.data.hasFacilitiesInfo);
          setTechSupport(response.data.hasTechRequest);
          setStartDate(response.data.start.substring(0, 10));
          setStartTime(response.data.start.substring(11, 16));
          setEndDate(response.data.end.substring(0, 10));
          setEndTime(response.data.end.substring(11, 16));
          setEventName(response.data.summary);
          setDescription(response.data.description);
          setPreaprovalDate(response.data.preaprovalDate);
          setDivisionHead(true);
        }
      });
    }
  };

  const handleClickOpen = () => {
    setShowLoading(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: 'https://forms-dev.winsor.edu/api/users/employees', // move to .env
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(function (response) {
      if (response.status === 200) {
        setEmployees(response.data);
      }
    });
  }, []);
  useEffect(() => {
    axios({
      method: 'get',
      url: 'https://forms-dev.winsor.edu/api/events/location', // move to .env
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(function (response) {
      if (response.status === 200) {
        setRooms(response.data);
      }
    });
  }, []);

  useEffect(() => {
    axios({
      method: 'get',
      url: 'https://forms-dev.winsor.edu/api/events/location/custom', // move to .env
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(function (response) {
      if (response.status === 200) {
        setCustom(response.data);
      }
    });
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    fetch(`https://forms-dev.winsor.edu/api/budget-codes`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setBudgetCodes(data);
      });
  }, []);

  if (!employees || !rooms || (!eventDetails && eventId) || showLoading) {
    return (
      <Box sx={{ width: 300, position: 'absolute', left: '45%', top: '48%' }}>
        <Box marginLeft={4}>
          <CircularProgress />
        </Box>
        <Box>
          <Typography>Please be patient...</Typography>
        </Box>
      </Box>
    );
  } else {
    return (
      <Grid width="100vw">
        {/* <EventBase /> */}
        <MiddleHeader
          title={eventName ? eventName : 'Start a new Event Form'}
          variant='h4'
        />
        <MiddleHeader title='Basic Event Details' />
        <ErrorDialog
          handleClose={handleClose}
          serverError={serverError}
          open={open}
        />
        <Stack>
          <Stack>
            <Box
              sx={{
                border: 'solid 1px lightgray',
                padding: 3,
                borderRadius: 4,
                width: '90%'
              }}
              marginBottom={2}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                marginBottom={3}>
                <Box width={'10%'}>
                  <Typography marginRight={5}>
                    Event Name<span style={{ color: 'red' }}>*</span>
                  </Typography>
                  {submitting && !eventName ? (
                    <Typography fontSize={9} color={'red'}>
                      Required
                    </Typography>
                  ) : null}
                </Box>
                <TextField
                  margin='normal'
                  required
                  id='event-name'
                  style={{
                    width: '55%',
                    borderColor: 'lightgray',
                    fontFamily: 'monospace',
                    backgroundColor: '#f3f5f8'
                  }}
                  autoFocus
                  size='small'
                  value={eventName ? eventName : eventDetails?.summary || ''}
                  onChange={e => setEventName(e.target.value)}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                marginBottom={3}>
                <Box width={'10%'}>
                  <Typography marginRight={5}>
                    Description<span style={{ color: 'red' }}>*</span>
                  </Typography>
                  {submitting && !description ? (
                    <Typography fontSize={9} color={'red'}>
                      Required
                    </Typography>
                  ) : null}
                </Box>
                <textarea
                  className='description'
                  style={{
                    fontFamily: 'monospace',
                    fontSize: '15px',
                    backgroundColor: '#f3f5f8',
                    borderRadius: '12px',
                    padding: '15px',
                    border: 'solid 1px lightgray'
                  }}
                  cols={95}
                  rows={10}
                  onChange={e => setDescription(e.target.value)}
                  value={description ? description : eventDetails?.description}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                marginBottom={1}
                marginLeft={'10%'}>
                <Typography>Start Date</Typography>
                <Box width={'15%'} marginLeft={3} marginRight={1}>
                  <input
                    type='date'
                    className='date'
                    value={startDate || ''}
                    style={{ backgroundColor: '#f3f5f8' }}
                    onChange={e => {
                      setStartDate(e.target.value);
                      setEndDate(e.target.value);
                    }}
                  />
                  {submitting && !startDate ? (
                    <Typography fontSize={9} color={'red'}>
                      Required
                    </Typography>
                  ) : null}
                </Box>
                <Typography>Time</Typography>
                <Box width={'15%'}>
                  <input
                    type='time'
                    className='time'
                    value={startTime || ''}
                    style={{ backgroundColor: '#f3f5f8' }}
                    onChange={e => {
                      setStartTime(e.target.value);
                    }}
                  />
                  {submitting && !startTime ? (
                    <Typography fontSize={9} color={'red'}>
                      Required
                    </Typography>
                  ) : null}
                </Box>
                <Box>
                  <Typography>End Date</Typography>
                </Box>
                <Box width={'15%'} marginLeft={3}>
                  <input
                    type='date'
                    className='date'
                    value={endDate || ''}
                    style={{ backgroundColor: '#f3f5f8' }}
                    onChange={e => {
                      setEndDate(e.target.value);
                    }}
                  />
                  {submitting && !endDate ? (
                    <Typography fontSize={9} color={'red'}>
                      Required
                    </Typography>
                  ) : null}
                </Box>
                <Typography>Time</Typography>
                <Box width={'15%'}>
                  <input
                    type='time'
                    className='time'
                    value={endTime || ''}
                    style={{ backgroundColor: '#f3f5f8' }}
                    onChange={e => setEndTime(e.target.value)}
                  />
                  {submitting && !endTime ? (
                    <Typography fontSize={9} color={'red'}>
                      Required
                    </Typography>
                  ) : null}
                </Box>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                marginBottom={3}>
                <Button onClick={e => addAnotherLocation(e)} variant='outlined'>
                  Add Location
                </Button>
              </Box>
              {locationArray.length > 0 &&
                locationArray.map((array, index) => {
                  if (array !== null) {
                    return (
                      <>
                        <Box
                          sx={{
                            border: 'solid 1px lightgray',
                            padding: 3,
                            borderRadius: 4,
                            width: 'fit-content'
                          }}
                          marginBottom={3}
                          key={index}>
                          <Box sx={{ width: '100%' }}>
                            <Box
                              sx={{
                                borderBottom: 1,
                                borderColor: 'divider'
                              }}>
                              <Tabs
                                value={locationValue}
                                onChange={(e, y) => handleChange(e, y, index)}
                                aria-label='basic tabs example'>
                                <Tab
                                  label='On Campus Location'
                                  {...a11yProps(0)}
                                />
                                <Tab
                                  label='My Custom Locations'
                                  {...a11yProps(1)}
                                />
                                <Tab
                                  label='New Custom Location'
                                  {...a11yProps(2)}
                                />
                              </Tabs>
                            </Box>
                            <TabPanel value={locationValue} index={0}>
                              <Stack direction={'row'}>
                                <Autocomplete
                                  disablePortal
                                  id='combo-box-demo'
                                  onChange={(event, newValue) => {
                                    setRoomValidation(false);
                                    handleRoomChange(newValue, index);
                                  }}
                                  value={rooms.find(
                                    option => option.id === roomDynamic[index]
                                  )}
                                  options={rooms}
                                  sx={{ width: 300 }}
                                  getOptionLabel={option => {
                                    if (typeof option === 'string') {
                                      return option;
                                    }
                                    if (option.label) {
                                      return option.label;
                                    }
                                    return option.label;
                                  }}
                                  renderOption={(props, option) => (
                                    <li {...props}>{option.label}</li>
                                  )}
                                  renderInput={params => (
                                    <TextField {...params} label='' />
                                  )}
                                />
                                <Box marginLeft={2}>
                                  <Button
                                    onClick={e => {
                                      if (roomDynamic[index]) {
                                        setRoomValidation(false);
                                        // addLocationRoom(e, index);
                                        handleSubmit();
                                      } else {
                                        setRoomValidation(true);
                                      }
                                    }}>
                                    Add Location
                                  </Button>
                                </Box>
                              </Stack>
                              {roomValidation ? (
                                <Box>
                                  <div style={{ color: 'red' }}>Required!</div>
                                </Box>
                              ) : null}
                            </TabPanel>
                            <TabPanel value={locationValue} index={1}>
                              <Stack direction={'row'}>
                                <Autocomplete
                                  disablePortal
                                  id='combo-box-demo'
                                  onChange={(event, newValue) => {
                                    setCustomRoomValidation(false);
                                    handleSecondRoomChange(newValue, index);
                                  }}
                                  value={custom.find(
                                    option =>
                                      option.id === customDynamicRoom[index]
                                  )}
                                  options={custom}
                                  sx={{ width: 300 }}
                                  getOptionLabel={option => {
                                    if (typeof option === 'string') {
                                      return option;
                                    }
                                    if (option.label) {
                                      return option.label;
                                    }
                                    return option.label;
                                  }}
                                  renderOption={(props, option) => (
                                    <li {...props}>{option.label}</li>
                                  )}
                                  renderInput={params => (
                                    <TextField {...params} label='' />
                                  )}
                                />
                                <Box marginLeft={2}>
                                  <Button
                                    onClick={e => {
                                      if (customDynamicRoom[index]) {
                                        setCustomRoomValidation(false);
                                        handleSubmit();
                                      } else {
                                        setCustomRoomValidation(true);
                                      }
                                    }}>
                                    Add Location
                                  </Button>
                                </Box>
                              </Stack>
                              {customRoomValidation ? (
                                <Box>
                                  <div style={{ color: 'red' }}>Required!</div>
                                </Box>
                              ) : null}
                            </TabPanel>
                            <TabPanel value={locationValue} index={2}>
                              <Box
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'center'}
                                marginBottom={1}>
                                <Box width={'30%'}>
                                  <Typography component={'div'} marginRight={5}>
                                    Location Name
                                  </Typography>
                                </Box>
                                <TextField
                                  margin='normal'
                                  required
                                  id='event-name'
                                  style={{
                                    width: '55%',
                                    borderColor: 'lightgray'
                                  }}
                                  autoFocus
                                  size='small'
                                  onChange={e => setCustomName(e.target.value)}
                                />
                              </Box>
                              <Box
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'center'}
                                marginBottom={1}></Box>
                              <Box
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'center'}
                                marginBottom={1}>
                                <Box>
                                  <Checkbox
                                    label='Label'
                                    value={isPublic}
                                    onChange={e =>
                                      setIsPublic(e.target.checked)
                                    }
                                  />
                                  {techSupport ? (
                                    <Typography component={'div'}>
                                      Form{' '}
                                    </Typography>
                                  ) : null}{' '}
                                </Box>
                                <Box>
                                  <Typography component={'div'}>
                                    Make This Location Public
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                marginBottom={1}>
                                <Button
                                  size='small'
                                  onClick={e => handleSaveCustomLocation(e)}>
                                  Add Location Info
                                </Button>
                              </Box>
                            </TabPanel>
                          </Box>
                          <Box
                            sx={{ width: '50%' }}
                            marginBottom={1}
                            marginLeft={5}>
                            <Box
                              display={'flex'}
                              flexDirection={'row'}
                              alignItems={'center'}
                              marginBottom={1}>
                              <Button
                                variant='outlined'
                                onClick={() => removeLocation(index)}
                                startIcon={<DeleteIcon />}
                                color='error'>
                                Remove Location
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                        <Box marginBottom={3}>
                          <Button
                            onClick={e => addAnotherLocation(e)}
                            variant='outlined'>
                            Add Another Location
                          </Button>
                        </Box>
                      </>
                    );
                  } else {
                    return 'No Array';
                  }
                })}
            </Box>
            <Box
              sx={{
                border: 'solid 1px lightgray',
                padding: 3,
                borderRadius: 4,
                width: '90%'
              }}
              marginBottom={2}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                marginBottom={1}>
                <Box width={'10%'}>
                  <Typography component={'div'}>Attachment</Typography>
                </Box>
                <Box width={'300px'}>
                  <input
                    variant='outlined'
                    size='small'
                    onChange={e => handleFileUpload(e)}
                    type='file'
                    className='file-upload'
                    accept='image/png, image/jpeg, .pdf, .txt, .doc, docx'
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  border: 'solid 1px lightgray',
                  borderRadius: '4px',
                  width: 'fit-content',
                  padding: '15px',
                  marginBottom: 3,
                  marginTop: 3
                }}>
                {eventDetails?.attachments.length > 0
                  ? eventDetails?.attachments.map((files, fileIndex) => {
                      return (
                        <Box
                          key={fileIndex}
                          display={'flex'}
                          flexDirection={'row'}
                          alignItems={'center'}
                          marginBottom={1}>
                          <Box marginRight={1}>
                            <AttachmentIcon sx={{ color: 'GrayText' }} />{' '}
                          </Box>
                          <Box>
                            <div>{files.fileName}</div>
                          </Box>
                        </Box>
                      );
                    })
                  : null}
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                marginBottom={1}>
                <Box width={'10%'}>
                  <Typography>Leader</Typography>
                </Box>
                <Box>
                  <Autocomplete
                    disablePortal
                    id='combo-box-demo'
                    onChange={(event, newValue) => setLeader(newValue?.id)}
                    value={employees.find(option => option.id === leader)}
                    options={employees}
                    sx={{ width: 300 }}
                    getOptionLabel={option => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.firstName + option.lastName) {
                        return option.firstName + ' ' + option.lastName;
                      }
                      return option.firstName + ' ' + option.lastName;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>
                        {option.firstName + ' ' + option.lastName}
                      </li>
                    )}
                    renderInput={params => <TextField {...params} label='' />}
                  />
                </Box>
                {submitting && !leader ? (
                  <Typography fontSize={9} color={'red'}>
                    Required
                  </Typography>
                ) : null}
              </Box>
              <Box
                sx={{
                  border: 'solid 1px lightgray',
                  width: 'fit-content',
                  padding: '10px',
                  borderRadius: '5px',
                  backgroundColor: '#F0F7FF',
                  height: '40px',
                  wordWrap: '-moz-initial'
                }}
                marginBottom={1}>
                <Typography marginTop={1} marginLeft={1}>
                  Note that Event Forms will not be sent for approval until Room
                  use has been cleared by the Registrar
                </Typography>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                marginBottom={3}>
                <Box>
                  <Checkbox
                    label='Label'
                    value={divisionHead || false}
                    checked={divisionHead || false}
                    onChange={e => setDivisionHead(e.target.checked)}
                  />
                </Box>
                <Box>
                  <Typography>Division Head Approved</Typography>
                </Box>
                {submitting && !divisionHead ? (
                  <Typography fontSize={9} color={'red'}>
                    Required
                  </Typography>
                ) : null}
                <Box>
                  <Checkbox label='Label' disabled={true} checked={true} />
                </Box>
                <Box marginRight={8}>
                  <Typography>Room Use Approved</Typography>
                </Box>
                <Box width={'13%'}>
                  <Typography>Date of Calendar Approval</Typography>
                </Box>
                <Box marginRight={2}>
                  <input
                    type='date'
                    className='date'
                    value={preaprovalDate || Date()}
                    style={{ backgroundColor: '#f3f5f8' }}
                    onChange={e => {
                      setPreaprovalDate(e.target.value);
                    }}
                  />
                </Box>
                {submitting && !preaprovalDate ? (
                  <Typography fontSize={9} color={'red'}>
                    Required
                  </Typography>
                ) : null}
                <Box marginRight={1}>
                  <Typography>Expected Number of Attendees</Typography>
                </Box>
                <Box>
                  <TextField
                    margin='normal'
                    required
                    id='event-name'
                    style={{ width: '100%', borderColor: 'lightgray' }}
                    autoFocus
                    type={'number'}
                    size='small'
                    value={
                      attendeeCount
                        ? attendeeCount
                        : eventDetails?.attendeeCount
                    }
                    onChange={e => setAttendeeCount(e.target.value)}
                  />
                </Box>
              </Box>
            </Box>
            <Box alignItems={'center'} marginBottom={1}>
              {theater ? (
                <Box marginLeft={'0%'}>
                  <TheaterSupport
                    theaterArray={theaterArray}
                    handleNotes={handleNotes}
                    eventId={eventId}
                    hasTheater={eventDetails?.hasTheaterRequest}
                    handleTheaterArray={handleTheaterArray}
                    submitting={submitting}
                  />
                </Box>
              ) : null}
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                width={'25%'}>
                <Checkbox
                  label='Label'
                  checked={theater || false}
                  onChange={e => setTheater(e.target.checked)}
                />
                <Typography>This event Needs Theater Support</Typography>
              </Box>
            </Box>
            <Box width={'93%'}>
              <Divider light />
            </Box>
            <Box alignItems={'center'} marginBottom={1}>
              {techSupport ? (
                <Box>
                  <TechnologySupport
                    eventId={eventId}
                    fetchTech={eventDetails?.hasTechRequest}
                    showZoom={eventDetails?.hasZoom}
                    handleHelp={handleHelp}
                    handlePresence={handlePresence}
                    handleEquipment={handleEquipment}
                    handleDetails={handleDetails}
                    submitting={submitting}
                    handleRegistration={handleRegistration}
                    handleChatEnabled={handleChatEnabled}
                    handleQAEnabled={handleQAEnabled}
                    handleRecording={handleRecording}
                    handleRemider={handleRemider}
                    handleTranscript={handleTranscript}
                    handleRegistrantList={handleRegistrantList}
                    handleZoomLink={handleZoomLink}
                    handleQASupportPerson={handleQASupportPerson}
                    handleContactId={handleContactId}
                    handlePanelList={handlePanelList}
                  />{' '}
                </Box>
              ) : null}
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                minWidth={'27%'}>
                <Checkbox
                  checked={techSupport || false}
                  label='Label'
                  onChange={e => setTechSupport(e.target.checked)}
                />
                <Typography>This event needs Technology Support</Typography>
              </Box>
            </Box>
            <Box width={'93%'}>
              <Divider light />
            </Box>
            <Box alignItems={'center'} marginBottom={1}>
              {food ? (
                <>
                  <Box>
                    <CateringSupport
                      eventId={eventId}
                      budgetCodes={budgetCodes}
                      food={eventDetails?.hasCatering}
                      handleServers={handleServers}
                      handleCleanup={handleCleanup}
                      handleBudgetCode={handleBudgetCode}
                      handleAddToList={handleAddToList}
                    />
                  </Box>
                </>
              ) : null}
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                minWidth={'26%'}>
                <Checkbox
                  label='Label'
                  checked={food || false}
                  onChange={e => setFood(e.target.checked)}
                />
                <Typography>This event needs Food Service</Typography>
              </Box>
            </Box>
            <Box width={'93%'}>
              <Divider light />
            </Box>
            <Box alignItems={'center'} marginBottom={1}>
              {facilitiesSupport ? (
                <Box>
                  <FacilitiesSupport
                    techArray={foodArray}
                    eventId={eventId}
                    fetchFacilities={eventDetails?.hasFacilitiesInfo}
                    handleSetup={handleSetup}
                    handleParking={handleParking}
                    handleFacilityPresence={handleFacilityPresence}
                    handleBreakdown={handleBreakdown}
                    handleOvernight={handleOvernight}
                    handleLocationSetup={handleLocationSetup}
                    handleAddToArray={handleAddToArray}
                    handleRemoveLocation={handleRemoveLocation}
                  />{' '}
                </Box>
              ) : null}
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                minWidth={'26%'}>
                <Checkbox
                  label='Label'
                  checked={facilitiesSupport || false}
                  onChange={e => setFacilitiesSupport(e.target.checked)}
                />
                <Typography>This event needs Facilities Support</Typography>
              </Box>
            </Box>
            <Box width={'93%'}>
              <Divider light />
            </Box>
            <Box alignItems={'center'} marginBottom={1}>
              {communication ? (
                <Box>
                  <CommunicationSupport
                    communicationCheckbox={communicationCheckbox}
                    eventId={eventId}
                    communication={eventDetails?.hasMarCom}
                    leader={leader}
                    handlePrintInvite={handlePrintInvite}
                    handleDigitalInvite={handleDigitalInvite}
                    handleNewsletter={handleNewsletter}
                    handleEmail={handleEmail}
                    handlePrintProgram={handlePrintProgram}
                    handleMedia={handleMedia}
                    handlePhotographer={handlePhotographer}
                    handleScript={handleScript}
                    handleDigitalProgram={handleDigitalProgram}
                    handleInviteContact={handleInviteContact}
                    handleDeleteContact={handleDeleteContact}
                  />{' '}
                </Box>
              ) : null}
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                minWidth={'26%'}>
                <Checkbox
                  label='Label'
                  checked={communication || false}
                  onChange={e => setCommunication(e.target.checked)}
                />
                <Typography>This event needs Communications Support</Typography>
              </Box>
            </Box>
            <Box width={'93%'} marginBottom={2}></Box>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              marginBottom={10}>
              <Box>
                <Button variant='outlined' onClick={() => handleSubmit()}>
                  Submit
                </Button>
                {submitting && showErrors ? (
                  <Typography fontSize={9} color={'red'}>
                    Complete required fields
                  </Typography>
                ) : null}
              </Box>
            </Grid>
          </Stack>
        </Stack>
      </Grid>
    );
  }
}

export default GenericEventForm;
