import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function a11yProps(index) {
  return {
    id: `student-calendar-tab-tab-${index}`,
    'aria-controls': `student-calendar-tab-panel-${index}`
  };
}

export const AssessmentCalendarTab = ({
  onToggle,
  isAdmin = false,
  updateValue = 0
}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onToggle(newValue);
  };

  React.useEffect(() => {
    setValue(updateValue);
  }, [updateValue]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='Assessment Calendar Tab'>
          <Tab label='Calendar' {...a11yProps(0)} />
          <Tab label='My Assessments' {...a11yProps(1)} />
          <Tab label='Late Work' {...a11yProps(2)} />
          {isAdmin && <Tab label='Division Head' {...a11yProps(3)} />}
        </Tabs>
      </Box>
    </Box>
  );
};