import {
  Box,
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';

import { rtkApiSlice } from 'utils/rtk-api-slices';
import './FieldTripForm.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 2, ml: 2 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function FieldTripBasicInfo({
  rooms,
  submitting,
  setRoom,
  custom,
  setCustomRoomValidation,
  customRoomValidation,
  locationArray,
  setLocationArray,
  roomDynamic,
  setRoomDynamic,
  selectedLocations,
  setSelectedLocations,
  customDynamicRoom,
  setCustomDyamicRoom,
  roomValidation,
  setRoomValidation,
  next,
  setNext,
  handleInput
}) {
  const isAdjusted = useMediaQuery('(min-width:768px)');
  const [customName, setCustomName] = useState();
  const [isPublic, setIsPublic] = useState(false);
  const [locationValue, setLocationValue] = useState(0);

  const [eventName, setEventName] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();

  const handleInputData = () => {
    handleInput({
      eventName,
      description,
      startDate,
      endDate,
      startTime,
      endTime
    });
    setNext(1);
  };

  const [userEventActions, userEventActionsReponse] =
    rtkApiSlice.useUserEventActionsMutation();

  const handleChange = (value, newValue, find) => {
    locationArray.map((row, index) => {
      if (index === find) {
        setLocationValue(newValue);
      } else {
      }
      return null;
    });
  };

  const handleRoomChange = (event, index) => {
    selectedLocations[index] = event.id;
    // setSelectedItemsIds(selectedLocations);
    roomDynamic[index] = event.id;
    setRoomDynamic(roomDynamic);
  };

  const handleSecondRoomChange = (event, index) => {
    customDynamicRoom[index] = event.id;
    setCustomDyamicRoom(customDynamicRoom);
  };

  const addAnotherLocation = () => {
    setLocationArray([
      ...locationArray,
      {
        id: locationArray?.length + 1
      }
    ]);
    roomDynamic[locationArray?.length] = '';
    setRoom(roomDynamic);
    customDynamicRoom[locationArray?.length] = '';
    setCustomDyamicRoom(customDynamicRoom);
  };

  const handleSaveCustomLocation = async e => {
    await userEventActions({
      params: `/location?name=${customName}&isPublic=${isPublic}`
    });
  };

  const removeLocation = (index) => {
    locationArray?.splice(index, 1);
    setLocationArray([...locationArray]);
    selectedLocations?.splice(index, 1);
    setSelectedLocations(selectedLocations);
    roomDynamic?.splice(index, 1);
    setRoomDynamic(roomDynamic);
    customDynamicRoom?.splice(index, 1);
    setCustomDyamicRoom(customDynamicRoom);
  };

  const handleSubmit = () => {};

  return next === 0 ? (
    <>
      <Box
        sx={{
          border: 'solid 1px lightgray',
          padding: 3,
          borderRadius: 4
        }}
        marginBottom={2}>
        <Box alignItems={'center'} marginBottom={3}>
          <Box>
            <Typography>
              Event Name<span style={{ color: 'red' }}>*</span>
            </Typography>
          </Box>
          <Box>
            <TextField
              margin='normal'
              required
              id='event-name'
              style={{
                width: isAdjusted ? '50%' : '95%',
                borderColor: 'lightgray',
                fontFamily: `'Times New Roman', Times, serif`,
                backgroundColor: '#f3f5f8'
              }}
              autoFocus
              size='small'
              value={eventName || ''}
              onChange={(e) => setEventName(e.target.value)}
            />
            {submitting && !eventName ? (
              <Typography fontSize={9} color={'red'}>
                Required
              </Typography>
            ) : null}
          </Box>
        </Box>
        <Box alignItems={'center'} marginBottom={3}>
          <Box>
            <Typography>
              Description<span style={{ color: 'red' }}>*</span>
            </Typography>
          </Box>
          <textarea
            className='description'
            style={{
              fontFamily: `'Times New Roman', Times, serif`,
              fontSize: 'large',
              backgroundColor: '#f3f5f8',
              borderRadius: '12px',
              padding: '15px',
              border: 'solid 1px lightgray',
              width: '90%'
            }}
            rows={10}
            onChange={(e) => setDescription(e.target.value)}
            value={description || ''}
          />
          {submitting && !description ? (
            <Typography fontSize={9} color={'red'}>
              Required
            </Typography>
          ) : null}
        </Box>
        <Box alignItems={'center'} marginBottom={1}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent='space-between'
            alignItems={'center'}>
            <Typography style={{ width: '20%', textAlign: 'right' }}>
              Start Date
            </Typography>
            <input
              type='date'
              className='date'
              value={startDate}
              style={{
                backgroundColor: '#f3f5f8',
                width: isAdjusted ? '50%' : '80%'
              }}
              onChange={(e) => {
                setStartDate(e.target.value);
                setEndDate(e.target.value);
              }}
            />
            {submitting && !startDate ? (
              <Typography fontSize={9} color={'red'}>
                Required
              </Typography>
            ) : null}
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent='space-between'
            alignItems={'center'}>
            <Typography style={{ width: '20%', textAlign: 'right' }}>
              Time
            </Typography>
            <input
              type='time'
              className='time'
              value={startTime || ''}
              style={{
                backgroundColor: '#f3f5f8',
                width: isAdjusted ? '50%' : '80%'
              }}
              onChange={(e) => {
                setStartTime(e.target.value);
              }}
            />
            {submitting && !startTime ? (
              <Typography fontSize={9} color={'red'}>
                Required
              </Typography>
            ) : null}
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent='space-between'
            alignItems={'center'}>
            <Typography style={{ width: '20%', textAlign: 'right' }}>
              End Date
            </Typography>
            <input
              type='date'
              className='date'
              value={endDate}
              style={{
                backgroundColor: '#f3f5f8',
                width: isAdjusted ? '50%' : '80%'
              }}
              onChange={(e) => setEndDate(e.target.value)}
            />
            {submitting && !endDate ? (
              <Typography fontSize={9} color={'red'}>
                Required
              </Typography>
            ) : null}
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent='space-between'
            alignItems={'center'}>
            <Typography style={{ width: '20%', textAlign: 'right' }}>
              Time
            </Typography>
            <input
              type='time'
              className='time'
              value={endTime || ''}
              style={{
                backgroundColor: '#f3f5f8',
                width: isAdjusted ? '50%' : '80%'
              }}
              onChange={(e) => {
                setEndTime(e.target.value);
              }}
            />
            {submitting && !endTime ? (
              <Typography fontSize={9} color={'red'}>
                Required
              </Typography>
            ) : null}
          </Box>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          marginBottom={3}>
          <Button onClick={(e) => addAnotherLocation(e)}>Add Location</Button>
        </Box>
        {locationArray?.length > 0 &&
          locationArray.map((array, index) => {
            if (array !== null) {
              return (
                <Box
                  sx={{
                    border: 'solid 1px lightgray',
                    borderRadius: 4,
                    padding: 2,
                    marginBottom: 2,
                    width: '63%'
                  }}
                  marginBottom={3}
                  key={index}>
                  <Box sx={{ width: '92%', padding: 3 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs
                        value={locationValue}
                        onChange={(e, y) => handleChange(e, y, index)}
                        aria-label='basic tabs example'>
                        <Tab label='On Campus Location' {...a11yProps(0)} />
                        <Tab label='My Custom Locations' {...a11yProps(1)} />
                        <Tab label='New Custom Location' {...a11yProps(2)} />
                      </Tabs>
                    </Box>
                    <TabPanel value={locationValue} index={0}>
                      <Stack direction={'row'}>
                        <Autocomplete
                          disablePortal
                          id='combo-box-demo'
                          onChange={(event, newValue) => {
                            setRoomValidation(false);
                            handleRoomChange(newValue, index);
                          }}
                          value={rooms?.find(
                            (option) => option.id === roomDynamic[index]
                          )}
                          options={rooms}
                          sx={{ width: 300 }}
                          getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.label) {
                              return option.label;
                            }
                            return option.label;
                          }}
                          renderOption={(props, option) => (
                            <li {...props}>{option.label}</li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label='' />
                          )}
                        />
                        <Box marginLeft={2}>
                          <Button
                            onClick={(e) => {
                              if (roomDynamic[index]) {
                                setRoomValidation(false);
                                // addLocationRoom(e, index);
                                handleSubmit();
                              } else {
                                setRoomValidation(true);
                              }
                            }}>
                            Add Location
                          </Button>
                        </Box>
                      </Stack>
                      {roomValidation ? (
                        <Box>
                          <div style={{ color: 'red' }}>Required!</div>
                        </Box>
                      ) : null}
                    </TabPanel>
                    <TabPanel value={locationValue} index={1}>
                      <Stack direction={'row'}>
                        <Autocomplete
                          disablePortal
                          id='combo-box-demo'
                          onChange={(event, newValue) => {
                            setCustomRoomValidation(false);
                            handleSecondRoomChange(newValue, index);
                          }}
                          value={custom?.find(
                            (option) => option.id === customDynamicRoom[index]
                          )}
                          options={custom}
                          sx={{ width: 300 }}
                          getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option?.label) {
                              return option?.label;
                            }
                            return option?.label;
                          }}
                          renderOption={(props, option) => (
                            <li {...props}>{option?.label}</li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label='' />
                          )}
                        />
                        <Box marginLeft={2}>
                          <Button
                            onClick={(e) => {
                              if (customDynamicRoom[index]) {
                                setCustomRoomValidation(false);
                                handleSubmit();
                              } else {
                                setCustomRoomValidation(true);
                              }
                            }}>
                            Add Location
                          </Button>
                        </Box>
                      </Stack>
                      {customRoomValidation ? (
                        <Box>
                          <div style={{ color: 'red' }}>Required!</div>
                        </Box>
                      ) : null}
                    </TabPanel>
                    <TabPanel value={locationValue} index={2}>
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        marginBottom={1}>
                        <Box width={'30%'}>
                          <Typography component={'div'} marginRight={5}>
                            Location Name
                          </Typography>
                        </Box>
                        <TextField
                          margin='normal'
                          required
                          id='event-name'
                          style={{
                            width: '55%',
                            borderColor: 'lightgray'
                          }}
                          autoFocus
                          size='small'
                          // value={customName}
                          onChange={(e) => setCustomName(e.target.value)}
                        />
                      </Box>
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        marginBottom={1}></Box>
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        marginBottom={1}>
                        <Box>
                          <Checkbox
                            label='Label'
                            value={isPublic}
                            onChange={(e) => setIsPublic(e.target.checked)}
                          />
                          {/* {techSupport ? (
                              <Typography component={'div'}>Form </Typography>
                            ) : null}{' '} */}
                        </Box>
                        <Box>
                          <Typography component={'div'}>
                            Make This Location Public
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        marginBottom={1}>
                        <Button
                          size='small'
                          onClick={(e) => handleSaveCustomLocation(e)}>
                          Add Location Info
                        </Button>
                      </Box>
                    </TabPanel>
                  </Box>
                  <Box sx={{ width: '50%' }} marginBottom={1} marginLeft={5}>
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      marginBottom={1}>
                      <Button
                        size='small'
                        onClick={() => removeLocation(index)}
                        startIcon={<DeleteIcon />}
                        color='error'
                        variant='outlined'>
                        Remove Location
                      </Button>
                    </Box>
                  </Box>
                </Box>
              );
            } else {
              return 'No Array';
            }
          })}
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'flex-end'}
        marginBottom={3}>
        <Button></Button>
        <Button onClick={(e) => handleInputData()}>Next</Button>
      </Box>
    </>
  ) : (
    <></>
  );
}

export default FieldTripBasicInfo;