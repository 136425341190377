import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { API_ROUTES } from 'utils/constants';

interface InputData {
  [key: string]: any;
}

export const apiSliceBuilderQuery = (
  queryBuilder: EndpointBuilder<BaseQueryFn, any, any>
) => {
  const endpointObj = API_ROUTES.reduce((acc, { name, method, url }) => {
    const query =
      method === 'DELETE' || method === 'CUSTOM_DELETE'
        ? queryBuilder.mutation({
            query: (params: any) => ({
              url:
                method === 'CUSTOM_DELETE'
                  ? `${params?.url}`
                  : `${url}/${params}`,
              method: 'DELETE'
            })
          })
        : method === 'CUSTOM_GET'
        ? queryBuilder.query({
            query: (option?: any) => ({
              url: option?.url,
              method: 'GET'
            })
          })
        : queryBuilder[
            method === 'POST' || method === 'PATCH' ? 'mutation' : 'query'
          ]({
            query: (inputData: InputData | any) => ({
              url: inputData?.params ? `${url}${inputData?.params}` : url,
              method: inputData?.method ? inputData?.method : method,
              body:
                method === 'POST' || method === 'PATCH'
                  ? inputData?.body
                    ? inputData?.body
                    : inputData
                  : undefined
            })
          });
    return { ...acc, [name]: query };
  }, {});

  return endpointObj;
};