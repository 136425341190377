import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { rtkApiSlice } from 'utils/rtk-api-slices';

function AssessmentAPExam({
  hideAPExam,
  handleEndDate,
  handleCourseName,
  handleStartDate,
  saveExam,
  courseName,
  startDateTime,
  endDateTime,
  studentIds,
}) {
  const [students, setStudents] = useState([]);
  const [student, setStudent] = useState('');
  const [studentArray, setStudentArray] = useState([]);
  const [studentArrayIds, setStudentArrayIds] = useState([]);

  const getStudentsResponse =
    rtkApiSlice.useAssessmentCalendarQuery({
      url: `/assessment-calendar/teachers/student-calendars/list`
    });
  const handleStudents = array => {
    setStudents(array);
    if (studentIds) {
      setStudentArrayIds(studentIds);
      setStudentArray(
        studentIds.map(value => {
          return studentArray?.push(
            array.find(x => x.id === value)?.displayName
          );
        })
      );
      return studentArray;
    }
  };
  useEffect(() => {
    handleStudents(getStudentsResponse?.data);
  }, [getStudentsResponse?.data]);


  if (!students) {
    <Box sx={{ width: 300, position: 'absolute', left: '45%', top: '48%' }}>
      <Box marginLeft={4}>
        <CircularProgress />
      </Box>
      <Box>
        <Typography>Please be patient...</Typography>
      </Box>
    </Box>;
  } else {
    return (
      <Box
        minWidth={'45%'}
        height={'55%'}
        marginRight={2}
        sx={{ borderRadius: 4, border: 'solid 1px lightgray', padding: 3 }}>
        <Stack spacing={2}>
          <TextField
            onChange={e => handleCourseName(e.target.value)}
            value={courseName || ''}
            label={'Course Name'}
          />
          <label>Start Date</label>
          <input
            type='date'
            className='date'
            value={startDateTime || ''}
            style={{ backgroundColor: '#f3f5f8' }}
            onChange={e => handleStartDate(e.target.value)}
          />
          <label>End Date</label>
          <input
            type='date'
            className='date'
            value={endDateTime || ''}
            style={{ backgroundColor: '#f3f5f8' }}
            onChange={e => handleEndDate(e.target.value)}
          />
          <Autocomplete
            disablePortal
            id='combo-box-demo'
            onChange={(event, newValue) => {
              if (newValue?.id) {
                setStudent(newValue?.id);
                studentArray?.push(newValue?.displayName);
                setStudentArray(studentArray);
                studentArrayIds?.push(newValue.id);
                setStudentArrayIds(studentArrayIds);
              }
            }}
            value={students?.find(option => option === student)}
            options={students}
            sx={{ width: 300 }}
            getOptionLabel={option => {
              if (typeof option === 'string') {
                return option;
              }
              if (option?.displayName) {
                return option?.displayName;
              }
              return option?.displayName;
            }}
            renderOption={(props, option) => (
              <li {...props}>{option?.displayName}</li>
            )}
            renderInput={params => <TextField {...params} label='' />}
          />
          <Box>
            {studentArray?.map((row, index) => {
              console.log('final ', studentArray);
              return (
                <Stack
                  direction={'row'}
                  spacing={6}
                  sx={{
                    backgroundColor: index % 2 === 1 ? 'lightgray' : null,
                    borderRadius: 4,
                    padding: 1
                  }}>
                  <Box minWidth={'200px'}>{row}</Box>
                  <Button
                    onClick={() => {
                      setStudentArray(studentArray?.filter(x => x !== row));
                      setStudentArrayIds(
                        studentArrayIds?.filter(x => x !== row)
                      );
                    }}
                    color='error'>
                    Delete
                  </Button>
                </Stack>
              );
            })}
          </Box>
          <Button onClick={() => hideAPExam()} variant='outlined'>
            Cancel
          </Button>
          <Button
            onClick={() => {
              saveExam(studentArrayIds);
            }}
            variant='contained'>
            Save
          </Button>
        </Stack>
      </Box>
    );
  }
}

export default AssessmentAPExam;
