import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import styled from 'styled-components';
const Card = styled.div`
  background-color: white;
  width: 70%;
  min-width: 200px;
  min-height: 100px;
  height: 10rem;
  border-radius: 12px;
  cursor: pointer;
  border: solid 1px lightgray;
`;

function ItemCard({ name, image }) {
  return (
    <Card id='main-card'>
      <Box
        id='inner-box'
        marginLeft={1}
        marginTop={4}
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box>{image}</Box>
        <Box>
          <Typography>{name}</Typography>
        </Box>
      </Box>
    </Card>
  );
}

export default ItemCard;
