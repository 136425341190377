import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import HomeLogo from '../../assets/images/winsor_red_solid_white.png';
import './Login.css';
import {
  setGoogleSSOCredentials,
  setAuthentication,
  clearGoogleSSOCredentials
} from './slice';
import { useDispatch } from 'react-redux';
import { useStore } from 'react-redux';
import { rtkApiSlice } from 'utils/rtk-api-slices';
import { toast } from 'react-toastify';
const GOOGLE = ((window as any).google = (window as any)?.google
  ? (window as any)?.google
  : {});

const Login = () => {
  const dispatch = useDispatch();
  const { data = [], currentData, isSuccess } = rtkApiSlice.useSsoQuery();
  const [Login, loginResponse] = rtkApiSlice.useLoginMutation();
  const [isGoogleSSO, setIsGoogleSSO] = React.useState(false);
  const navigate = useNavigate();
  const store = useStore<any>();
  const state = store?.getState();
  const isGoogle = GOOGLE?.accounts?.id;
  // @ts-ignore

  const handleCredentialResponse = async (response) => {
    setIsGoogleSSO(true);
    dispatch(setGoogleSSOCredentials(response.credential));
    dispatch(
      rtkApiSlice.endpoints.Sso.initiate(undefined, {
        refetchOnMountOrArgChange: true
      })
    );
  };

  useEffect(() => {
    const { isLoggedIn } = state?.authentication;
    if (isLoggedIn) {
      navigate('/home');
    } else {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (currentData?.jwt && isGoogleSSO) {
      dispatch(setAuthentication({ userAuth: currentData }));
      dispatch(clearGoogleSSOCredentials());
      toast.success('Successfully logged in!');
      navigate('/home');
    }
  }, [data, currentData, isSuccess, isGoogleSSO]);

  useEffect(() => {
    if (isGoogle) {
      (window as any).google.accounts.id.initialize({
        client_id:
          '60926153476-aqv4k6f5efe98vgdgjaqk7idsh6qjlon.apps.googleusercontent.com', // move to .env
        callback: handleCredentialResponse
      });
      (window as any).google.accounts.id.renderButton(
        document.getElementById('buttonDiv'),
        { theme: 'outline', size: 'large', width: '300px' } // customization attributes
      );
    }
  }, [isGoogle]);

  const handleSubmit = async (e: React.SyntheticEvent): Promise<any> => {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      email: { value: string };
      password: { value: string };
    };

    const response = await Login({
        email: target?.email?.value,
        password: target?.password?.value
      }),
      responseData = response?.data;

    if (responseData?.jwt) {
      dispatch(setAuthentication({ userAuth: responseData }));
      toast.success('Successfully logged in!');
      navigate('/home');
    }
  };

  const makeGoogleLoginVisibleIfLoaded = () => {
    const divElement = document.querySelector('div#alt-google-login'),
      targetDivElement = document.querySelector('div#buttonDiv'),
      hasElement = targetDivElement.hasChildNodes();

    if (hasElement) {
      // @ts-ignore
      divElement.style.display = 'block';
    }
  };

  useEffect(() => {
    makeGoogleLoginVisibleIfLoaded();
  }, [isGoogle]);

const isDev = process.env.NODE_ENV === 'development';
  return (
    <div className='center-position'>
      <Box>
        <img src={HomeLogo} alt={'Logo'} loading='lazy' width={250} />
      </Box>
      <div className='login-form-wrapper'>
        {isDev && (
          <form onSubmit={handleSubmit} className='login-form'>
            <input type='email' name='email' id='email' placeholder='Email' />
            <input
              type='password'
              name='password'
              id='password'
              placeholder='Password'
            />
            <button type='submit'>Sign in</button>
          </form>
        )}
        <div id='alt-google-login'>
          {isDev && <div id='or'>or</div>}
          <div id='buttonDiv'></div>
        </div>
      </div>
    </div>
  );
};

export default Login;