import {
  Box,
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';

import { rtkApiSlice } from 'utils/rtk-api-slices';
import './FieldTripForm.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 2, ml: 2 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function FieldTripDetailsContact({
  customRoomValidation,
  userSelfContact,
  studentCount,
  primaryContactId,
  setPrimaryContactId,
  contacts,
  verifyCellphone,
  setVerifyCellphone,
  verifyEmail,
  setVerifyEmail,
  next,
  setNext
}) {
  const isAdjusted = useMediaQuery('(min-width:768px)');
  const [submittingContact, setSubmittingContact] = useState();
  const [locationValue, setLocationValue] = useState(0);
  const [isPublic, setIsPublic] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [userEventActions, userEventActionsReponse] =
    rtkApiSlice.useUserEventActionsMutation();

  const handleChangeContact = (event, newValue) => {
    setLocationValue(newValue);
  };

  const updateContact = async () => {
    var data = {
      isPublic: isPublic,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone
    };
    setSubmittingContact(true);

    if (firstName && lastName && email && phone) {
      await userSelfContact(data);
    }
  };

  const updateContactInfo = async (id) => {
    if (id && primaryContactId) {
      var data = {
        primaryContactId: primaryContactId,
        studentCount: studentCount
      };
      await userEventActions({
        body: data,
        params: `/${id}/field-trip`
      });
    }
  };

  return next === 2 ? (
    <>
      <Box
        sx={{
          border: 'solid 1px lightgray',
          borderRadius: 4,
          marginBottom: 1,
          padding: 3
        }}
        marginBottom={3}>
        <Box>
          <Typography variant='h6'>Primary Contact</Typography>
        </Box>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={locationValue}
              onChange={handleChangeContact}
              aria-label='basic tabs example'>
              <Tab label='Select an Existing Contact' {...a11yProps(0)} />
              <Tab label='Add New Contact' {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={locationValue} index={0}>
            <Stack direction={'column'}>
              <Box marginBottom={3}>
                <Autocomplete
                  disablePortal
                  id='combo-box-demo'
                  sx={{ width: isAdjusted ? '70%' : '100%' }}
                  onChange={(event, newValue) => {
                    setPrimaryContactId(newValue?.id);
                    contacts?.find((element) => {
                      if (element.id === newValue?.id) {
                        setVerifyEmail(element.email);
                        setVerifyCellphone(element.phone);
                      }
                      return null;
                    });
                  }}
                  value={contacts?.find(
                    (option) => option.id === primaryContactId
                  )}
                  options={contacts}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.label) {
                      return option.firstName + ' ' + option.lastName;
                    }
                    return option.firstName + ' ' + option.lastName;
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>
                      {option.firstName + ' ' + option.lastName}
                    </li>
                  )}
                  renderInput={(params) => <TextField {...params} label='' />}
                />
              </Box>
              <Box
                display={isAdjusted ? 'flex' : 'column'}
                alignItems={'center'}
                marginBottom={3}>
                <Box marginRight={isAdjusted ? 10 : 0}>
                  <Box marginTop={1} marginRight={2}>
                    <Typography>Verify Cellphone</Typography>
                  </Box>
                  <TextField
                    sx={{ width: '100%' }}
                    value={verifyCellphone || ''}
                    onChange={(e) => setVerifyCellphone(e.target.value)}
                  />
                </Box>
                <Box>
                  <Box marginTop={1} marginRight={2}>
                    <Typography>Verify Email</Typography>
                  </Box>
                  <TextField
                    sx={{ width: '100%' }}
                    value={verifyEmail || ''}
                    onChange={(e) => setVerifyEmail(e.target.value)}
                  />
                </Box>
              </Box>
              <Box>
                <Button onClick={() => updateContactInfo()}>
                  Update Contact Info
                </Button>
              </Box>
            </Stack>
            {customRoomValidation ? (
              <Box>
                <div style={{ color: 'red' }}>Required!</div>
              </Box>
            ) : null}
          </TabPanel>
          <TabPanel value={locationValue} index={1}>
            <Stack direction={'column'}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                marginBottom={3}>
                <Box width={'40%'}>
                  <Box marginTop={1} marginRight={2}>
                    <Typography>First Name</Typography>
                  </Box>
                  <Box>
                    <TextField
                      value={firstName || ''}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Box>
                  {submittingContact && !firstName ? (
                    <Typography fontSize={9} color={'red'}>
                      Required
                    </Typography>
                  ) : null}
                </Box>
                <Box width={'30%'}>
                  <Box marginTop={1} marginRight={2}>
                    <Typography>Email</Typography>
                  </Box>
                  <Box>
                    <TextField
                      value={email || ''}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Box>
                  {submittingContact && !email ? (
                    <Typography fontSize={9} color={'red'}>
                      Required
                    </Typography>
                  ) : null}
                </Box>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                marginBottom={3}>
                <Box width={'40%'}>
                  <Box marginTop={1} marginRight={2}>
                    <Typography>Last Name</Typography>
                  </Box>
                  <Box>
                    <TextField
                      value={lastName || ''}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Box>
                  {submittingContact && !lastName ? (
                    <Typography fontSize={9} color={'red'}>
                      Required
                    </Typography>
                  ) : null}
                </Box>
                <Box width={'30%'}>
                  <Box marginTop={1} marginRight={2}>
                    <Typography>Phone Number</Typography>
                  </Box>
                  <Box>
                    <TextField
                      maxLength={3}
                      type='number'
                      value={phone || ''}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Box>
                  {submittingContact && !phone ? (
                    <Typography fontSize={9} color={'red'}>
                      Required
                    </Typography>
                  ) : null}
                </Box>
              </Box>
              <Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginBottom={3}>
                  <Checkbox
                    value={isPublic}
                    onChange={(e) => setIsPublic(e.target.checked)}
                  />
                  <Typography>Keep Contact Private</Typography>
                </Box>
                <Box width={'50%'} marginLeft={20}>
                  <Button onClick={() => updateContact()}>
                    Update Contact Info
                  </Button>
                </Box>
              </Box>
            </Stack>
            {customRoomValidation ? (
              <Box>
                <div style={{ color: 'red' }}>Required!</div>
              </Box>
            ) : null}
          </TabPanel>
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'flex-end'}
        marginBottom={3}>
        <Button onClick={(e) => setNext(1)}>Back</Button>
        <Button onClick={(e) => setNext(3)}>Next</Button>
      </Box>
    </>
  ) : (
    <></>
  );
}

export default FieldTripDetailsContact;