import { createSlice } from '@reduxjs/toolkit';

export interface TemplateState {
  is_template: boolean;
}

const initialState: TemplateState = {
  is_template: false
};

export const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    setTemplateStatus: (state, action) => {
      state.is_template = action.payload?.is_template;
    },
    cleanUpTemplateStatus: (state) => {
      state.is_template = initialState?.is_template;
    }
  }
});
export const { setTemplateStatus, cleanUpTemplateStatus } =
  templateSlice.actions;

export default templateSlice;
