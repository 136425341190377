import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

function NewAssessment({ courses = [], setTeachCourse, course }) {
  return (
    <div>
      <Autocomplete
        disablePortal
        id='combo-box-demo'
        onChange={(event, newValue) => {
          setTeachCourse(newValue);
        }}
        value={courses?.find(option => option === course)}
        options={courses}
        sx={{ width: 300 }}
        getOptionLabel={option => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.firstName + option.lastName) {
            return option.displayName;
          }
          return option.displayName;
        }}
        renderOption={(props, option) => (
          <li {...props}>{option.displayName}</li>
        )}
        renderInput={params => <TextField {...params} label='' />}
      />
    </div>
  );
}

export default NewAssessment;
