/* eslint-disable  */
import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';

import StudentViewDetails from './sub-views/StudentViewDetails';
import StudentLateWork from './sub-views/StudentLateWork';
import { rtkApiSlice } from 'utils/rtk-api-slices';

function StudentView() {
  const [lateWork, setLateWork] = useState([]);
  const [students, setStudents] = useState([]);
  const [student, setStudent] = useState('');
  const [showStudents, setShowStudets] = useState(false);
  // const [lateWork, setLateWork] = useState([]);
  const [showLateWork, setShowLaterWork] = useState(false);

  // getStudentsList
  const getStudentsListReponse = rtkApiSlice.useAssessmentCalendarQuery({
    url: `/assessment-calendar/teachers/student-calendars/list`
  });
  useEffect(() => {
    setStudents(getStudentsListReponse?.data);
  }, [getStudentsListReponse?.data]);

  const hideLateWork = () => {
    setShowLaterWork(false);
  };
  const showList = () => {
    setShowStudets(true);
  };

  // markAsResolved
  const [{ isAssessment, id }, setMarkAsResolvedInput] = useState({
    isAssessment: '',
    id: ''
  });
  const markAsResolvedReponse = isAssessment
    ? rtkApiSlice.useAssessmentLateWorkMarkedQuery(
        { params: `/${id}/resolve` },
        {
          refetchOnMountOrArgChange: true
        }
      )
    : rtkApiSlice.usePatternLateWorkMarkedQuery(
        { params: `/${id}/resolve` },
        {
          refetchOnMountOrArgChange: true
        }
      );
  const markAsResolved = (isAssessment, id) => {
    setMarkAsResolvedInput({ isAssessment, id });
  };

  const [getLateWorkUrl, setGetLateWorkUrl] = useState();
  const getLateWorkReponse =
    rtkApiSlice.useAssessmentCalendarQuery(getLateWorkUrl);

  useEffect(() => {
    setLateWork(getLateWorkReponse?.data);
  }, [getLateWorkReponse?.data]);
  const getLateWork = (id, isResolved) => {
    const status = isResolved === undefined ? true : isResolved,
      url = `/assessment-calendar/late-work/student/${id}?includeResolved=${status}`;
    setGetLateWorkUrl(url);
    // getStudentLateWork(id, isResolved === undefined ? true : isResolved).then(
    //   res => setLateWork(res)
    // );
  };

  if (students?.length === 0) {
    return (
      <Box sx={{ width: 300, position: 'absolute', left: '65%', top: '48%' }}>
        <Box marginLeft={4}>
          <CircularProgress />
        </Box>
        <Box>
          <Typography>Please be patient...</Typography>
        </Box>
      </Box>
    );
  } else {
    return (
      <>
        {!showLateWork ? (
          <Box
            sx={{
              border: 'solid 1px lightgray',
              borderRadius: 4,
              padding: 3,
              marginBottom: 2,
              marginTop: 2,
              position: 'relative'
            }}
            minWidth={'399px'}>
            <Typography variant='h6'>Select A Student</Typography>
            <Autocomplete
              disablePortal
              id='combo-box-demo'
              onChange={(event, newValue) => {
                if (newValue?.id) {
                  getLateWork(newValue?.id);
                  setStudent(newValue?.id);
                  showList();
                }
              }}
              value={students?.find((option) => option === student)}
              options={students}
              sx={{ width: 300 }}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                if (option?.firstName + option?.lastName) {
                  return option?.displayName;
                }
                return option?.displayName;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option?.displayName}</li>
              )}
              renderInput={(params) => <TextField {...params} label='' />}
            />
            <>
              {showStudents ? (
                <StudentLateWork
                  lateWork={lateWork}
                  markAsResolved={markAsResolved}
                  getLateWork={getLateWork}
                  studentId={student}
                />
              ) : null}
            </>
          </Box>
        ) : null}

        <>
          {showLateWork ? (
            <StudentViewDetails
              hideLateWork={hideLateWork}
              // lateWork={lateWork}
            />
          ) : null}
        </>
      </>
    );
  }
}

export default StudentView;