import { Box, Button, Stack, TextField } from '@mui/material';
import React from 'react';

function CourseSelectionOptions({
  courseSelection,
  setSelectionNote,
  setSectionDatesOptions,
  showCourseForm,
  createNewAssessment,
  myAssessments,
  actionType
}) {
  return (
    <Box
      sx={{
        padding: 3,
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 998
      }}>
      <Box
        onClick={() => showCourseForm()}
        sx={{
          padding: 3,
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 999,
          backgroundColor: '#0000005f'
        }}
      />
      <Box
        margin={4}
        sx={{
          borderRadius: 4,
          border: 'solid 1px lightgray',
          borderRadius: 2,
          padding: 3,
          paddingY: 10,
          marginX: 30,
          backgroundColor: 'white',
          position: 'relative',
          zIndex: 1000
        }}
        minWidth={'600px'}>
        <Box
          display='grid'
          justifyContent='center'
          fontWeight='bold'
          fontSize={20}
          marginBottom={5}>
          {actionType ? (
            <span> Edit Assessment</span>
          ) : (
            <span> Create Assessment</span>
          )}
        </Box>
        <Box
          display='grid'
          gridTemplateColumns='50% 50%'
          backgroundColor='brown'
          color='white'
          alignItems='center'
          fontWeight='bold'>
          {courseSelection?.map((course, index) => {
            const assessment = myAssessments?.find((ass) =>
              course?.displayName?.includes(ass?.course)
            );
            const assessmentBasedOnBlock = assessment?.assessments?.find(
              (ass) => course?.block?.includes(ass?.block)
            );
            return (
              <div key={index}>
                <Stack
                  display='grid'
                  gridTemplateColumns='30% 70%'
                  alignItems='center'
                  paddingX={1}>
                  <Box marginRight={2}>
                    <span> {`Block: ${course.block}`}</span>
                  </Box>
                  <input
                    type='date'
                    className='date'
                    defaultValue={formatDate(
                      new Date(
                        assessmentBasedOnBlock?.assessmentDateTime
                      )?.toLocaleDateString('en-GB')
                    )}
                    onChange={(e) =>
                      setSectionDatesOptions(course.sectionId, e.target.value)
                    }
                    style={{ width: '90%' }}
                  />
                </Stack>
              </div>
            );
          })}
        </Box>
        <Stack>
          <TextField
            onChange={(e) => setSelectionNote(e.target.value)}
            placeholder='Enter your notes here'
          />
          <Box
            display='grid'
            gridTemplateColumns='40% 40%'
            justifyContent='space-between'
            marginTop={5}>
            <Button
              style={{ border: '1px solid #0293DB' }}
              onClick={() => showCourseForm()}>
              Cancel
            </Button>
            <Button
              style={{ border: '1px solid #0293DB' }}
              onClick={() => createNewAssessment()}>
              Schedule Assessment
            </Button>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}

export default CourseSelectionOptions;

function formatDate(date: any) {
  const formattedDate = String(date)?.split('/')?.reverse()?.join('-');
  return formattedDate;
}