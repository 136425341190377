import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import Badge from '@mui/material/Badge';

function StudentList({
  lateWorkBySection,
  hideList,
  showEditElements,
  markAsResolved,
  createNewLateWork,
  handleStudentNotes,
  handleStudentIds,
  handleHideAddNew,
  showAddNew,
  userSchedule,
  studentIndex,
}) {
  if (!lateWorkBySection) {
    return (
      <Box sx={{ width: 300, position: 'absolute', left: '45%', top: '48%' }}>
        <Box marginLeft={4}>
          <CircularProgress />
        </Box>
        <Box>
          <Typography>Please be patient...</Typography>
        </Box>
      </Box>
    );
  } else {
    return (
      <div style={{ position: 'relative' }}>
        {lateWorkBySection ? (
          <Box sx={{ position: 'absolute', right: '0px', top: '1px' }}>
            <Button
              onClick={() => {
                hideList();
                handleHideAddNew();
              }}
            >
              Back
            </Button>
          </Box>
        ) : null}
        {!showAddNew ? (
          <div>
            {lateWorkBySection.length > 0 ? (
              <>
                {lateWorkBySection?.map((row, index) => {
                  return (
                    <Box>
                      <Stack direction={'row'}>
                        <Box>
                          <Typography>
                            <strong>Name:</strong> {row.student.displayName}
                          </Typography>
                        </Box>
                        <Box marginLeft={7}>
                          <Typography>
                            <strong>Advisor:</strong> {row.student.advisor}
                          </Typography>
                        </Box>
                      </Stack>
                      <Box
                        sx={{
                          border: 'solid 1px lightgray',
                          borderRadius: 4,
                          padding: 3,
                          marginBottom: 2,
                          marginTop: 2,
                        }}
                      >
                        {row.lateWork?.map((row2, index2) => {
                          return (
                            <Stack
                              direction={'row'}
                              marginBottom={1}
                              sx={{
                                backgroundColor: `${
                                  index2 % 2 === 1 ? 'lightgray' : null
                                }`,
                                padding: 2,
                                borderRadius: 4,
                              }}
                            >
                              <Box minWidth={'20px'}>{index2 + 1 + '.'}</Box>
                              <Stack marginBottom={4}>
                                <Box minWidth={'600px'} maxWidth={'600px'}>
                                  {row2.details}
                                </Box>
                                <Box>
                                  <Button
                                    onClick={() => {
                                      markAsResolved(
                                        row2.isAssessment,
                                        row2.id
                                      );
                                      hideList();
                                      handleHideAddNew();
                                    }}
                                  >
                                    Mark As Resolved
                                  </Button>
                                </Box>
                              </Stack>
                              <Stack marginBottom={4}>
                                <Box marginLeft={2}>{row2.markedDates}</Box>
                                <Box marginLeft={7}>
                                  {row2.isResolved ? (
                                    <Badge
                                      badgeContent={'Resolved'}
                                      color="warning"
                                    />
                                  ) : null}
                                </Box>
                              </Stack>
                            </Stack>
                          );
                        })}
                      </Box>
                    </Box>
                  );
                })}
              </>
            ) : (
              <Typography>No Late work found</Typography>
            )}
          </div>
        ) : (
          <Stack marginTop={3}>
            {userSchedule[studentIndex].students?.map((row, index) => {
              return (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onClick={(e) =>
                          handleStudentIds(row.id, e.target.checked)
                        }
                      />
                    }
                    label={`${row?.displayName}`}
                  />
                </FormGroup>
              );
            })}
            <textarea
              style={{
                fontFamily: 'monospace',
                fontSize: '15px',
                backgroundColor: '#f3f5f8',
                borderRadius: '12px',
                padding: '15px',
                border: 'solid 1px lightgray',
                maxWidth: '600px',
              }}
              onChange={(e) => handleStudentNotes(e.target.value)}
              cols={30}
              rows={5}
            />
            <Button
              onClick={() => {
                createNewLateWork();
                hideList();
                handleHideAddNew();
              }}
            >
              Create New Late Work
            </Button>
          </Stack>
        )}
      </div>
    );
  }
}

export default StudentList;
