import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { rtkApiSlice } from 'utils/rtk-api-slices';

function AssessmentDetails({ showDetailsForm, assessmentId, assessments }) {
  const [students, setStudents] = useState([]);
  const userRoles = useSelector((state) => state?.authentication?.roles);
  const assessmentNote = assessments?.find((obj, index) => {
    if (obj.id === assessmentId) {
      return obj;
    }
    return null;
  });

  const getStudentAssessmendRequestReponse =
    rtkApiSlice.useAssessmentCalendarQuery({
      url: `assessment-calendar/assessment/${assessmentId}` //XpADdUGWxBN8
    });
  useEffect(() => {
    setStudents(getStudentAssessmendRequestReponse?.data);
  }, [getStudentAssessmendRequestReponse?.data]);

  return (
    <Box
      sx={{
        padding: 3,
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 998
      }}>
      <Box
        onClick={() => showDetailsForm()}
        sx={{
          padding: 3,
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 999,
          backgroundColor: '#0000005f'
        }}
      />
      <Box
        margin={4}
        sx={{
          borderRadius: 4,
          border: 'solid 1px lightgray',
          padding: 3,
          marginX: 30,
          backgroundColor: 'white',
          position: 'relative',
          zIndex: 1000
        }}
        minWidth={'600px'}>
        <Box marginBottom={3}>
          <Typography variant='h5' textAlign={'center'}>
            {students?.displayName}
          </Typography>
        </Box>
        {assessmentNote?.description && (
          <>
            <Typography textAlign={'left'}>Notes:</Typography>
            <Box
              marginBottom={3}
              sx={{
                backgroundColor: '#f5f5f5',
                padding: '10px',
                border: 'solid 1px lightgray'
              }}>
              <Typography textAlign={'center'}>
                {assessmentNote?.description}
              </Typography>
            </Box>
          </>
        )}
        <Stack direction={'row'}>
          <Box minWidth={'300px'}>
            <Typography variant='h6'>Student</Typography>
          </Box>
          <Box width={'25%'}>
            <Typography variant='h6'>Grade</Typography>
          </Box>
          {!userRoles?.includes('Student') ? (
            <Box>
              <Typography variant='h6'>Overlaps</Typography>
            </Box>
          ) : null}
        </Stack>
        <Divider />

        {!students?.students ? (
          <Box
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex'
            }}>
            <Typography variant='h6'>Loading...</Typography>
          </Box>
        ) : (
          <Box>
            {students?.students?.map((object, index) => {
              return (
                <div key={index}>
                  <Stack direction={'row'}>
                    <Box
                      minWidth={'200px'}
                      sx={{ marginRight: 10, borderRight: '1px solid gray' }}>
                      <Typography>{object.student.displayName}</Typography>
                    </Box>
                    <Box
                      width={'200px'}
                      sx={{ marginRight: 10, borderRight: '1px solid gray' }}>
                      <Typography>{object.student.className}</Typography>
                    </Box>
                    {!userRoles?.includes('Student') ? (
                      <Box>
                        <Typography>
                          {object.conflictCount ? object.conflictCount : null}
                        </Typography>
                      </Box>
                    ) : null}
                  </Stack>
                </div>
              );
            })}
          </Box>
        )}
        <Box
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex'
          }}>
          <Button
            style={{
              alignSelf: 'center',
              backgroundColor: '#0293DB',
              color: 'white',
              paddingLeft: 50,
              paddingRight: 50,
              marginTop: 50
            }}
            onClick={() => showDetailsForm()}>
            Close
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default AssessmentDetails;