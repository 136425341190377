import React, { useEffect, useState } from 'react';
import { Button, Divider, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  currentWeekDays,
  navigationToNextWeekDays
} from '../../../helpers/Week';

function StudentWeek({
  assessments,
  latePassUse,
  undoLatePassUse,
  currentMonth,
  currentYear
  // dayNumbers,
}) {
  const [greyNext, setGreyNext] = useState(false);
  const [weeklyUnit, setWeeklyUnit] = useState(7);

  const [week, setWeek] = useState({
    Monday: '',
    Tuesday: '',
    Wednesday: '',
    Thursday: '',
    Friday: ''
  });

  const [weekDayByAttachedMonth, setWeekDayByAttachedMonth] = useState({
    Monday: '',
    Tuesday: '',
    Wednesday: '',
    Thursday: '',
    Friday: ''
  });

  const [previousweekDays, setPreviousWeekDays] = useState([]);

  const nextWeek = async () => {
    await setWeeklyUnit(weeklyUnit + 7);
    const weekDays = navigationToNextWeekDays(
      currentMonth,
      currentYear,
      weeklyUnit
    );
    setWeek(weekDays?.days);
    setWeekDayByAttachedMonth(weekDays?.daysInMonth);
    setPreviousWeekDays([...previousweekDays, weekDays]);
  };
  const previousWeek = () => {
    setGreyNext(false);

    //remove n - 1 and get n - 2 from tracked items
    previousweekDays?.length > 1 && previousweekDays.pop();
    const weekDays = previousweekDays[previousweekDays.length - 1];
    const newWeeklyUnit = weeklyUnit - 7;

    //picking from tracked items
    if (newWeeklyUnit > 7) {
      setWeeklyUnit(newWeeklyUnit);
      setWeek(weekDays?.days);
      setWeekDayByAttachedMonth(weekDays?.daysInMonth);
      return;
    }

    //back the current
    const currentWeek = currentWeekDays(currentMonth, currentYear);
    newWeeklyUnit > 6 && setWeeklyUnit(newWeeklyUnit); //stopped at initial state
    setWeek(currentWeek?.days);
    setWeekDayByAttachedMonth(currentWeek?.daysInMonth);
  };

  useEffect(() => {
    const weekDays = currentWeekDays(currentMonth, currentYear);
    setWeek(weekDays?.days);
    setWeekDayByAttachedMonth(weekDays?.daysInMonth);
  }, []);

  if (week.Monday === '') {
    <>Loading...</>;
  } else {
    return (
      <Box>
        <Stack direction={'row'}>
          <Box marginRight={4}>
            {weeklyUnit > 7 ? (
              <ArrowBackIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => previousWeek()}
              />
            ) : null}
          </Box>
          <Box>
            <Typography variant='h5'>Weekly View</Typography>
          </Box>
          <Box marginLeft={4}>
            {greyNext !== true ? (
              <ArrowForwardIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => nextWeek()}
              />
            ) : null}
          </Box>
        </Stack>
        <Stack>
          <Box display='grid' gridTemplateColumns={'repeat(2, 1fr)'} gap={6}>
            <WeeklyCalendarDayView
              weeklyData={dataManager(
                week,
                weekDayByAttachedMonth,
                assessments
              )}
              latePassUse={latePassUse}
              undoLatePassUse={undoLatePassUse}
            />
          </Box>
        </Stack>
      </Box>
    );
  }
}

export default StudentWeek;

const LateworkButton = ({ row, latePassUse, undoLatePassUse }) => {
  return (
    <Box>
      <Button
        onClick={() => {
          row.passUsed ? undoLatePassUse(row.id) : latePassUse(row.id);
        }}>
        {row.passUsed ? 'Undo Late Pass' : 'Use Late Pass'}
      </Button>
    </Box>
  );
};

const WeeklyCalendarDayView = ({
  weeklyData,
  latePassUse,
  undoLatePassUse
}) => {
  return (
    <Box display='grid' gridTemplateColumns={'repeat(2, 1fr)'} gap={6}>
      {weeklyData?.map(
        ({ name, value, month, assessments, summaries, canUseLatePass }) => (
          <Box
            sx={{
              border: 'solid 1px lightgray',
              borderRadius: 4,
              padding: 3,
              marginBottom: 2,
              marginTop: 2,
              height: 'fit-content'
            }}>
            <Stack direction={'row'} spacing={2}>
              <Typography minWidth={'80px'}>
                <strong>{name}</strong>
              </Typography>
              <Typography>
                <strong>{value}</strong>
              </Typography>
              <Typography>
                <strong>{month}</strong>
              </Typography>
            </Stack>
            <Divider />
            {summaries?.map(({ note }) => (
              <Typography marginTop={2} marginLeft={1}>
                {note}
              </Typography>
            ))}
            {assessments?.map(
              ({
                courseName,
                courseTime,
                courseDetails,
                assessmentUseLatePass
              }) => (
                <Box>
                  <Typography marginTop={2} marginLeft={1}>
                    {`${courseTime} ${courseName}`}
                  </Typography>
                  {assessmentUseLatePass?.canUseLatePass && (
                    <LateworkButton
                      row={courseDetails}
                      latePassUse={latePassUse}
                      undoLatePassUse={undoLatePassUse}
                    />
                  )}
                  {assessmentUseLatePass?.showTitle && (
                    <Typography color='gold'>
                      {assessmentUseLatePass?.title}
                    </Typography>
                  )}
                </Box>
              )
            )}
          </Box>
        )
      )}
    </Box>
  );
};

function dataManager(objectWeek, weekDayByAttachedMonths, assessmentz) {
  if (!objectWeek || !weekDayByAttachedMonths || !assessmentz) {
    return [];
  }
  const weekInArray = Object.entries(objectWeek)
    ?.reduce((prev, cur) => [...prev, { name: cur[0], value: cur[1] }], [])
    ?.map((weekDay) => {
      let assessments = assessmentz
          ?.filter((x) => {
            if (x?.type === 'assessment') {
              let givenMonth = new Date(x.start)?.toLocaleDateString('en-US', {
                  month: 'long'
                }),
                givenDay = new Date(x.start).getDate();
              let isSameMonth =
                  givenMonth === weekDayByAttachedMonths[weekDay?.name],
                isSameDay = givenDay === weekDay.value;
              return isSameMonth && isSameDay;
            }
          })
          ?.map((x) => {
            let date = new Date(x.start),
              hours = date?.getHours(),
              minutes = date?.getMinutes(),
              dayLight = date?.getHours() > 12 ? 'PM' : 'AM';

            let assessmentUseLatePass = usePassLateHandler(
              x?.start,
              x?.passAvailable,
              x?.passUsed
            );

            return {
              courseName: x?.summary,
              courseTime: `${hours}:${minutes} ${dayLight}`,
              courseDetails: x,
              passAvailable: x?.passAvailable,
              passUsed: x?.passUsed,
              assessmentUseLatePass
            };
          }),
        summaries = assessmentz
          ?.filter((x) => {
            if (x?.type === 'note') {
              let givenMonth = new Date(x.start)?.toLocaleDateString('en-US', {
                  month: 'long'
                }),
                givenDay = new Date(x.start).getDate();
              let isSameMonth =
                  givenMonth === weekDayByAttachedMonths[weekDay?.name],
                isSameDay = givenDay === weekDay.value;
              return isSameMonth && isSameDay;
            }
          })
          ?.map((x) => ({
            note: x?.summary
          }));
      return {
        ...weekDay,
        month: weekDayByAttachedMonths[weekDay?.name],
        assessments,
        summaries
      };
    });
  return weekInArray;
}

function usePassLateHandler(startDate, passAvailable, passUsed) {
  const currentDate = new Date(),
    startDateFormatted = new Date(startDate);

  if (!passAvailable || currentDate > startDateFormatted) {
    //The item has expired
    if (passUsed) {
      return { title: 'Pass Used', canUseLatePass: false, showTitle: true };
    }
    return {
      title: 'Pass Not Used but expired',
      canUseLatePass: false,
      showTitle: false
    };
  } else {
    //The item is still valid
    if (passUsed) {
      return {
        title: 'Withdraw Pass',
        canUseLatePass: false,
        showTitle: false
      };
    }
    return {
      title: 'show use late pass',
      canUseLatePass: true,
      showTitle: false
    };
  }
}