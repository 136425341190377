import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import { combineReducers } from 'redux';
import authSlice from 'screens/login/slice';
import rootSaga from 'sagas/index';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import { rtkApiSlice } from 'utils/rtk-api-slices';
import templateSlice from 'screens/event-forms/event-forms-slice';

const persistConfig = {
  key: 'root',
  storage
};
const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  authentication: authSlice.reducer,
  [rtkApiSlice.reducerPath]: rtkApiSlice.reducer,
  template: templateSlice.reducer
});

//@ts-ignore
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  //@ts-ignore
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
      .concat(rtkApiSlice.middleware)
      .concat(sagaMiddleware)
});

sagaMiddleware.run(rootSaga);
setupListeners(store.dispatch);
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;