import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import TeacherView from './TeacherView';
import { Stack } from '@mui/system';

function Views({ hideLateWorkView, hideAssessmentDetails }) {
  return (
    <Box>
      <Stack direction={'row'}>
        <Box minWidth={'600px'}>
          <Typography variant="h3">Section Information</Typography>
        </Box>
        <Box>
          <Button variant="outlined" onClick={() => hideAssessmentDetails()}>
            Show Calendar
          </Button>
        </Box>
      </Stack>
      <Box
        sx={{
          border: 'solid 1px lightgray',
          borderRadius: 4,
          padding: 3,
          marginBottom: 2,
          marginTop: 2,
        }}
      >
        <TeacherView hideLateWorkView={hideLateWorkView} />
      </Box>
    </Box>
  );
}

export default Views;
