/* eslint-disable  */
import {
  Box,
  CircularProgress,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';

import StudentWeek from './calendar-molecules/StudentWeek';
import CalendarView from './CalendarView';
import { rtkApiSlice } from 'utils/rtk-api-slices';
import { StudentCalendarTab } from './StudentCalendarTab';
import {
  formatMonthByNumberValue,
  getCurrentAndNextMonthByNumberValue,
  getNextMonthByNumberValue
} from '../../helpers/month';

function StudentCalendar() {
  const userRoles = useSelector((state) => state?.authentication?.roles);
  const [switchedTab, setSwitchedTab] = useState(0);
  const [students, setStudents] = useState([]);
  const [student, setStudent] = useState('');
  const [startingDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(startingDate.getMonth());
  const [currentYear, setCurrentYear] = useState(startingDate.getFullYear());
  const [assessments, setAssessments] = useState([]);
  const [latePasses, setLatePasses] = useState([]);
  const [dayNumbers, setDayNumbers] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [assessmentId, setAssessmentId] = useState('');
  const [loadCalendar, setLoadCalendar] = useState(false);

  const [{ month, year, studentId, nextMonth1 }, setGetAssessmentInput] =
    useState({
      month: getCurrentAndNextMonthByNumberValue()?.currentMonth,
      year: new Date().getFullYear(),
      studentId: ''
    });

  const getStudentAssessmentCalendarTeacherResponse = rtkApiSlice.useEventQuery(
    studentId
      ? {
          url: `/assessment-calendar/teachers/student-calendars/${studentId}?fromDate=${year}-${month}-01`
        }
      : {},
    { refetchOnMountOrArgChange: true, refetchOnReconnect: true }
  );

  // getStudentAssessmentCalendarStudent
  const getStudentAssessmentCalendarStudentResponse = rtkApiSlice.useEventQuery(
    {
      url: `/assessment-calendar/my-calendar?start=${year}-${month}-01&end=${year}-${nextMonth1}-01`
    },
    { refetchOnMountOrArgChange: true, refetchOnReconnect: true }
  );

  const studentAssessmentCalendarResponse = () => {
    if (userRoles?.includes('Teacher')) {
      return setAssessments(getStudentAssessmentCalendarTeacherResponse?.data);
    }
    return setAssessments(getStudentAssessmentCalendarStudentResponse?.data);
  };
  useEffect(() => {
    studentAssessmentCalendarResponse();
  }, [
    getStudentAssessmentCalendarStudentResponse?.data,
    getStudentAssessmentCalendarTeacherResponse?.data
  ]);

  //getStudentLatePasses
  const [getStudentLatePassesInput, setGetStudentLatePassesInput] = useState();
  const getStudentLatePassesReponse = rtkApiSlice.useEventQuery(
    studentId
      ? {
          url: `/assessment-calendar/teachers/student-calendars/${getStudentLatePassesInput}/passes`
        }
      : {}
  );

  useEffect(() => {
    setLatePasses(getStudentLatePassesReponse?.data);
  }, [getStudentLatePassesReponse?.data]);

  //getStudentsList
  const getStudentsListReponse = rtkApiSlice.useEventQuery({
    url: `/assessment-calendar/teachers/student-calendars/list`
  });
  const handleGetStudentsListReponse = () => {
    if (
      !userRoles?.includes('Student') &&
      getStudentsListReponse?.data?.length > 0
    ) {
      setStudents(getStudentsListReponse?.data);
    }
  };
  useEffect(() => {
    handleGetStudentsListReponse();
  }, [getStudentsListReponse?.data]);

  //latePassRequest
  const [assessmentCalendarAction, assessmentCalendarActionReponse] =
    rtkApiSlice.useAssessmentCalendarActionMutation();

  //undoLatePassRequest
  const [undoLatePassRequest, undoLatePassRequestResponse] =
    rtkApiSlice.useDeleteActionMutation();

  const handleActionReponse = async () => {
    if (
      assessmentCalendarActionReponse?.isSuccess ||
      undoLatePassRequestResponse?.isSuccess
    ) {
      getStudentAssessmentCalendarTeacherResponse?.refetch();
      getStudentAssessmentCalendarStudentResponse?.refetch();
    }
  };
  useEffect(() => {
    handleActionReponse();
  }, [
    assessmentCalendarActionReponse?.isSuccess,
    undoLatePassRequestResponse?.isSuccess
  ]);

  // getDayNumbersRequest
  const [
    { getDayNumbersYear, getDayNumbersMonth },
    setGetDayNumbersRequestInput
  ] = useState({
    getDayNumbersMonth: getCurrentAndNextMonthByNumberValue()?.currentMonth,
    getDayNumbersYear: new Date().getFullYear()
  });

  const getDayNumbersRequestReponse = rtkApiSlice.useEventQuery(
    studentId
      ? {
          url: `/schedule/cycle-day?start=${getDayNumbersYear}-${getDayNumbersMonth}-01`
        }
      : {}
  );

  useEffect(() => {
    setDayNumbers(getDayNumbersRequestReponse?.data);
  }, [getDayNumbersRequestReponse?.data]);

  const nextMonth = () => {
    setLoadCalendar(true);
    setTimeout(() => {
      setLoadCalendar(false);
    }, 1000);
    if (currentMonth < 11) {
      setCurrentMonth((prev) => prev + 1);
    } else {
      setCurrentMonth(0);
      setCurrentYear((prev) => prev + 1);
    }
    getAssessments(currentMonth + 1, currentYear);
  };
  const prevMonth = () => {
    setLoadCalendar(true);
    setTimeout(() => {
      setLoadCalendar(false);
    }, 1000);
    if (currentMonth > 0) {
      setCurrentMonth((prev) => prev - 1);
    } else {
      setCurrentMonth(11);
      setCurrentYear((prev) => prev - 1);
    }
    getAssessments(currentMonth + 1, currentYear);
    getDayNumbers(currentMonth + 1, currentYear);
  };

  const getAssessments = (month, year, studentId) => {
    setGetAssessmentInput({
      month: formatMonthByNumberValue(month),
      year,
      studentId,
      nextMonth1: getNextMonthByNumberValue(month)
    });
  };

  const getDayNumbers = (month, year) => {
    setGetDayNumbersRequestInput({
      getDayNumbersMonth: month,
      getDayNumbersYear: year
    });
  };

  const getStudentLateInfo = (id) => {
    setGetStudentLatePassesInput(id);
  };

  const showDetailsForm = () => {
    setShowDetails(!showDetails);
  };

  const latePassUse = (itemId) => {
    if (userRoles?.includes('Teacher')) {
      return assessmentCalendarAction({
        params: `/teachers/student-calendars/${student}/passes/${itemId}`
      });
    }
    return assessmentCalendarAction({
      params: `/students/passes/${itemId}`
    });
  };

  const undoLatePassUse = async (itemId) => {
    if (userRoles?.includes('Teacher')) {
      return undoLatePassRequest({
        url: `/assessment-calendar/teachers/student-calendars/${student}/passes/${itemId}`
      });
    }
    return undoLatePassRequest({
      url: `/assessment-calendar/students/passes/${itemId}`
    });
  };

  useEffect(() => {
    getAssessments(currentMonth + 1, currentYear, student);
    getDayNumbers(currentMonth + 1, currentYear);
  }, [currentMonth, currentYear]);

  return (
    <Box>
      <StudentCalendarTab onToggle={setSwitchedTab} />
      <Stack direction={'row'} spacing={2}>
        {userRoles?.includes('Student') ? null : (
          <>
            <Typography variant='h6' marginTop={1}>
              Choose a Student to View their assessments:
            </Typography>
            <Autocomplete
              disablePortal
              id='combo-box-demo'
              onChange={(event, newValue) => {
                if (newValue?.id) {
                  setStudent(newValue?.id);
                  getStudentLateInfo(newValue?.id);
                  getAssessments(currentMonth + 1, currentYear, newValue?.id);
                }
              }}
              value={students?.find((option) => option === student)}
              options={students}
              sx={{ width: 300 }}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                if (option?.firstName + option?.lastName) {
                  return option?.displayName;
                }
                return option?.displayName;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option?.displayName}</li>
              )}
              renderInput={(params) => <TextField {...params} label='' />}
            />
          </>
        )}
      </Stack>
      <Stack>
        <Box width={'100%'}>
          <Box
            marginTop={5}
            sx={{ display: switchedTab === 1 ? 'none' : 'block' }}>
            <>
              {loadCalendar ? (
                <Box
                  sx={{
                    width: 300,
                    position: 'absolute',
                    left: '35%',
                    top: '38%'
                  }}>
                  <Box marginLeft={4}>
                    <CircularProgress />
                  </Box>
                  <Box>
                    <Typography>Please be patient...</Typography>
                  </Box>
                </Box>
              ) : (
                <CalendarView
                  currentMonth={currentMonth}
                  currentYear={currentYear}
                  prevMonth={prevMonth}
                  nextMonth={nextMonth}
                  setAssessmentId={setAssessmentId}
                  assessments={assessments}
                  dayNumbers={dayNumbers}
                  showDetailsForm={showDetailsForm}
                  showDetails={showDetails}
                  assessmentId={assessmentId}
                />
              )}
            </>
          </Box>
        </Box>
        <Box
          width={'fit-content'}
          sx={{
            display: switchedTab === 0 ? 'none' : 'block',
            marginBottom: 10
          }}>
          <Box marginTop={5}>
            <StudentWeek
              assessments={assessments}
              latePassUse={latePassUse}
              undoLatePassUse={undoLatePassUse}
              currentMonth={currentMonth}
              currentYear={currentYear}
              dayNumbers={dayNumbers}
            />
          </Box>
        </Box>
      </Stack>
      {!loadCalendar ? (
        <Stack>
          <Box>
            <Typography variant='h4'>Late Passes For Assessments</Typography>
          </Box>
          {latePasses?.length > 0 ? (
            <Box
              sx={{
                border: 'solid 1px lightgray',
                borderRadius: 4,
                padding: 3,
                marginBottom: 2,
                marginTop: 2
              }}
              width={'fit-content'}>
              <Stack direction={'row'} marginBottom={2}>
                <Box minWidth={'400px'} maxWidth={'400px'}>
                  <Typography variant='h6'>Course Name</Typography>
                </Box>
                <Box minWidth={'500px'} maxWidth={'500px'} marginRight={2}>
                  <Typography variant='h6'>Note</Typography>
                </Box>
                <Box>
                  <Typography variant='h6'>Assessment Date</Typography>
                </Box>
              </Stack>
              {latePasses &&
                latePasses?.map((row, index) => (
                  <Stack key={index} direction={'row'}>
                    <Box width={'28%'} minWidth={'400px'} maxWidth={'400px'}>
                      {row.assessment.courseName}
                    </Box>
                    <Box
                      width={'40%'}
                      minWidth={'500px'}
                      maxWidth={'500px'}
                      marginRight={2}>
                      {row.assessment.assessmentNote}
                    </Box>
                    <Box>
                      {new Date(
                        row.assessment.assessmentDateTime
                      ).toDateString()}
                    </Box>
                  </Stack>
                ))}
            </Box>
          ) : (
            <Box
              sx={{
                border: 'solid 1px lightgray',
                borderRadius: 4,
                padding: 3,
                marginBottom: 2,
                marginTop: 2
              }}
              width={'fit-content'}>
              <Typography>No Late Passes Submitted</Typography>
            </Box>
          )}
        </Stack>
      ) : null}
    </Box>
  );
}

export default StudentCalendar;