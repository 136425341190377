import { Grid } from '@mui/material';
import React from 'react';
import Typography from '@mui/material/Typography';

function MiddleHeader({ title, variant, color}) {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Typography variant={variant} gutterBottom color={color}>
        {title}
      </Typography>
    </Grid>
  );
}

export default MiddleHeader;