import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
  userAuth: {
    userId: string | null;
    jwt: string | null;
    refreshToken: string | null;
    expires: string | null;
  };
  googleSSOCredentials?: string | null;
  isLoggedIn?: boolean;
  roles?: string[];
  userProfile?: any;
}

const initialState: AuthState = {
  userAuth: {
    userId: null,
    jwt: null,
    refreshToken: null,
    expires: null
  },
  googleSSOCredentials: null,
  isLoggedIn: false,
  roles: [],
  userProfile: null
};
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthentication: (state, action) => {
      state.userAuth = action.payload?.userAuth;
      state.isLoggedIn = true;
    },
    setGoogleSSOCredentials: (state, action) => {
      state.googleSSOCredentials = action.payload;
    },
    setUserRoles: (state, action) => {
      state.roles = action.payload;
    },
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },
    clearGoogleSSOCredentials: (state) => {
      state.googleSSOCredentials = null;
    },
    cleanUpAuthentication: (state) => {
      state.userAuth = initialState?.userAuth;
      state.googleSSOCredentials = initialState?.googleSSOCredentials;
      state.isLoggedIn = initialState?.isLoggedIn;
      state.roles = initialState?.roles;
      state.userProfile = initialState?.userProfile;
    }
  }
});
export const {
  setAuthentication,
  setGoogleSSOCredentials,
  clearGoogleSSOCredentials,
  cleanUpAuthentication,
  setUserRoles
} = authSlice.actions;

export default authSlice;
