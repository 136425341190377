import { Grid } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Provider } from 'react-redux';
import MaterialUIContainer from '@mui/material/Container';
import { store } from './store';
import router from './router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-tooltip/dist/react-tooltip.css';

const App = () => {
  return (
    <Provider store={store}>
      <MaterialUIContainer>
        <div style={{ backgroundColor: 'white' }}>
          <Container maxWidth='xl'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <RouterProvider router={router} />
            </LocalizationProvider>
          </Container>
        </div>
        <ToastContainer />
      </MaterialUIContainer>
    </Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);