import { Box, Button, Typography, Stack } from '@mui/material';
import React from 'react';

function AssessmentSelection({ showCalendar, selectionDetails }) {
  return (
    <Box
      sx={{
        border: 'solid 1px lightgray',
        borderRadius: 4,
        padding: 3,
        marginBottom: 2,
        marginTop: 2,
        height: 'fit-content',
      }}
    >
      <Box marginBottom={2}>
        <Typography variant="h6">{selectionDetails?.displayName}</Typography>
      </Box>
      <Stack direction={'row'} marginBottom={1}>
        <Box>
          <Typography>
            <strong>Block:</strong>{' '}
          </Typography>
        </Box>
        <Box marginLeft={3}>
          <Typography>{selectionDetails?.block}</Typography>
        </Box>
      </Stack>
      <Stack direction={'row'} marginBottom={1}>
        <Box>
          <Typography>
            <strong>Assessment Date:</strong>{' '}
          </Typography>
        </Box>
        <Box marginLeft={3}>
          <Typography>
            {new Date(selectionDetails?.assessmentDateTime).toDateString()}
          </Typography>
        </Box>
      </Stack>
      <Stack direction={'row'} marginBottom={1}>
        <Box>
          <Typography>
            <strong>Teacher:</strong>{' '}
          </Typography>
        </Box>
        <Box marginLeft={3}>
          <Typography>
            {selectionDetails?.teacher.firstName +
              ' ' +
              selectionDetails?.teacher.lastName}
          </Typography>
        </Box>
      </Stack>
      <Stack direction={'column'} marginBottom={1}>
        <Box>
          <Typography>
            <strong>Students:</strong>{' '}
          </Typography>
        </Box>
        <Box
          marginLeft={3}
          sx={{
            border: 'solid 1px lightgray',
            borderRadius: 4,
            padding: 3,
            marginBottom: 2,
            marginTop: 2,
            width: 'fit-content',
          }}
        >
          <Typography>
            <Stack direction={'row'} marginBottom={2}>
              <Box width={'23%'}>
                <Typography variant="h6">Name </Typography>
              </Box>
              <Box width={'16%'}>
                <Typography variant="h6">Class </Typography>
              </Box>
              <Box width={'20%'}>
                <Typography variant="h6">Conflicts </Typography>
              </Box>
              <Box>
                <Typography variant="h6">Email </Typography>
              </Box>
            </Stack>
            {selectionDetails?.students?.map((row, index) => (
              <Stack direction={'row'}>
                <Box minWidth={'150px'} width={'50%'}>
                  <Typography>{row.student.displayName}</Typography>
                </Box>
                <Box minWidth={'100px'} width={'30%'}>
                  <Typography>{row.student.className}</Typography>
                </Box>
                <Box minWidth={'90px'} width={'10%'}>
                  <Typography>{row.conflictCount}</Typography>
                </Box>
                <Box minWidth={'300px'}>
                  <Typography>{row.student.email}</Typography>
                </Box>
              </Stack>
            ))}
          </Typography>
        </Box>
      </Stack>
      <Button variant="outlined" onClick={() => showCalendar()}>
        Show Calendar
      </Button>
    </Box>
  );
}

export default AssessmentSelection;
