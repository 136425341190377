import { Box, Button } from '@mui/material';
import React from 'react';

function StudentViewDetails({ hideLateWork, lateWork }) {
  return (
    <Box
      sx={{
        border: 'solid 1px lightgray',
        borderRadius: 4,
        padding: 3,
        marginBottom: 2,
        marginTop: 2,
        position: 'relative',
      }}
    >
      <Box>
        <Button onClick={() => hideLateWork()}>Back</Button>
      </Box>
      <Box>
        {lateWork?.map((row, index) => {
          return <Box>{row.student.id}</Box>;
        })}
      </Box>
    </Box>
  );
}

export default StudentViewDetails;
