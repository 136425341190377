import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import Badge from '@mui/material/Badge';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { rtkApiSlice } from 'utils/rtk-api-slices';

function eventListManager(orginalList, searchList) {
  if (searchList?.length > 0) {
    return searchList;
  } else if (orginalList?.length > 0) {
    return orginalList;
  } else {
    return [];
  }
}

function EventAdmin() {
  const isAdjusted = useMediaQuery('(min-width:768px)');
  const [events, setEvents] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [showNotes, setShowNotes] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const handleSearchEvents = value => {
    setSearchTerm(value);
    if (value?.length > 0) {
      const result =
        events?.filter(event =>
          Object.values(event)
            ?.join(' ')
            ?.toLowerCase()
            ?.includes(value?.toLowerCase())
        ) ?? [];
      return setSearchResult(result);
    }
    return setSearchResult([]);
  };

  const eventList = eventListManager(events, searchResult);
  const navigate = useNavigate();

  const pendingEventRequestReponse = rtkApiSlice.useAdminEventPendingQuery();
  const eventLocationRequestReponse = rtkApiSlice.useEventLocationQuery();
  const employeesReponse = rtkApiSlice.useEmployeesQuery();
  const handleMountedList = () => {
    setEvents(pendingEventRequestReponse.data);
    setRooms(eventLocationRequestReponse.data);
    setEmployees(employeesReponse.data);
  };
  useEffect(() => {
    handleMountedList();
  }, [
    pendingEventRequestReponse?.isSuccess,
    eventLocationRequestReponse?.isSuccess,
    employeesReponse?.isSuccess
  ]);

  //approveEventRequest
  const [approveEventId, setApproveEventId] = useState('');
  const approveEventRequestReponse = rtkApiSlice.useEventQuery(
    {
      url: `/events/${approveEventId}/approve`
    },
    { refetchOnMountOrArgChange: true }
  );
  const handleApproveEvent = id => {
    setApproveEventId(id);
  };

  // deleteEventRequest
  const [deleteEventId, setDeleteEventId] = useState('');
  const deleteEventRequestReponse = rtkApiSlice.useEventQuery(
    {
      url: `/events/${deleteEventId}/decline`
    },
    { refetchOnMountOrArgChange: true }
  );
  const handleDeleteEventRequest = id => {
    setDeleteEventId(id);
  };

  const handleEventResponse = async id => {
    if (
      approveEventRequestReponse?.isSuccess ||
      deleteEventRequestReponse?.isSuccess
    ) {
      await pendingEventRequestReponse?.refetch();
      window.location.reload();
    }
  };
  useEffect(() => {
    handleEventResponse();
  }, [
    approveEventRequestReponse?.isSuccess,
    deleteEventRequestReponse?.isSuccess
  ]);

  return (
    <Grid
      sx={{
        border: 'solid 1px lightgray',
        borderRadius: 1,
        padding: 1,
        backgroundColor: 'white',
        width: '90vw',
        marginTop: 4
      }}>
      <Box>
        <TextField
          margin='normal'
          required
          id='event-name'
          style={{
            width: isAdjusted ? '50%' : '100%',
            borderColor: 'lightgray',
            fontFamily: `'Times New Roman', Times, serif`,
            backgroundColor: '#f3f5f8'
          }}
          autoFocus
          placeholder='Search events'
          size='small'
          value={searchTerm}
          onChange={e => handleSearchEvents(e.target.value)}
        />
      </Box>
      <Box>
        <Box>
          <Stack direction={'row'} marginBottom={1}>
            <Box width={'20%'}></Box>
            <Box width={'60%'}>
              <Typography variant='h6'>Event Name</Typography>
            </Box>
            <Box width={'25%'}>
              <Typography variant='h6'>Location</Typography>
            </Box>
            <Box width={'40%'}>
              <Typography variant='h6'>Date and Time</Typography>
            </Box>
            <Box width={'25%'}>
              <Typography variant='h6'>Leader</Typography>
            </Box>
            <Box width={'30%'}>
              <Typography variant='h6'>Controls</Typography>
            </Box>
          </Stack>
          <Divider variant='light' />
        </Box>
      </Box>
      {showNotes ? (
        <Box
          sx={{
            position: 'absolute',
            left: '33%',
            top: '50%',
            zIndex: 100,
            backgroundColor: 'lightgray',
            borderRadius: 4,
            padding: 4
          }}>
          <Box>
            {' '}
            <textarea
              style={{
                border: 'solid 1px lightgray',
                borderRadius: '12px',
                backgroundColor: '#f3f5f8'
              }}
              cols={50}
              rows={7}
            />
          </Box>
          <Box margin={2}>
            {' '}
            <Button variant='outlined' onClick={() => setShowNotes(!showNotes)}>
              Send Note
            </Button>
            <Button variant='outlined' onClick={() => setShowNotes(false)}>
              Cancel
            </Button>
          </Box>
        </Box>
      ) : null}
      {eventList.map((i, index) => (
        <Stack direction={'row'} margin={1} key={index}>
          <Box width={'5%'} marginLeft={2} marginBottom={4}>
            {i.status?.toLowerCase()?.includes('approved') ? (
              <Box>
                <Badge badgeContent={i.status} color='warning' />
              </Box>
            ) : null}
          </Box>
          <Box width={'15%'}>
            <Typography>{i.type}</Typography>
          </Box>
          <Box width={'40%'}>
            <Link
              to={`/${
                i.type === 'Default'
                  ? 'generic-event-form'
                  : i.type === 'Field Trip'
                  ? 'field-trip-form'
                  : i.type === 'Rental'
                  ? 'rental-form'
                  : i.type === 'Virtual Event'
                  ? 'virtual-only-form'
                  : null
              }/${events[index].id}`}
              style={{ textDecoration: 'none' }}>
              <Typography color={'#1976d2'}>{i.summary}</Typography>
            </Link>
          </Box>
          <Box width={'30%'}>
            <Typography>
              {i.selectedLocations.map(
                (item, index) =>
                  rooms?.find(option => option.id === item)?.label +
                  (index > 0 ? ';' : '')
              )}
            </Typography>
          </Box>
          <Box width={'23%'}>
            <Typography>
              {new Date(i.start).toLocaleString().replace(/(.*)\D\d+/, '$1')}
            </Typography>
          </Box>
          <Box width={'25%'}>
            <Typography>
              {`${
                employees?.find(option => option.id === i.leaderId)?.firstName
              } ${
                employees?.find(option => option.id === i.leaderId)?.lastName
              } `}
            </Typography>
          </Box>
          <Box width={'50%'}>
            <Button
              sx={{ fontSize: '11px', color: 'black' }}
              onClick={() => {
                handleApproveEvent(i.id);
              }}>
              Approve
            </Button>
            <Button
              color='error'
              sx={{ fontSize: '11px' }}
              onClick={() => setShowNotes(!showNotes)}>
              Add Notes
            </Button>
            <Button
              color='error'
              sx={{ fontSize: '11px' }}
              onClick={() => {
                var location;
                switch (i.type) {
                  case 'Default':
                    location = '/generic-event-form/';
                    break;
                  case 'Field Trip':
                    location = '/field-trip-form/';
                    break;
                  case 'Rental':
                    location = '/rental-form/';
                    break;
                  case 'Virtual Event':
                    location = '/virtual-only-form/';
                    break;

                  default:
                    break;
                }
                console.log(location);

                navigate(location + `${events[index].id}`);
              }}>
              Edit
            </Button>
            {/* <Button color="error" sx={{ fontSize: '11px' }}>
              Print
            </Button>*/}
            <Button
              color='error'
              sx={{ fontSize: '11px' }}
              onClick={() => handleDeleteEventRequest(i.id)}>
              Delete
            </Button>
          </Box>
        </Stack>
      ))}{' '}
    </Grid>
  );
}

export default EventAdmin;
