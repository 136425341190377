import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ItemCard from 'components/cards/ItemCard';
import MiddleHeader from 'components/headers/MiddleHeader';
import { Link, useNavigate } from 'react-router-dom';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import CommuteTwoToneIcon from '@mui/icons-material/CommuteTwoTone';
import CarRentalTwoToneIcon from '@mui/icons-material/CarRentalTwoTone';
import VpnLockTwoToneIcon from '@mui/icons-material/VpnLockTwoTone';

import '../home/Home.css';
import { toast } from 'react-toastify';
import { getUserRoles } from 'utils/permissions';
import { rtkApiSlice } from 'utils/rtk-api-slices';
import { useDispatch } from 'react-redux';

import { setTemplateStatus } from './event-forms-slice';

const handlePrintToPDF = (eventId, dispatch, toast) => {
  dispatch(rtkApiSlice.endpoints.PDFEvents.initiate(`${eventId}/pdf`));
  toast('PDF Print request done');
};

const handleDeleteEvent = (id, dispatch) => {
  dispatch(rtkApiSlice.endpoints.DeleteEvents.initiate(id));
};

const EventForms = () => {
  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);
  const [startingDate] = useState(new Date());
  const [showPastDates, setShowPastDates] = useState(false);
  const [month] = useState(startingDate.getMonth());
  const [year] = useState(startingDate.getFullYear());
  const { data = [] } = rtkApiSlice.useRolesQuery();
  const userRoles = getUserRoles(data);
  const { data: createdEvents } = rtkApiSlice.useCreatedEventsQuery();
  const queryParams = `${year}-${month}-01&end=${year}-${month + 2}-01`;
  const pastEvents = rtkApiSlice.useShowPastEventsQuery({
    params: queryParams
  });


  const navigate = useNavigate();

  const handleTemplateStaus = (is_template) =>
    dispatch(setTemplateStatus({ is_template }));

  const handleEventList = () => {
    if (createdEvents && !showPastDates) {
      setEvents(createdEvents);
    }
  }
  useEffect(() => {
    handleEventList();
  }, [createdEvents, showPastDates]);
  
  const handlePastEventList = () => {
    const { currentData } = pastEvents;
    if (showPastDates) {
      setEvents(currentData);
    }
  }
  useEffect(() => {
    handlePastEventList();
  }, [pastEvents, showPastDates]);
  

  return (
    <div style={{ minWidth: '900px' }}>
      <MiddleHeader title='Start a new Event Form' variant='h3' />
      <Grid container spacing={2} marginBottom={3}>
        <Grid item xs={3}>
          <Link
            to={'/generic-event-form'}
            style={{ textDecoration: 'none', color: 'black' }}>
            <ItemCard
              name='Generic Event Form'
              image={<ListAltTwoToneIcon color='error' sx={{ fontSize: 60 }} />}
              titleMargin={5}
              onClick={() => handleTemplateStaus(false)}
            />
          </Link>
        </Grid>
        <Grid item xs={3}>
          <Link
            to={'/field-trip-form'}
            style={{ textDecoration: 'none', color: 'black' }}
            onClick={() => handleTemplateStaus(false)}>
            <ItemCard
              name='Field Trip Form'
              image={<CommuteTwoToneIcon color='error' sx={{ fontSize: 60 }} />}
              titleMargin={6}
            />
          </Link>
        </Grid>
        {userRoles.isWinsorAdminAssistant || userRoles.isEventsAdmin ? (
          <Grid item xs={3}>
            <Link
              to={'/rental-form'}
              style={{ textDecoration: 'none', color: 'black' }}
              onClick={() => handleTemplateStaus(false)}>
              <ItemCard
                name='Rental Form'
                image={
                  <CarRentalTwoToneIcon color='error' sx={{ fontSize: 60 }} />
                }
                titleMargin={7}
              />
            </Link>
          </Grid>
        ) : null}
        <Grid item xs={3} marginBottom={5}>
          <Link
            to={'/virtual-only-form'}
            style={{ textDecoration: 'none', color: 'black' }}
            onClick={() => handleTemplateStaus(false)}>
            <ItemCard
              name='Virtual-Only Form'
              image={<VpnLockTwoToneIcon color='error' sx={{ fontSize: 60 }} />}
              titleMargin={5}
            />
          </Link>
        </Grid>
        <MiddleHeader title='My Events' variant={'h4'} />
        <Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  value={showPastDates}
                  onChange={e => setShowPastDates(e.target.checked)}
                />
              }
              label='Show Past Events'
            />
          </FormGroup>
        </Box>
        <Grid
          width={'93%'}
          container
          direction='row'
          justifyContent='center'
          alignItems='center'>
          <div style={{ width: '80%', minWidth: '1100px' }}>
            <Grid>
              <Box
                sx={{
                  border: 'solid 1px lightgray',
                  borderRadius: 1,
                  padding: 2,
                  backgroundColor: 'white'
                }}>
                <Stack direction={'row'} marginBottom={1}>
                  <Box width={'15%'}>
                    <Typography variant='h6'>Status</Typography>
                  </Box>
                  <Box width={'15%'}>
                    <Typography variant='h6'>Event Type</Typography>
                  </Box>
                  <Box width={'40%'}>
                    <Typography variant='h6'>Summary</Typography>
                  </Box>
                  <Box width={'25%'}>
                    <Typography variant='h6'>Start Date and Time</Typography>
                  </Box>
                  <Box width={'30%'}>
                    <Typography variant='h6'>Action</Typography>
                  </Box>
                </Stack>
                <Divider />
                {events?.length > 0 ? null : (
                  <Grid
                    container
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                    marginTop={2}>
                    <Typography>No Events Found</Typography>
                  </Grid>
                )}{' '}
                {events &&
                  events.map((i, index) => {
                    return (
                      <Stack direction={'row'} margin={1} key={index}>
                        <Box width={'15%'}>
                          <Typography>{i.status}</Typography>
                        </Box>
                        <Box width={'15%'}>
                          <Typography>{i.type}</Typography>
                        </Box>
                        <Box width={'40%'}>
                          <Link
                            to={`/${
                              i.type === 'Default'
                                ? 'generic-event-form'
                                : i.type === 'Field Trip'
                                ? 'field-trip-form'
                                : i.type === 'Rental'
                                ? 'rental-form'
                                : i.type === 'Virtual Event'
                                ? 'virtual-only-form'
                                : null
                            }/${events[index].id}`}
                            onClick={() => handleTemplateStaus(false)}
                            style={{ textDecoration: 'none' }}>
                            <Typography color={'#1976d2'}>
                              {i.summary}
                            </Typography>
                          </Link>
                        </Box>
                        <Box width={'25%'}>
                          <Typography>
                            {new Date(i.start)
                              .toLocaleString()
                              .replace(/(.*)\D\d+/, '$1')}
                          </Typography>
                        </Box>
                        <Box width={'30%'}>
                          <Button
                            color='error'
                            onClick={() => handleDeleteEvent(i.id, dispatch)}>
                            Delete
                          </Button>
                          <Button
                            onClick={() => {
                              handleTemplateStaus(true);
                              navigate(
                                `${`/${
                                  i.type === 'Default'
                                    ? 'generic-event-form'
                                    : i.type === 'Field Trip'
                                    ? 'field-trip-form'
                                    : i.type === 'Rental'
                                    ? 'rental-form'
                                    : i.type === 'Virtual Event'
                                    ? 'virtual-only-form'
                                    : null
                                }/${events[index].id}`}`
                              );
                            }}>
                            Template
                          </Button>
                          <Button
                            onClick={() =>
                              handlePrintToPDF(
                                events[index].id,
                                dispatch,
                                toast
                              )
                            }>
                            Print
                          </Button>
                        </Box>
                      </Stack>
                    );
                  })}
                <Box marginLeft={1} marginTop={2}></Box>
              </Box>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default EventForms;
