import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Home from 'screens/home/Home';
import { Grid } from '@mui/material';
import FieldTripForm from 'screens/event-forms/field-trip-form/FieldTripForm';
import RentalForm from 'screens/event-forms/rental-form/RentalForm';
import VirtualOnlyForm from 'screens/event-forms/virtual-only-form/VirtualOnlyForm';
import EventAdmin from 'screens/events-admin/EventAdmin';
import Master from 'screens/event-forms/master-event-list/MasterEvent';
import AssessmentCalendar from 'screens/assessment-calendar/AssessmentCalendar';
import StudentCalendar from 'screens/assessment-calendar/StudentCalendar';
import Login from 'screens/login/Login';
import EventForms from 'screens/event-forms/EventForms';
import GenericEventForm from 'screens/event-forms/generic-event-form/GenericEventForm';
import Navbar from 'components/navbar/Navbar';
import MainNavigationBar from 'components/navbar/MainNavigationBar';

const WrappedRoute = ({ children }) => {
  return (
    <div>
      <MainNavigationBar />
      {children}
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />
  },
  {
    path: '/home',
    element: (
      <WrappedRoute>
        <Home />
      </WrappedRoute>
    )
  },
  {
    path: '/event-forms',
    element: (
      <WrappedRoute>
        <EventForms />
      </WrappedRoute>
    )
  },
  {
    path: '/generic-event-form',
    element: (
      <WrappedRoute>
        <GenericEventForm />
      </WrappedRoute>
    )
  },
  {
    path: '/generic-event-form/:eventId',
    element: (
      <WrappedRoute>
        <GenericEventForm />
      </WrappedRoute>
    )
  },
  {
    path: '/field-trip-form/',
    element: (
      <WrappedRoute>
        <FieldTripForm />
      </WrappedRoute>
    )
  },
  {
    path: '/field-trip-form/:eventId',
    element: (
      <WrappedRoute>
        <FieldTripForm />
      </WrappedRoute>
    )
  },
  {
    path: '/rental-form/',
    element: (
      <WrappedRoute>
        <RentalForm />
      </WrappedRoute>
    )
  },
  {
    path: '/rental-form/:eventId',
    element: (
      <WrappedRoute>
        <RentalForm />
      </WrappedRoute>
    )
  },
  {
    path: '/virtual-only-form/',
    element: (
      <WrappedRoute>
        <VirtualOnlyForm />
      </WrappedRoute>
    )
  },
  {
    path: '/virtual-only-form/:eventId',
    element: (
      <WrappedRoute>
        <VirtualOnlyForm />
      </WrappedRoute>
    )
  },
  {
    path: '/events-admin',
    element: (
      <WrappedRoute>
        <EventAdmin />
      </WrappedRoute>
    )
  },
  {
    path: '/master-events',
    element: (
      <WrappedRoute>
        <Master />
      </WrappedRoute>
    )
  },
  {
    path: '/assessment-calendar',
    element: (
      <WrappedRoute>
        <AssessmentCalendar />
      </WrappedRoute>
    )
  },
  {
    path: '/student-calendar',
    element: (
      <WrappedRoute>
        <StudentCalendar />
      </WrappedRoute>
    )
  }
]);

export default router;
