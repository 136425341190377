export const ENDPOINT_URL = 'https://forms-dev.winsor.edu/api';
export const API_ROUTES = [
  {
    name: 'Login',
    url: '/auth',
    method: 'POST'
  },
  {
    name: 'Auth',
    url: '/auth/',
    method: 'GET'
  },
  {
    name: 'Sso',
    url: '/auth/sso',
    method: 'GET'
  },
  {
    name: 'Roles',
    url: '/users/self/roles',
    method: 'GET'
  },
  {
    name: 'Refresh',
    url: '/auth/renew?refreshToken=',
    method: 'GET'
  },
  {
    name: 'Events',
    url: '/events/',
    method: 'GET'
  },
  {
    name: 'PDFEvents',
    url: '/events/',
    method: 'GET'
  },
  {
    name: 'ShowPastEvents',
    url: '/events?start=',
    method: 'GET'
  },

  {
    name: 'DeleteEvents',
    url: '/events',
    method: 'DELETE'
  },
  {
    name: 'CreatedEvents',
    url: '/events/created',
    method: 'GET'
  },
  {
    name: 'UserSelf',
    url: '/users/self',
    method: 'GET'
  },
  {
    name: 'UserEventActions',
    url: '/events',
    method: 'POST'
  },
  {
    name: 'UserSelfContact',
    url: '/users/self/contacts',
    method: 'POST'
  },
  {
    name: 'UserSelfContacts',
    url: '/users/self/contacts',
    method: 'GET'
  },
  {
    name: 'EventUpdate',
    url: '/events',
    method: 'PATCH'
  },
  {
    name: 'Employees',
    url: '/users/employees',
    method: 'GET'
  },
  {
    name: 'CustomLocation',
    url: '/events/location/custom',
    method: 'GET'
  },
  {
    name: 'Vehicles',
    url: '/events/transportation/vehicles',
    method: 'GET'
  },
  {
    name: 'Event',
    url: '',
    method: 'CUSTOM_GET'
  },
  {
    name: 'Location',
    url: '/location',
    method: 'GET'
  },
  {
    name: 'CateringMenu',
    url: '/catering/menu/field-trip',
    method: 'GET'
  },
  {
    name: 'CustomLocation',
    url: '/location/custom'
  },
  {
    name: 'TransportationVehicles',
    url: '/transportation/vehicles',
    method: 'GET'
  },
  {
    name: 'FieldTripLunch',
    url: '/field-trip/lunch',
    method: 'GET'
  },
  {
    name: 'FieldTripTransportation',
    url: '/field-trip/transportation',
    method: 'GET'
  },
  {
    name: 'FieldTripTransportationBuses',
    url: '/field-trip/transportation/busses',
    method: 'GET'
  },
  {
    name: 'FieldTripTransportationVehicles',
    url: '/field-trip/transportation/vehicles',
    method: 'GET'
  },
  {
    name: 'FieldTrip',
    url: '/field-trip',
    method: 'GET'
  },
  {
    name: 'DeleteAction',
    url: '',
    method: 'CUSTOM_DELETE'
  },
  {
    name: 'AddNewAssessment',
    url: '/assessment-calendar/teachers',
    method: 'POST'
  },
  {
    name: 'DeleteAssessmentCalendarAction',
    url: '/assessment-calendar',
    method: 'DELETE'
  },
  {
    name: 'AssessmentCalendarAction',
    url: '/assessment-calendar',
    method: 'POST'
  },
  {
    name: 'AssessmentCalendar',
    url: '',
    method: 'CUSTOM_GET'
  },
  {
    name: 'AssessmentLateWorkMarked',
    url: '/assessment-calendar/late-work/assessment',
    method: 'GET'
  },
  {
    name: 'PatternLateWorkMarked',
    url: '/assessment-calendar/late-work/pattern',
    method: 'GET'
  },
  {
    name: 'AssessmentNoteList',
    url: '/assessment-calendar/notes',
    method: 'GET'
  },
  {
    name: 'AssessmentAPExamList',
    url: '/assessment-calendar/ap-exam',
    method: 'GET'
  },
  {
    name: 'AssessmentTeacherCourseList',
    url: '/assessment-calendar/teachers/course-list',
    method: 'GET'
  },
  {
    name: 'AdminEventPending',
    url: '/events/admin/pending-events',
    method: 'GET'
  },
  {
    name: 'EventLocation',
    url: '/events/location',
    method: 'GET'
  }
];

export const getApiRoutes = (name: string) => {
  const route = API_ROUTES.find((route) => route.name === name);
  return route?.url;
};