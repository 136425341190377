import { Box, Typography } from '@mui/material';
import React from 'react';
import {
  CalendarBody,
  CalendarHead,
  HeadDay,
  SevenColGrid,
  StyledDay,
  days,
  months
} from './AssessmentCalendarStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { generateDate } from '../../helpers/Calendar';
import AssessmentDetails from './AssessmentDetails';

function CalendarView({
  currentMonth,
  currentYear,
  prevMonth,
  nextMonth,
  assessments,
  setAssessmentId,
  showDetailsForm,
  dayNumbers,
  showDetails,
  assessmentId
}) {
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <CalendarHead>
        <ArrowBackIcon onClick={() => prevMonth()} />
        <Typography variant='h5' width={'80%'} textAlign={'center'} noWrap>
          {months[currentMonth]} {currentYear}
        </Typography>
        <ArrowForwardIcon onClick={() => nextMonth()} />
      </CalendarHead>
      <SevenColGrid>
        {days.map((day, index) => {
          return <HeadDay key={index}>{day}</HeadDay>;
        })}
      </SevenColGrid>
      {showDetails ? (
        <AssessmentDetails
          showDetailsForm={showDetailsForm}
          assessmentId={assessmentId}
          assessments={assessments}
        />
      ) : null}
      <CalendarBody>
        {generateDate(currentMonth, currentYear).map((day, index) => {
          var theDay = dayNumbers?.find((obj, index) => {
            if (parseInt(obj.date.substring(8, 10)) === day.date.date()) {
              return obj.cycleDay;
            }
            return null;
          });
          return (
            <StyledDay key={index}>
              <span style={{ color: 'gray' }}>{day.date.date()}</span>
              <span
                style={{
                  position: 'absolute',
                  left: '40%',
                  width: '40%',
                  fontWeight: 700,
                  color: 'orangered'
                }}>
                {day.show ? theDay?.cycleDay : null}
              </span>
              <Box
                marginTop={3}
                key={index}
                style={{
                  padding: 8,
                  backgroundColor: calendarColorization(day, assessments)
                }}>
                {assessments &&
                  assessments?.map((item, index2) => {
                    if (new Date(item.start).getDate() === day.date.date()) {
                      return (
                        <div
                          onClick={() => {
                            setAssessmentId(item.id);
                            showDetailsForm();
                          }}
                          style={{
                            textDecoration: 'none',
                            color: 'black',
                            cursor: 'pointer',
                            paddingBottom: 5
                          }}
                          key={index2}>
                          {item.type === 'assessment' ? (
                            <>
                              {' '}
                              <div data-tooltip-id='my-tooltip-1'>
                                {day.show
                                  ? new Date(item.start).getHours() +
                                    ':' +
                                    new Date(item.start).getMinutes()
                                  : null}{' '}
                                {day.show ? item.summary : null}
                              </div>
                              <ReactTooltip
                                id='my-tooltip-1'
                                place='top'
                                variant='info'
                                content={item.description}
                              />
                            </>
                          ) : null}
                        </div>
                      );
                    }
                    return null;
                  })}
              </Box>
            </StyledDay>
          );
        })}
      </CalendarBody>
    </Box>
  );
}

export default CalendarView;

function calendarColorization(day, assessments) {
  let givenDate = new Date(day.date.toDate().toDateString()),
    currentDate = new Date(new Date().toDateString()),
    list = assessments?.filter(
      (item) => new Date(item.start).getDate() === day.date.date()
    );

  if (!day.show || list?.length < 1) {
    return 'transparent';
  } else if (givenDate < currentDate) {
    return '#ADD8E6';
  } else if (givenDate > currentDate) {
    return '#A857EF';
  }
  return '#90EE90';
}
