export function getCurrentAndNextMonthByNumberValue() {
  const currentDate = new Date(),
    currentMonthByIndex = currentDate.getMonth(),
    currentMonthByActualNumberValue = currentMonthByIndex + 1,
    nextMonthByActualNumberValue =
      currentMonthByActualNumberValue + 1 > 12
        ? 1
        : currentMonthByActualNumberValue + 1;

  const currentMonthByActualNumberValueInString = String(
      currentMonthByActualNumberValue
    ),
    nextMonthByActualNumberValueInString = String(nextMonthByActualNumberValue);

  const currentMonth =
      currentMonthByActualNumberValueInString?.length === 1
        ? '0' + currentMonthByActualNumberValueInString
        : currentMonthByActualNumberValueInString,
    nextMonth =
      nextMonthByActualNumberValueInString?.length === 1
        ? '0' + nextMonthByActualNumberValueInString
        : nextMonthByActualNumberValueInString;

  return {
    currentMonth,
    nextMonth
  };
}
export function getNextMonthByNumberValue(currentMonth) {
  const nextMonthByActualNumberValue =
    currentMonth + 1 > 12 ? 1 : currentMonth + 1;

  const nextMonthByActualNumberValueInString = String(
    nextMonthByActualNumberValue
  );

  const nextMonth =
    nextMonthByActualNumberValueInString?.length === 1
      ? '0' + nextMonthByActualNumberValueInString
      : nextMonthByActualNumberValueInString;

  return nextMonth;
}

export function formatMonthByNumberValue(currentMonth) {
  const monthByActualNumberValueInString = String(currentMonth);
  const month =
    monthByActualNumberValueInString?.length === 1
      ? '0' + monthByActualNumberValueInString
      : monthByActualNumberValueInString;

  return month;
}
