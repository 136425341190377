import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TimePicker } from '@mui/x-date-pickers';
import Autocomplete from '@mui/material/Autocomplete';

import { rtkApiSlice } from 'utils/rtk-api-slices';
import './FieldTripForm.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 2, ml: 2 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function FieldTripDetailsChaperone({
  eventId,
  customRoomValidation,
  userSelfContact,
  contacts,
  menu,
  menuItems,
  vehicles,
  primaryContactId,
  quantitiesFullList,
  setQuantitiesFullList,
  studentCount,
  setStudentCount,
  usePublicTransit,
  setUsePublicTransit,
  noOrganizedTransit,
  setNoOrganizedTransit,
  hasVehicleRequest,
  setHasVehicleRequest,
  hasBusRequest,
  setHasBusRequest,
  busCount,
  setBusCount,
  departureTime,
  setDepartureTime,
  busPickupTime,
  setBusPickupTime,
  returnTime,
  setReturnTime,
  instructions,
  setInstructions,
  numberNeeded,
  setNumberNeeded,
  notes,
  setNotes,
  categoryId,
  setcategoryId,
  numberOfLunches,
  setNumberOfLunches,
  diningCount,
  setDiningCount,
  eatingAway,
  setEatingAway,
  pickupTime,
  setPickupTime,
  hasLunch,
  setHasLunch,
  chaparonesArray,
  setChaparonesArray,
  next,
  setNext
}) {
  const [submittingContact, setSubmittingContact] = useState();
  const [locationValueChaperone, setLocationValueChaperone] = useState(0);
  const [isPublic, setIsPublic] = useState(false);
  const [showLowerSchools, setShowLowerSchools] = useState(false);
  const [showUpperSchools, setShowUpperSchools] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [chaparoneDynamic, setChaparoneDynamic] = useState([]);
  const [selectedChaperones, setSelectedChaperones] = useState([]);
  const [chaparone, setChaparone] = useState('');

  const [userEventActions, userEventActionsReponse] =
    rtkApiSlice.useUserEventActionsMutation();

  const handleChangeChaperoneTab = (value, newValue, find) => {
    chaparonesArray.map((row, index) => {
      if (index === find) {
        setLocationValueChaperone(newValue);
      }
      return null;
    });
  };

  const handleChangeChaperone = (event, index) => {
    selectedChaperones[index] = event?.id;
    setSelectedChaperones(selectedChaperones);
    chaparoneDynamic[index] = event?.id;
    setChaparoneDynamic(chaparoneDynamic);
  };

  const addAnotherChaperone = () => {
    setChaparonesArray([
      ...chaparonesArray,
      {
        id: chaparonesArray.length
      }
    ]);
    chaparoneDynamic[chaparonesArray.length] = '';
    setChaparone(chaparoneDynamic);
  };

  const removeChaparone = (index) => {
    chaparonesArray.splice(index, 1);
    setChaparonesArray([...chaparonesArray]);
    chaparoneDynamic.splice(index, 1);
    setChaparoneDynamic(chaparoneDynamic);
    updateChaperoneInfo();
  };

  const updateContact = async () => {
    var data = {
      isPublic: isPublic,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone
    };
    setSubmittingContact(true);

    if (firstName && lastName && email && phone) {
      await userSelfContact(data);
    }
  };

  const updateChaperoneInfo = async () => {
    if (primaryContactId) {
      await userEventActions({
        body: chaparoneDynamic,
        params: `/${eventId}/field-trip/chaperones`
      });
    }
  };

  return next === 3 ? (
    <>
      <Box>
        <Typography variant='h6'>Chaperones</Typography>
      </Box>
      {chaparonesArray?.length === 0 ? (
        <Box>
          <Button onClick={(e) => addAnotherChaperone(e)}>
            Add another Chaparone
          </Button>
        </Box>
      ) : null}
      {chaparonesArray?.length > 0 &&
        chaparonesArray?.map((chaparone, chaparoneIndex) => {
          if (chaparone !== null) {
            return (
              <div key={chaparoneIndex}>
                <Box
                  sx={{
                    border: 'solid 1px lightgray',
                    borderRadius: '4px',
                    marginBottom: 1,
                    width: '50%'
                  }}>
                  <Box sx={{ width: '93%' }}>
                    <Box sx={{ width: '100%' }}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                          value={locationValueChaperone}
                          onChange={(e, y) => {
                            handleChangeChaperoneTab(e, y, chaparoneIndex);
                          }}
                          aria-label='basic tabs example2'>
                          <Tab
                            label='Select an Existing Contact'
                            {...a11yProps(0)}
                          />
                          <Tab label='Add New Contact' {...a11yProps(1)} />
                        </Tabs>
                      </Box>
                      <TabPanel value={locationValueChaperone} index={0}>
                        <Stack direction={'column'}>
                          <Box marginBottom={3}>
                            <Autocomplete
                              disablePortal
                              id='combo-box-demo'
                              onChange={(event, newValue) => {
                                handleChangeChaperone(
                                  newValue,
                                  chaparoneIndex
                                );
                                console.log('arr ', chaparoneDynamic);
                              }}
                              value={contacts?.find(
                                (option) =>
                                  option.id === chaparoneDynamic[chaparoneIndex]
                              )}
                              options={contacts}
                              sx={{ width: 300 }}
                              getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                  return option;
                                }
                                if (
                                  option?.firstName +
                                  ' ' +
                                  option?.lastName
                                ) {
                                  return (
                                    option?.firstName + ' ' + option?.lastName
                                  );
                                }
                                return (
                                  option?.firstName + ' ' + option?.lastName
                                );
                              }}
                              renderOption={(props, option) => (
                                <li {...props}>
                                  {option?.firstName + ' ' + option?.lastName}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField {...params} label='' />
                              )}
                            />
                          </Box>
                          <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            marginBottom={3}>
                            <Box width={'40%'}>
                              <Box marginTop={1} marginRight={2}>
                                <Typography>Verify Cellphone</Typography>
                              </Box>
                              <Box>
                                <TextField value={chaparone?.phone} />
                              </Box>
                            </Box>
                            <Box width={'30%'}>
                              <Box marginTop={1} marginRight={2}>
                                <Typography>Verify Email</Typography>
                              </Box>
                              <Box>
                                <TextField
                                  // value={
                                  //   contacts?.find(
                                  //     (contact) =>
                                  //       contact.id ===
                                  //       chaparoneDynamic[chaparoneIndex]
                                  //   )?.email
                                  // }
                                  value={
                                    chaparoneDynamic[chaparoneIndex] || ''
                                  }
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Box width={'50%'} marginLeft={20}>
                            <Button onClick={() => updateChaperoneInfo()}>
                              Update Contact Info
                            </Button>
                          </Box>
                        </Stack>
                        {customRoomValidation ? (
                          <Box>
                            <div style={{ color: 'red' }}>Required!</div>
                          </Box>
                        ) : null}
                      </TabPanel>
                      <TabPanel value={locationValueChaperone} index={1}>
                        <Stack direction={'column'}>
                          <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            marginBottom={3}>
                            <Box width={'40%'}>
                              <Box marginTop={1} marginRight={2}>
                                <Typography>First Name</Typography>
                              </Box>
                              <Box>
                                <TextField
                                  value={firstName || ''}
                                  onChange={(e) => setFirstName(e.target.value)}
                                />
                              </Box>
                              {submittingContact && !firstName ? (
                                <Typography fontSize={9} color={'red'}>
                                  Required
                                </Typography>
                              ) : null}
                            </Box>
                            <Box width={'30%'}>
                              <Box marginTop={1} marginRight={2}>
                                <Typography>Email</Typography>
                              </Box>
                              <Box>
                                <TextField
                                  value={email || ''}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </Box>
                              {submittingContact && !email ? (
                                <Typography fontSize={9} color={'red'}>
                                  Required
                                </Typography>
                              ) : null}
                            </Box>
                          </Box>
                          <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            marginBottom={3}>
                            <Box width={'40%'}>
                              <Box marginTop={1} marginRight={2}>
                                <Typography>Last Name</Typography>
                              </Box>
                              <Box>
                                <TextField
                                  value={lastName || ''}
                                  onChange={(e) => setLastName(e.target.value)}
                                />
                              </Box>
                              {submittingContact && !lastName ? (
                                <Typography fontSize={9} color={'red'}>
                                  Required
                                </Typography>
                              ) : null}
                            </Box>
                            <Box width={'30%'}>
                              <Box marginTop={1} marginRight={2}>
                                <Typography>Phone Number</Typography>
                              </Box>
                              <Box>
                                <TextField
                                  maxLength={3}
                                  type='number'
                                  value={phone || ''}
                                  onChange={(e) => setPhone(e.target.value)}
                                />
                              </Box>
                              {submittingContact && !phone ? (
                                <Typography fontSize={9} color={'red'}>
                                  Required
                                </Typography>
                              ) : null}
                            </Box>
                          </Box>
                          <Box>
                            <Box
                              display={'flex'}
                              flexDirection={'row'}
                              alignItems={'center'}
                              marginBottom={3}>
                              <Checkbox
                                value={isPublic}
                                onChange={(e) => setIsPublic(e.target.checked)}
                              />
                              <Typography>Keep Contact Private</Typography>
                            </Box>
                            <Box width={'50%'} marginLeft={20}>
                              <Button onClick={() => updateContact()}>
                                Update Contact Info
                              </Button>
                            </Box>
                          </Box>
                        </Stack>
                        {customRoomValidation ? (
                          <Box>
                            <div style={{ color: 'red' }}>Required!</div>
                          </Box>
                        ) : null}
                      </TabPanel>
                    </Box>
                  </Box>
                </Box>
                <Box margin={1}>
                  <Button onClick={(e) => addAnotherChaperone(e)}>
                    Add another Chaparone
                  </Button>
                  <Button
                    variant='outlined'
                    onClick={() => removeChaparone(chaparoneIndex)}
                    startIcon={<DeleteIcon />}
                    color='error'>
                    Remove
                  </Button>
                </Box>
              </div>
            );
          } else {
            return null;
          }
        })}

      <Box marginTop={2}>
        <Typography variant='h6'>
          Number of Students by Grade Level
        </Typography>
      </Box>
      <Box
        sx={{ cursor: 'pointer' }}
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        onClick={() => {
          setShowLowerSchools(!showLowerSchools);
        }}
        marginLeft={3}>
        {showLowerSchools ? (
          <KeyboardArrowDownIcon />
        ) : (
          <KeyboardArrowRightIcon />
        )}
        <Typography>Lower School</Typography>
      </Box>
      {showLowerSchools ? (
        <Box
          sx={{
            border: 'solid 1px lightgray',
            padding: 3,
            borderRadius: 4,
            width: 'fit-content'
          }}
          marginBottom={2}>
          <Box
            sx={{
              width: 'fit-content',
              padding: '20px',
              borderRadius: '4px'
            }}
            marginLeft={11}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              marginBottom={3}>
              <Box width={'30%'}>
                <Box marginTop={1} marginRight={2}>
                  <Typography>Class I</Typography>
                </Box>
                <Box>
                  <TextField
                    type='number'
                    placeholder='0'
                    value={studentCount.classI || ''}
                    onChange={(e) => {
                      setStudentCount({
                        ...studentCount,
                        classI: parseInt(e.target.value)
                      });
                    }}
                  />
                </Box>
              </Box>
              <Box width={'30%'} marginLeft={5}>
                <Box marginTop={1} marginRight={2}>
                  <Typography>Class II</Typography>
                </Box>
                <Box>
                  <TextField
                    type='number'
                    placeholder='0'
                    value={studentCount.classII || ''}
                    onChange={(e) => {
                      setStudentCount({
                        ...studentCount,
                        classII: parseInt(e.target.value)
                      });
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              marginBottom={3}>
              <Box width={'30%'}>
                <Box marginTop={1} marginRight={2}>
                  <Typography>Class III</Typography>
                </Box>
                <Box>
                  <TextField
                    type='number'
                    placeholder='0'
                    value={studentCount.classIII || ''}
                    onChange={(e) => {
                      setStudentCount({
                        ...studentCount,
                        classIII: parseInt(e.target.value)
                      });
                    }}
                  />
                </Box>
              </Box>
              <Box width={'30%'} marginLeft={5}>
                <Box marginTop={1} marginRight={2}>
                  <Typography>Class IV</Typography>
                </Box>
                <Box>
                  <TextField
                    type='number'
                    placeholder='0'
                    value={studentCount.classIV || ''}
                    onChange={(e) => {
                      setStudentCount({
                        ...studentCount,
                        classIV: parseInt(e.target.value)
                      });
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : null}
      <Box
        sx={{ cursor: 'pointer' }}
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        onClick={() => {
          setShowUpperSchools(!showUpperSchools);
        }}
        marginLeft={3}>
        {showUpperSchools ? (
          <KeyboardArrowDownIcon />
        ) : (
          <KeyboardArrowRightIcon />
        )}
        <Typography>Upper School</Typography>
      </Box>
      {showUpperSchools ? (
        <Box
          sx={{
            border: 'solid 1px lightgray',
            padding: 3,
            borderRadius: 4,
            width: 'fit-content'
          }}
          marginBottom={2}>
          <Box
            sx={{
              width: 'fit-content',
              padding: '20px',
              borderRadius: '4px'
            }}
            marginLeft={11}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              marginBottom={3}>
              <Box width={'30%'}>
                <Box marginTop={1} marginRight={2}>
                  <Typography>Class V</Typography>
                </Box>
                <Box>
                  <TextField
                    type='number'
                    placeholder='0'
                    value={studentCount.classV || ''}
                    onChange={(e) => {
                      setStudentCount({
                        ...studentCount,
                        classV: parseInt(e.target.value)
                      });
                    }}
                  />
                </Box>
              </Box>
              <Box width={'30%'} marginLeft={5}>
                <Box marginTop={1} marginRight={2}>
                  <Typography>Class VII</Typography>
                </Box>
                <Box>
                  <TextField
                    type='number'
                    placeholder='0'
                    value={studentCount.classVI || ''}
                    onChange={(e) => {
                      setStudentCount({
                        ...studentCount,
                        classVI: parseInt(e.target.value)
                      });
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              marginBottom={3}>
              <Box width={'30%'}>
                <Box marginTop={1} marginRight={2}>
                  <Typography>Class VI</Typography>
                </Box>
                <Box>
                  <TextField
                    type='number'
                    placeholder='0'
                    value={studentCount.classVII || ''}
                    onChange={(e) => {
                      setStudentCount({
                        ...studentCount,
                        classVII: parseInt(e.target.value)
                      });
                    }}
                  />
                </Box>
              </Box>
              <Box width={'30%'} marginLeft={5}>
                <Box marginTop={1} marginRight={2}>
                  <Typography>Class VIII</Typography>
                </Box>
                <Box>
                  <TextField
                    type='number'
                    placeholder='0'
                    value={studentCount.classVIII || ''}
                    onChange={(e) => {
                      setStudentCount({
                        ...studentCount,
                        classVIII: parseInt(e.target.value)
                      });
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : null}
      <Box marginTop={2}>
        <Typography variant='h6'>Transportation</Typography>
      </Box>
      <Box marginLeft={4}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                value={hasBusRequest || false}
                checked={hasBusRequest || false}
                onChange={(e) => {
                  setHasBusRequest(e.target.checked);
                }}
              />
            }
            label='Hired Outside Busses'
          />
          {hasBusRequest ? (
            <Box
              sx={{
                border: 'solid 1px lightgray',
                padding: 3,
                borderRadius: 4,
                width: 'fit-content'
              }}
              marginBottom={2}>
              <Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginBottom={3}>
                  <Box>
                    <Typography>Number of Outside Busses Needed</Typography>
                  </Box>
                  <Box marginLeft={2}>
                    <TextField
                      placeholder='0'
                      type='number'
                      value={busCount || ''}
                      onChange={(e) => setBusCount(e.target.value)}
                    />
                  </Box>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginBottom={3}>
                  <Box>
                    <Typography>Time of Departure</Typography>
                  </Box>
                  <Box marginLeft={17}>
                    <input
                      type='time'
                      className='date'
                      value={departureTime}
                      onChange={(e) => setDepartureTime(e.target.value)}
                    />
                  </Box>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginBottom={3}>
                  <Box>
                    <Typography>Bus Pickup Time for Return</Typography>
                  </Box>
                  <Box marginLeft={9}>
                    <input
                      type='time'
                      className='date'
                      value={busPickupTime}
                      onChange={(e) => setBusPickupTime(e.target.value)}
                    />
                  </Box>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginBottom={3}>
                  <Box>
                    <Typography>Arrival Back on Campus</Typography>
                  </Box>
                  <Box marginLeft={12}>
                    <input
                      type='time'
                      className='date'
                      value={returnTime}
                      onChange={(e) => setReturnTime(e.target.value)}
                    />
                  </Box>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginBottom={3}>
                  <Box>
                    <Typography>Instructions for Bus Driver</Typography>
                  </Box>
                  <Box marginLeft={10}>
                    <textarea
                      value={instructions || ''}
                      onChange={(e) => setInstructions(e.target.value)}
                      rows={10}
                      cols={40}
                      style={{
                        border: 'solid 1px lightgray',
                        borderRadius: '4px',
                        padding: `10px`
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : null}
          <FormControlLabel
            control={
              <Checkbox
                value={hasVehicleRequest || false}
                checked={hasVehicleRequest || false}
                onChange={(e) => {
                  setHasVehicleRequest(e.target.checked);
                }}
              />
            }
            label='Winsor Vehicles'
          />
          {hasVehicleRequest ? (
            <Box
              sx={{
                border: 'solid 1px lightgray',
                padding: 3,
                borderRadius: 4,
                width: 'fit-content'
              }}
              marginBottom={2}>
              <Box marginLeft={10}>
                <Box marginBottom={2} marginLeft={33}>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    sx={{ width: 300 }}
                    size='small'
                    value={categoryId || ''}
                    onChange={(e) => setcategoryId(e.target.value)}>
                    {vehicles &&
                      vehicles.map((r, index) => {
                        return (
                          <MenuItem key={index} value={r.id}>
                            {r.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginBottom={3}>
                  <Box>
                    <Typography>Quantity</Typography>
                  </Box>
                  <Box marginLeft={25}>
                    <TextField
                      value={numberNeeded || ''}
                      onChange={(e) => setNumberNeeded(e.target.value)}
                    />
                  </Box>
                </Box>
                <Box marginLeft={32}>
                  <textarea
                    value={notes || ''}
                    onChange={(e) => setNotes(e.target.value)}
                    rows={10}
                    cols={40}
                    style={{
                      border: 'solid 1px lightgray',
                      borderRadius: '4px',
                      padding: '10px'
                    }}
                  />
                </Box>
              </Box>
            </Box>
          ) : null}
          <FormControlLabel
            control={
              <Checkbox
                value={usePublicTransit || false}
                checked={usePublicTransit || false}
                onChange={(e) => setUsePublicTransit(e.target.checked)}
              />
            }
            label='Using Public Transportation'
          />
          <FormControlLabel
            control={
              <Checkbox
                value={noOrganizedTransit || false}
                checked={noOrganizedTransit || false}
                onChange={(e) => setNoOrganizedTransit(e.target.checked)}
              />
            }
            label='Students Responsible For their Own Transportation'
          />
        </FormGroup>
      </Box>
      <Box marginTop={2}>
        <Typography variant='h6'>Field Trip Food</Typography>
      </Box>
      <Box marginLeft={4}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                value={hasLunch || false}
                checked={hasLunch || false}
                onChange={(e) => setHasLunch(e.target.checked)}
              />
            }
            label='Lunch Required'
          />
        </FormGroup>
      </Box>
      {hasLunch ? (
        <Box marginLeft={10}>
          <Box>
            <Box>
              <Typography variant='h6'>Menu</Typography>
            </Box>
          </Box>
          <Box
            margin={2}
            sx={{
              border: 'solid 1px lightgray',
              borderRadius: 1,
              padding: 2,
              width: '60%'
            }}>
            <Stack direction={'row'} marginBottom={1}>
              <Box width={'69%'}>
                <Typography variant='h6'>Item</Typography>
              </Box>
              <Box width={'23%'}>
                <Typography variant='h6'>Quantity</Typography>
              </Box>
              <Box width={'5%'}>
                <Typography variant='h6'>Price</Typography>
              </Box>
            </Stack>
            <Divider variant='light' />
            {menu.map((inner, index) => {
              var createdObject = menuItems?.map((menuItem, menuIndex) => {
                <div key={menuIndex}></div>;
                return (createdObject = {
                  itemId: menuItem.item.id,
                  quantity: menuItem.quantity
                });
              });
              return (
                <Stack direction={'row'} key={index}>
                  <Box p={0} width={'80%'} sx={{ padding: '0px' }}>
                    <Typography component={'span'}>{inner.name}</Typography>
                  </Box>
                  <Box width={'10%'} p={0}>
                    <TextField
                      variant='standard'
                      type='number'
                      placeholder={
                        quantitiesFullList[index]['quantity'] ||
                        createdObject
                          ?.map((u, index312) => {
                            if (
                              u['itemId'] ===
                              quantitiesFullList[index]?.itemId
                            ) {
                              quantitiesFullList[index]['quantity'] =
                                parseInt(u['quantity']);
                              <div key={index312}></div>;
                              return u['quantity'].toString();
                            }
                            return null;
                          })
                          .join(' ')
                          .trim() ||
                        '0'
                      }
                      size='small'
                      className='number-class'
                      onChange={(e) => {
                        quantitiesFullList[index]['quantity'] = e.target.value;
                        setQuantitiesFullList(quantitiesFullList);
                      }}
                    />
                  </Box>
                  <Box width={'10%'} marginLeft={15} p={0}>
                    <Typography
                      component={'span'}
                      color={'gray'}>{`$${inner.pricePerPerson}`}</Typography>
                  </Box>
                </Stack>
              );
            })}
          </Box>

          <Box>
            <Box>
              <Typography variant='h6'>Additional Details</Typography>
            </Box>
            <Box
              sx={{
                border: 'solid 1px lightgray',
                padding: 3,
                borderRadius: 4,
                width: 'fit-content'
              }}
              marginBottom={4}>
              <Box marginLeft={8}>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={eatingAway || false}
                        checked={eatingAway || false}
                        onChange={(e) => setEatingAway(e.target.checked)}
                      />
                    }
                    label='Eating Off Campus'
                  />
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginBottom={3}>
                  <Box>
                    <Typography>Number of Lunches</Typography>
                  </Box>
                  <Box marginLeft={30}>
                    <TextField
                      value={numberOfLunches}
                      onChange={(e) => setNumberOfLunches(e.target.value)}
                    />
                  </Box>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginBottom={3}>
                  <Box>
                    <Typography>Pickup Time</Typography>
                  </Box>
                  <Box marginLeft={37}>
                    <TimePicker
                      value={pickupTime}
                      onChange={(e) => setPickupTime(e.$d.toISOString())}
                    />
                  </Box>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginBottom={3}>
                  <Box>
                    <Typography>
                      Number of People Eating in the Dining Hall
                    </Typography>
                  </Box>
                  <Box marginLeft={10}>
                    <TextField
                      value={diningCount}
                      onChange={(e) => setDiningCount(e.target.value)}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  ) : (
    <></>
  );
}

export default FieldTripDetailsChaperone;