import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import CachedIcon from '@mui/icons-material/Cached';

import NewAssessment from '../../components/dropdowns/NewAssessment';
import CourseSelectionOptions from './calendar-molecules/CourseSelectionOptions';
import MyAssessments from './calendar-molecules/MyAssessments';
import AssessmentSelection from './calendar-molecules/AssessmentSelection';
import Views from './late-work/Views';
import CalendarView from './CalendarView';
import StudentView from './late-work/StudentView';
import AssessmentNotes from './calendar-molecules/AssessmentNotes';
import AssessmentAPExam from './calendar-molecules/AssessmentAPExam';
import { rtkApiSlice } from 'utils/rtk-api-slices';
import { AssessmentCalendarTab } from './AssessmentCalendarTab';
import {
  formatMonthByNumberValue,
  getCurrentAndNextMonthByNumberValue,
  getNextMonthByNumberValue
} from '../../helpers/month';

const AssessmentCalendar = () => {
  const userRoles = useSelector(state => state?.authentication?.roles);
  const [startingDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(startingDate.getMonth());
  const [currentYear, setCurrentYear] = useState(startingDate.getFullYear());
  const [assessments, setAssessments] = useState([]);
  const [myAssessments, setMyAssessments] = useState([]);
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState('');
  const [courseSelection, setCourseSelection] = useState([]);
  const [note, setNote] = useState('');
  const [sectionDates, setSectionDates] = useState([]);
  const [selectionDetails, setSelectionDetails] = useState();
  const [dayNumbers, setDayNumbers] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [assessmentId, setAssessmentId] = useState('');
  const [classList] = useState([]);
  const [showCalendar, setShowCalendar] = useState(true);
  const [showCourseDates, setShowCourseDates] = useState(false);
  const [showLateWork, setShowLateWork] = useState(false);
  const [showStudetLateWork, setShowStudentLateWork] = useState(false);
  const [showSelectionView, setShowSelectionView] = useState(false);
  const [showAddNotes, setShowAddNotes] = useState(false);
  const [showAddAPExam, setShowAddAPExam] = useState(false);
  const [loadCalendar, setLoadCalendar] = useState(false);
  const [newNote, setNewNote] = useState('');
  const [newDate, setNewDate] = useState('');
  const [newClassessAffected, setNewClassessAffected] = useState([]);
  const [assessmentNotes, setAssessmentNotes] = useState();
  const [assessmentExams, setAssessmentExams] = useState([]);
  const [courseName, setCourseName] = useState('');
  const [startDateTime, setstartDateTime] = useState('');
  const [endDateTime, setendDateTime] = useState('');
  const [studentIds, setstudentIds] = useState([]);
  const [noteId, setNoteId] = useState('');
  const [examId, setExamId] = useState('');
  const [toggleOption, setToggleOption] = useState(false);
  const [switchedTab, setSwitchedTab] = useState(0);
  const [
    actionTypeForNewOREditAssessment,
    setActionTypeForNewOREditAssessment
  ] = useState(null);

  // getAssessmentNotes
  const getAssessmentNotesReponse = rtkApiSlice.useAssessmentNoteListQuery();

  const getAssessmentExamsRequestReponse =
    rtkApiSlice.useAssessmentAPExamListQuery();

  const getTeacherCoursesReponse =
    rtkApiSlice.useAssessmentTeacherCourseListQuery();

  useEffect(() => {
    setAssessmentNotes(getAssessmentNotesReponse?.data);
    setAssessmentExams(getAssessmentExamsRequestReponse?.data);
  }, [getAssessmentNotesReponse?.data, getAssessmentExamsRequestReponse?.data]);

  useEffect(() => {
    const courses = getTeacherCoursesReponse?.data;
    setCourses(courses);
    courses?.length > 0 && setCourse(courses[0]);

    getAssessments(currentMonth + 1, currentYear);
    getDayNumbers(currentMonth + 1, currentYear);
    getMyAssessments(currentMonth, currentYear);
  }, [currentMonth, currentYear, getTeacherCoursesReponse?.data]);

  const [
    deleteAssessmentCalendarAction,
    deleteAssessmentCalendarActionReponse
  ] = rtkApiSlice.useDeleteAssessmentCalendarActionMutation();
  const handleDeleteReponse = async () => {
    if (deleteAssessmentCalendarActionReponse?.isSuccess) {
      await getAssessmentNotesReponse?.refetch();
    }
  };
  useEffect(() => {
    handleDeleteReponse();
  }, [deleteAssessmentCalendarActionReponse?.isSuccess]);

  const [assessmentCalendarAction, assessmentCalendarActionReponse] =
    rtkApiSlice.useAssessmentCalendarActionMutation();
  const handleActionReponse = async () => {
    if (assessmentCalendarActionReponse?.isSuccess) {
      await getAssessmentNotesReponse?.refetch();
      setAssessments(assessmentCalendarActionReponse?.data);
    }
  };
  useEffect(() => {
    handleActionReponse();
  }, [assessmentCalendarActionReponse?.isSuccess]);

  const nextMonth = () => {
    setLoadCalendar(true);
    setTimeout(() => {
      setLoadCalendar(false);
    }, 1000);
    if (currentMonth < 11) {
      setCurrentMonth(prev => prev + 1);
    } else {
      setCurrentMonth(0);
      setCurrentYear(prev => prev + 1);
    }
    getAssessments(currentMonth + 1, currentYear);
  };
  const prevMonth = () => {
    setLoadCalendar(true);
    setTimeout(() => {
      setLoadCalendar(false);
    }, 1000);
    if (currentMonth > 0) {
      setCurrentMonth(prev => prev - 1);
    } else {
      setCurrentMonth(11);
      setCurrentYear(prev => prev - 1);
    }
    getAssessments(currentMonth + 1, currentYear);
    getDayNumbers(currentMonth + 1, currentYear);
  };

  // getAssessmentCalendar
  const year = `${currentYear}-${currentMonth + 1}-01`;
  const lastDay = moment(year, 'YYYY-MM-DD h:m').endOf('month').format('D');
  const month = moment(year, 'YYYY-MM-DD h:m').format('M');
  const getAssessmentCalendarReponse = rtkApiSlice.useAssessmentCalendarQuery(
    {
      url: `/assessment-calendar?start=${currentYear}-${month}-01&end=${currentYear}-${month}-${lastDay}`
    },
    { refetchOnMountOrArgChange: true, refetchOnReconnect: true }
  );

  // getAssessmentCalendar
  const [, setGetAssessmentCalendarInput] = useState({
    nextMonth1: getCurrentAndNextMonthByNumberValue()?.nextMonth,
    month1: getCurrentAndNextMonthByNumberValue()?.currentMonth,
    year1: new Date().getFullYear()
  });

  const getAssessments = (month, year) => {
    setGetAssessmentCalendarInput({
      month1: month,
      year1: year,
      nextMonth1: getNextMonthByNumberValue(month)
    });
  };
  useEffect(() => {
    setAssessments(getAssessmentCalendarReponse?.data);
  }, [getAssessmentCalendarReponse?.data]);

  // getDayNumbersRequest
  const [{ month2, year2 }, setgetDayNumbersRequestInput] = useState({
    month2: getCurrentAndNextMonthByNumberValue()?.currentMonth,
    year2: new Date().getFullYear()
  });
  const getDayNumbersRequestReponse = rtkApiSlice.useAssessmentCalendarQuery(
    {
      url: `/schedule/cycle-day?start=${year2}-${month2}-01`
    },
    { refetchOnMountOrArgChange: true, refetchOnReconnect: true }
  );
  const getDayNumbers = (month, year) => {
    setgetDayNumbersRequestInput({ month2: month, year2: year });
  };
  useEffect(() => {
    setDayNumbers(getDayNumbersRequestReponse?.data);
  }, [getDayNumbersRequestReponse?.data]);

  // getMyAssessments
  const [{ month3, year3, nextMonth3 }, setgetMyAssessmentsInput] = useState({
    nextMonth3: getCurrentAndNextMonthByNumberValue()?.nextMonth,
    month3: getCurrentAndNextMonthByNumberValue()?.currentMonth,
    year3: new Date().getFullYear()
  });
  const getMyAssessmentsReponse = rtkApiSlice.useAssessmentCalendarQuery(
    {
      url: `/assessment-calendar/teachers?start=${year3}-${formatMonthByNumberValue(
        month3
      )}-01&end=${year3 + 1}-${nextMonth3}-01`
    },
    { refetchOnMountOrArgChange: true, refetchOnReconnect: true }
  );
  const getMyAssessments = (month, year) => {
    setgetMyAssessmentsInput({
      month3: month,
      year3: year,
      nextMonth3: getNextMonthByNumberValue(month)
    });
  };
  useEffect(() => {
    setMyAssessments(getMyAssessmentsReponse?.data);
  }, [getMyAssessmentsReponse?.data]);

  const showAssessmentDetails = details => {
    setShowCalendar(false);
    setShowLateWork(false);
    setShowSelectionView(true);
    setShowStudentLateWork(false);
    setSelectionDetails(details);
  };
  const hideAssessmentDetails = () => {
    setSwitchedTab(0);
    setShowCalendar(true);
    setShowLateWork(false);
    setShowSelectionView(false);
    setShowStudentLateWork(false);
  };
  const showLateWorkView = () => {
    setShowLateWork(true);
    setShowCalendar(false);
    setShowStudentLateWork(false);
    setShowSelectionView(false);
  };
  const showStudentLateWorkView = () => {
    setShowStudentLateWork(true);
    setShowCalendar(false);
    setShowLateWork(false);
    setShowSelectionView(false);
  };
  const hideLateWorkView = () => {
    setShowLateWork(false);
    setShowCalendar(true);
  };
  const showDetailsForm = () => {
    setShowDetails(!showDetails);
  };
  const setTeachCourse = e => {
    setCourse(e);
  };
  const setSelectionNote = e => {
    setNote(e);
  };
  const setSectionDatesOptions = (id, date) => {
    if (date) {
      sectionDates.push({ sectionId: id, date: date });
      setSectionDates(sectionDates);
    }
  };

  //createNewAssessment
  const [addNewAssessment, addNewAssessmentReponse] =
    rtkApiSlice.useAddNewAssessmentMutation();
  const handleCreateNewAssessment = async () => {
    const { isSuccess, isError, error } = addNewAssessmentReponse;
    if (isError) {
      alert(error?.data?.error);
    }

    if (isSuccess) {
      setNote('');
      setSectionDates([]);
      await getMyAssessmentsReponse?.refetch();
      window.location.reload();
    }
  };
  useEffect(() => {
    handleCreateNewAssessment();
  }, [addNewAssessmentReponse]);
  const createNewAssessment = async () => {
    const data = {
      note: note,
      sectionDates: sectionDates,
      doItAnyway: true
    };
    if (actionTypeForNewOREditAssessment === 'edit') {
      const assessment = myAssessments?.find((ass) =>
        course?.displayName?.includes(ass?.course)
      );
      return await addNewAssessment({
        body: data,
        params: `/${assessment?.id}`,
        method: 'PUT'
      });
    }
    await addNewAssessment(data);
  };
  const showCourseForm = () => {
    setShowCourseDates(!showCourseDates);
  };

  // getcourseSelection
  const [teacherCourseSelectionActionId, setGetTeacherCourseSelectionActionId] =
    useState();
  const getTeacherCourseSelectionAction =
    rtkApiSlice.useAssessmentCalendarQuery(
      teacherCourseSelectionActionId
        ? {
            url: `/assessment-calendar/teachers/courses/${teacherCourseSelectionActionId}/sections`
          }
        : {}
    );
  useEffect(() => {
    setCourseSelection(getTeacherCourseSelectionAction?.data);
  }, [getTeacherCourseSelectionAction?.data]);
  const getcourseSelection = () => {
    setActionTypeForNewOREditAssessment(null);
    setGetTeacherCourseSelectionActionId(course.courseId);
  };
  const onEditAssessment = (courseDisplayName) => {
    setActionTypeForNewOREditAssessment('edit');
    const courseToEdit = courses?.find(
      (courseElement) => courseElement?.displayName === courseDisplayName
    );
    setCourse(courseToEdit); //set for id ref via edit endpoint
    setGetTeacherCourseSelectionActionId(courseToEdit.courseId);
    showCourseForm();
  };

  //deleteAssessment
  const deleteAssessment = async (id) => {
    await deleteAssessmentCalendarAction(`teachers/${id}`);
  };

  // getAssessmentByClass
  const calendarByClass = async e => {
    if (classList.indexOf(e) === -1) {
      classList.push(e);
    } else {
      classList.splice(classList.indexOf(e), 1);
    }
    await assessmentCalendarAction({
      body: [e], //classList
      params: `/by-class?start=${year}&end=${currentYear}-${month}-${lastDay}`,
      method: 'POST'
    });
  };

  const hideNotes = () => {
    setShowAddNotes(false);
    setNoteId(null);
    setToggleOption(!toggleOption);
  };
  const hideAPExam = () => {
    setShowAddAPExam(false);
    setExamId(null);
    setToggleOption(!toggleOption);
  };
  const handleClasses = (value, isTrue) => {
    if (isTrue) {
      newClassessAffected?.push(value);
      setNewClassessAffected(newClassessAffected);
    } else {
      setNewClassessAffected(newClassessAffected?.filter(x => x !== value));
    }
  };
  const handleNewNote = e => {
    setNewNote(e);
  };
  const handleNewDate = e => {
    setNewDate(e);
  };

  const saveNotes = id => {
    var data = {
      note: newNote,
      date: newDate,
      classesAffected: newClassessAffected
    };
    if (data.date && data.note) {
      if (id) {
        assessmentCalendarAction({
          body: data,
          params: `/notes/${id}`,
          method: 'PUT'
        });
      } else {
        assessmentCalendarAction({
          body: data,
          params: `/notes`
        });
      }
    } else {
      alert('Date and Note are required!');
    }
    setToggleOption(!toggleOption);
  };

  const deleteNote = async id => {
    await deleteAssessmentCalendarAction(`notes/${id}`);
  };

  const editNote = item => {
    setNewNote(item.text);
    setNewDate(
      item.startDateTime.substring(0, 4) +
        '-' +
        item.startDateTime.substring(5, 7) +
        '-' +
        item.startDateTime.substring(8, 10)
    );
    setNewClassessAffected(item.affectedClasses);
    setNoteId(item.id);
    setShowAddNotes(true);
    setShowAddAPExam(false);
  };
  // Assessment Exam
  const handleCourseName = e => {
    setCourseName(e);
  };
  const handleStartDate = e => {
    setstartDateTime(e);
  };
  const handleEndDate = e => {
    setendDateTime(e);
  };

  const editExam = item => {
    setCourseName(item.courseName);
    setstartDateTime(
      item.startDateTime.substring(0, 4) +
        '-' +
        item.startDateTime.substring(5, 7) +
        '-' +
        item.startDateTime.substring(8, 10)
    );
    setendDateTime(
      item.endDateTime.substring(0, 4) +
        '-' +
        item.endDateTime.substring(5, 7) +
        '-' +
        item.endDateTime.substring(8, 10)
    );
    setstudentIds(item.studentIds);
    setExamId(item.id);
    setShowAddNotes(false);
    setShowAddAPExam(true);
  };

  //saveExam
  const saveExam = studentArray => {
    var data = {
      courseName: courseName,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      sectionIds: [],
      studentIds: studentArray
    };
    if (data.courseName && data.startDateTime) {
      if (examId) {
        assessmentCalendarAction({
          body: data,
          url: `/ap-exam/${examId}`,
          method: 'PUT'
        });
      } else {
        assessmentCalendarAction({
          body: data,
          url: `/ap-exam`
        });
      }
    } else {
      alert('Date and Note are required!');
    }
    setToggleOption(!toggleOption);
  };

  return (
    <Box sx={{ marginBottom: 100 }}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}>
        <a
          href={'/student-calendar'}
          style={{
            flexWrap: 'nowrap',
            width: '500px',
            textDecoration: 'none'
          }}>
          <div
            style={{
              flexWrap: 'nowrap',
              textDecoration: 'none',
              cursor: 'pointer',
              fontWeight: 'bold',
              backgroundColor: ' #0293DB',
              color: 'white',
              padding: 10,
              margin: 3,
              borderRadius: 10
            }}>
            {'Student Assessment Calendars'}
          </div>
        </a>
        {showCalendar ? (
          <Box marginLeft={60} width={'50vw'} display={'flex'}>
            <CachedIcon
              onClick={() => window.location.reload()}
              style={{
                color: 'magenta',
                padding: 10,
                alignSelf: 'center',
                cursor: 'pointer'
              }}
            />
            <Button
              style={{
                backgroundColor: '#0293DB',
                color: 'white',
                padding: 10,
                margin: 3
              }}
              onClick={() => calendarByClass('Class V')}>
              Class V
            </Button>
            <Button
              style={{
                backgroundColor: '#0293DB',
                color: 'white',
                padding: 10,
                margin: 3
              }}
              onClick={() => calendarByClass('Class VI')}>
              Class VI
            </Button>
            <Button
              style={{
                backgroundColor: '#0293DB',
                color: 'white',
                padding: 10,
                margin: 3
              }}
              onClick={() => calendarByClass('Class VII')}>
              Class VII
            </Button>
            <Button
              style={{
                backgroundColor: '#0293DB',
                color: 'white',
                padding: 10,
                margin: 3
              }}
              onClick={() => calendarByClass('Class VIII')}>
              Class VIII
            </Button>
          </Box>
        ) : null}
      </Stack>

      {/* tab */}
      <AssessmentCalendarTab
        updateValue={switchedTab}
        onToggle={setSwitchedTab}
        isAdmin={
          userRoles?.includes('Division Head') ||
          userRoles?.includes('Winsor - Registrar')
        }
      />

      <Stack direction={'row'} marginTop={1}>
        {/* modal */}
        {showAddNotes && toggleOption && (
          <Box
            sx={{
              padding: 3,
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 998
            }}>
            <Box
              onClick={() => setToggleOption(!toggleOption)}
              sx={{
                padding: 3,
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 999,
                backgroundColor: '#0000005f'
              }}
            />
            <Box
              sx={{
                borderRadius: 4,
                border: 'solid 1px lightgray',
                padding: 3,
                backgroundColor: 'seashell',
                position: 'relative',
                zIndex: 1000,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
                // width:"fit-content",
              }}
              maxHeight={'600px'}>
              <AssessmentNotes
                hideNotes={hideNotes}
                saveNotes={saveNotes}
                handleClasses={handleClasses}
                handleNewNote={handleNewNote}
                handleNewDate={handleNewDate}
                newNote={newNote}
                newDate={newDate}
                classesAffected={newClassessAffected}
                noteId={noteId}
              />
            </Box>
          </Box>
        )}
        {showAddAPExam && toggleOption && (
          <Box
            sx={{
              padding: 3,
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 998
            }}>
            <Box
              onClick={() => setToggleOption(!toggleOption)}
              sx={{
                padding: 3,
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 999,
                backgroundColor: '#0000005f'
              }}
            />
            <Box
              sx={{
                borderRadius: 4,
                border: 'solid 1px lightgray',
                padding: 3,
                backgroundColor: 'seashell',
                position: 'relative',
                zIndex: 1000,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
                // width:"fit-content",
              }}
              maxHeight={'600px'}>
              <AssessmentAPExam
                hideAPExam={hideAPExam}
                handleEndDate={handleEndDate}
                handleCourseName={handleCourseName}
                handleStartDate={handleStartDate}
                saveExam={saveExam}
                courseName={courseName}
                startDateTime={startDateTime}
                endDateTime={endDateTime}
                studentIds={studentIds}
                examId={examId}
              />
            </Box>
          </Box>
        )}

        {/* Assessment */}
        {switchedTab === 1 && (
          <Box
            minWidth={'40vw'}
            marginRight={2}
            sx={{
              borderRadius: 4,
              border: 'solid 1px lightgray',
              padding: 3
            }}>
            <Box>
              <Typography variant='h5'>New Assessment</Typography>
              <Box marginBottom={2} marginTop={2}>
                <NewAssessment
                  courses={courses}
                  setTeachCourse={setTeachCourse}
                  course={course}
                />
              </Box>
              <Box marginBottom={3}>
                <Button
                  onClick={() => {
                    getcourseSelection();
                    showCourseForm();
                  }}>
                  Select Course
                </Button>
                {showCourseDates ? (
                  <CourseSelectionOptions
                    courseSelection={courseSelection}
                    setSectionDatesOptions={setSectionDatesOptions}
                    setSelectionNote={setSelectionNote}
                    showCourseForm={showCourseForm}
                    createNewAssessment={createNewAssessment}
                    myAssessments={myAssessments}
                    actionType={actionTypeForNewOREditAssessment}
                  />
                ) : (
                  <Box marginTop={3} marginBottom={3}>
                    <Typography>
                      Select a Course to add Assessmemt Date(s)
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Box marginBottom={2} marginTop={2}>
              <Divider />
            </Box>
            <Box>
              <Box marginBottom={3} marginTop={4}>
                <Typography variant='h5'>My Assessment</Typography>
              </Box>
              {myAssessments?.length > 0 ? (
                <Box>
                  <MyAssessments
                    myAssessments={myAssessments}
                    showAssessmentDetails={showAssessmentDetails}
                    deleteAssessment={deleteAssessment}
                    onEditAssessment={onEditAssessment}
                  />
                </Box>
              ) : null}
            </Box>
            {showSelectionView ? (
              <AssessmentSelection
                selectionDetails={selectionDetails}
                showCalendar={hideAssessmentDetails}
              />
            ) : null}
          </Box>
        )}

        {/* Late work */}
        {switchedTab === 2 && (
          <Box
            minWidth={'40vw'}
            marginRight={2}
            sx={{
              borderRadius: 4,
              border: 'solid 1px lightgray',
              padding: 3
            }}>
            <Typography variant='h5'>Late Work</Typography>
            <Button onClick={() => showLateWorkView()}>View Late Work</Button>
            <Button onClick={() => showStudentLateWorkView()}>
              View Late Work By Student
            </Button>
            <Box marginBottom={2} marginTop={4}>
              <Divider />
            </Box>
            {showStudetLateWork ? <StudentView /> : null}
            {showLateWork ? (
              <Views
                hideLateWorkView={hideLateWorkView}
                hideAssessmentDetails={hideAssessmentDetails}
              />
            ) : null}
          </Box>
        )}

        {/* Division Head or Winsor - Registrar */}
        {switchedTab === 3 && (
          <Box
            minWidth={'40vw'}
            marginRight={2}
            sx={{
              borderRadius: 4,
              border: 'solid 1px lightgray',
              padding: 3
            }}>
            <Typography variant='h5'>Notes</Typography>
            <Stack direction={'row'}>
              <Button
                onClick={() => {
                  setNoteId(null);
                  setShowAddAPExam(false);
                  setShowAddNotes(true);
                  setToggleOption(!toggleOption);
                }}>
                Add Date Note
              </Button>
              <Box marginLeft={3}>
                <Button
                  onClick={() => {
                    setExamId(null);
                    setCourseName('');
                    setstartDateTime('');
                    setendDateTime('');
                    setstudentIds('');
                    setShowAddAPExam(true);
                    setShowAddNotes(false);
                    setToggleOption(!toggleOption);
                  }}>
                  Schedule New AP Exam
                </Button>
              </Box>
            </Stack>
            <Box marginBottom={2} marginTop={2}>
              <Divider />
            </Box>
            <Box marginBottom={1}>
              <Typography>
                <strong>New Notes</strong>
              </Typography>
            </Box>
            {assessmentNotes?.length > 0 ? (
              assessmentNotes?.map((row, index) => {
                return (
                  <Stack key={index} direction={'row'} marginBottom={2}>
                    <Stack direction={'row'} spacing={4}>
                      <Box minWidth={'80px'}>
                        {row.startDateTime.substring(5, 7) +
                          '-' +
                          row.startDateTime.substring(8, 10) +
                          '-' +
                          row.startDateTime.substring(0, 4)}
                      </Box>
                      <Box minWidth={'200px'} maxWidth={'200px'}>
                        {row.text}
                      </Box>
                    </Stack>
                    <Stack direction={'row'} marginTop={2}>
                      <Box sx={{ marginTop: -3 }}>
                        <Button onClick={() => editNote(row)}>Edit</Button>
                      </Box>
                      <Box sx={{ marginTop: -3 }}>
                        <Button
                          color='error'
                          onClick={() => deleteNote(row.id)}>
                          Delete
                        </Button>
                      </Box>
                    </Stack>
                  </Stack>
                );
              })
            ) : (
              <Typography>No notes found.</Typography>
            )}
            <Box marginBottom={2} marginTop={2}>
              <Divider />
            </Box>
            <Typography>
              <strong>New AP Exams</strong>
            </Typography>
            {assessmentExams?.map((row, index) => {
              return (
                <Stack key={index} direction={'row'} marginBottom={2}>
                  <Stack direction={'row'} spacing={4}>
                    <Box minWidth={'80px'}>
                      {row.startDateTime.substring(5, 7) +
                        '-' +
                        row.startDateTime.substring(8, 10) +
                        '-' +
                        row.startDateTime.substring(0, 4)}
                    </Box>
                    <Box minWidth={'200px'} maxWidth={'200px'}>
                      {row.courseName}
                    </Box>
                  </Stack>
                  <Stack direction={'row'} marginTop={2}>
                    <Box sx={{ marginTop: -3 }}>
                      <Button onClick={() => editExam(row)}>Edit</Button>
                    </Box>
                    <Box sx={{ marginTop: -3 }}>
                      <Button color='error' onClick={() => deleteNote(row.id)}>
                        Delete
                      </Button>
                    </Box>
                  </Stack>
                </Stack>
              );
            })}
            <Box marginBottom={2} marginTop={2}>
              <Divider />
            </Box>
          </Box>
        )}

        {switchedTab === 0 && (
          <Box>
            {loadCalendar ? (
              <Box
                sx={{
                  width: 300,
                  position: 'absolute',
                  left: '50%',
                  top: '48%'
                }}>
                <Box marginLeft={4}>
                  <CircularProgress />
                </Box>
                <Box>
                  <Typography>Please be patient...</Typography>
                </Box>
              </Box>
            ) : (
              <CalendarView
                currentMonth={currentMonth}
                currentYear={currentYear}
                prevMonth={prevMonth}
                nextMonth={nextMonth}
                setAssessmentId={setAssessmentId}
                assessments={assessments}
                dayNumbers={dayNumbers}
                showDetailsForm={showDetailsForm}
                showDetails={showDetails}
                assessmentId={assessmentId}
              />
            )}
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default AssessmentCalendar;