import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery
} from '@reduxjs/toolkit/query/react';
import { apiSliceBuilderQuery } from 'utils/api-builder';
import { RootState, store } from '../store';
import { ENDPOINT_URL, getApiRoutes } from 'utils/constants';
import { Mutex } from 'async-mutex';
import { setAuthentication, cleanUpAuthentication } from 'screens/login/slice';

const REFRESH_TOKEN_URL = getApiRoutes('Refresh');

const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
  baseUrl: ENDPOINT_URL,
  prepareHeaders: (headers, { getState }) => {
    const { userAuth, googleSSOCredentials } = (getState() as RootState)
      .authentication;
    if (googleSSOCredentials) {
      headers.set('Authorization', `Bearer ${googleSSOCredentials}`);
    }
    if (userAuth?.jwt) {
      headers.set('Authorization', `Bearer ${userAuth?.jwt}`);
    }
    return headers;
  }
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  if (typeof args === 'object' && args?.url === undefined) {
    return;
  }
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (
    (result?.error && result?.error?.status === 401) ||
    // @ts-ignore
    result?.error?.originalStatus === 401 //
  ) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const state = store?.getState();
        const { refreshToken } = state.authentication.userAuth;
        if (!refreshToken) {
          release();
          return;
        }
        const refreshResult = await baseQuery(
          REFRESH_TOKEN_URL + refreshToken,
          api,
          extraOptions
        );
        if (refreshResult.data) {
          api.dispatch(setAuthentication({ userAuth: refreshResult.data }));
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(cleanUpAuthentication());
          window.location.href = '/';
          release();
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const rtkApiSlice: any = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => apiSliceBuilderQuery(builder)
});
