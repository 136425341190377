import { Box, Button, Checkbox, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import AttachmentIcon from '@mui/icons-material/Attachment';
import Autocomplete from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';

import './FieldTripForm.css';

function FieldTripAuxillaryInfo({
  handleSetFile,
  eventId,
  eventDetails,
  submitting,
  employees,
  next,
  setNext,
  handleInput
}) {
  const isAdjusted = useMediaQuery('(min-width:768px)');
  const [leader, setLeader] = useState('');
  const [divisionHead, setDivisionHead] = useState();
  const [preaprovalDate, setPreaprovalDate] = useState('');
  const [attendeeCount, setAttendeeCount] = useState(0);

  const handleInputData = (confirmed) => {
    handleInput({
      leader,
      divisionHead,
      preaprovalDate,
      attendeeCount
    });
    setNext(2);
  };

  return next === 1 ? (
    <>
      <Box
        sx={{
          border: 'solid 1px lightgray',
          padding: 2,
          borderRadius: 4
        }}
        marginBottom={2}>
        <Box
          alignItems={'center'}
          marginBottom={1}
          width={isAdjusted ? '50%' : '100%'}>
          <Box alignItems={'center'} marginBottom={1}>
            <Box>
              <Typography component={'div'}>Attachment</Typography>
            </Box>
            <Box>
              <input
                variant='outlined'
                size='small'
                onChange={(e) => handleSetFile(e)}
                type='file'
                className='file-upload'
                accept='image/png, image/jpeg, .pdf, .txt, .doc, docx'
              />
            </Box>
          </Box>
          <Box alignItems={'center'} marginBottom={1}>
            <Box>
              <Typography component={'div'}>Existing Attachment</Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            border: 'solid 1px lightgray',
            borderRadius: '4px',
            width: 'fit-content',
            padding: '15px',
            marginBottom: 3,
            marginTop: 3
          }}>
          {eventDetails?.attachments?.length > 0
            ? eventDetails?.attachments?.map((files, fileIndex) => {
                return (
                  <Box
                    key={fileIndex}
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    marginBottom={1}>
                    <Box marginRight={1}>
                      <AttachmentIcon sx={{ color: 'GrayText' }} />{' '}
                    </Box>
                    <Box>
                      <div>{files.fileName}</div>
                    </Box>
                  </Box>
                );
              })
            : null}
        </Box>
        <Box alignItems={'center'} marginBottom={3}>
          <Box>
            <Typography>Leader</Typography>
          </Box>
          <Box>
            <Autocomplete
              disablePortal
              id='combo-box-demo'
              onChange={(event, newValue) => setLeader(newValue?.id)}
              value={employees?.find((option) => option.id === leader)}
              options={employees}
              // sx={{ width: 300 }}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                if (option.firstName + option.lastName) {
                  return option.firstName + ' ' + option.lastName;
                }
                return option.firstName + ' ' + option.lastName;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option.firstName + ' ' + option.lastName}</li>
              )}
              renderInput={(params) => <TextField {...params} label='' />}
            />
          </Box>
          <Box>
            <Button>Add a second Leader</Button>
          </Box>
        </Box>
        <Box
          sx={{
            border: 'solid 1px lightgray',
            width: 'fit-content',
            padding: '3px',
            borderRadius: '5px',
            backgroundColor: '#F0F7FF',
            maxHeight: '100px',
            wordWrap: '-moz-initial'
          }}
          marginBottom={3}>
          <Typography marginTop={1}>
            Note that Event Forms will not be sent for approval until Room use
            has been cleared by the Registrar
          </Typography>
        </Box>
        <Box width={isAdjusted ? '50%' : '100%'}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent='space-between'
            alignItems={'center'}>
            <Typography>Division Head Approved</Typography>
            <Checkbox
              label='Label'
              value={divisionHead}
              checked={divisionHead}
              onChange={(e) => setDivisionHead(e.target.checked)}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent='space-between'
            alignItems={'center'}>
            <Typography>Room Use Approved</Typography>
            <Checkbox label='Label' disabled={true} checked={true} />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent='space-between'
            alignItems={'center'}>
            <Typography>Date of Calendar Approval</Typography>
            <input
              type='date'
              className='date'
              value={preaprovalDate || Date()}
              style={{ backgroundColor: '#f3f5f8', width: '100%' }}
              onChange={(e) => {
                setPreaprovalDate(e.target.value);
              }}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent='space-between'
            alignItems={'center'}>
            <Typography>Expected Number of Attendees</Typography>
            <TextField
              margin='normal'
              required
              id='event-name'
              style={{ width: 'fit-content', borderColor: 'lightgray' }}
              autoFocus
              type={'number'}
              size='small'
              value={
                attendeeCount
                  ? attendeeCount
                  : eventDetails?.attendeeCount || ''
              }
              onChange={(e) => setAttendeeCount(e.target.value)}
            />
          </Box>
        </Box>
        {submitting && !divisionHead ? (
          <Typography fontSize={10} color={'red'} marginLeft={2}>
            Division Head Approval Required
          </Typography>
        ) : null}
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'flex-end'}
        marginBottom={3}>
        <Button onClick={(e) => setNext(0)}>Back</Button>
        <Button onClick={(e) => handleInputData()}>Next</Button>
      </Box>
    </>
  ) : (
    <></>
  );
}

export default FieldTripAuxillaryInfo;
