import { put, takeEvery } from 'redux-saga/effects';
import { rtkApiSlice } from 'utils/rtk-api-slices';

function* handleGetUserRole() {
  const promise = yield put(rtkApiSlice.endpoints.Roles.initiate());
  const response = yield promise;
  if (response.isSuccess) {
    const roles = response.data;
    yield put({ type: 'auth/setUserRoles', payload: roles });
  }
}

function* getUserProfile() {
  const promise = yield put(rtkApiSlice.endpoints.UserSelf.initiate());
  const response = yield promise;
  if (response.isSuccess) {
    const profile = response.data;
    yield put({ type: 'auth/setUserProfile', payload: profile });
  }
}
function* rootSaga() {
  yield takeEvery('auth/setAuthentication', handleGetUserRole);
  yield takeEvery('auth/setAuthentication', getUserProfile);
}

export default rootSaga;
