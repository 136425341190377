import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import MiddleHeader from '../../../components/headers/MiddleHeader';
import { rtkApiSlice } from 'utils/rtk-api-slices';
import ErrorMessage from './ErrorMessage';
import FieldTripDetailsChaperone from './FieldTripDetailsChaperone';
import FieldTripDetailsContact from './FieldTripDetailsContact';
import FieldTripAuxillaryInfo from './FieldTripAuxillaryInfo';
import FieldTripBasicInfo from './FieldTripBasicInfo';
import './FieldTripForm.css';

const handleFileUpload = async (file, accessToken, eventId) => {
  const formData = new FormData();
  formData.append('file', file);

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    body: formData
  };

  const response = await fetch(
    `https://forms-dev.winsor.edu/api/events/${eventId}/file-upload`,
    requestOptions
  );
  console.log('file', response); //nothing more
  // loadData();
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 2, ml: 2 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function FieldTripForm() {
  const storeValues = useSelector((state: any) => ({
    userId: state?.authentication?.userAuth.userId,
    accessToken: state?.authentication?.userAuth.jwt,
    isTemplate: state?.template?.is_template
  }));
  const isTemplate = storeValues?.isTemplate;
  const userId = storeValues?.userId;
  const accessToken = storeValues?.accessToken;
  const [submitting, setSubmitting] = useState();
  const [eventDetails, setEventDetails] = useState();
  const [eventName, setEventName] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [locationArray, setLocationArray] = useState([]);
  const [rooms, setRooms] = useState();
  const [roomDynamic, setRoomDynamic] = useState([]);
  const [room, setRoom] = useState();
  const [customDynamicRoom, setCustomDyamicRoom] = useState([]);
  const [roomValidation, setRoomValidation] = useState(false);
  const [customRoomValidation, setCustomRoomValidation] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [custom, setCustom] = useState();
  const [file, setFile] = useState();
  const [leader, setLeader] = useState('');
  const [employees, setEmployees] = useState();
  const [contacts, setContacts] = useState();
  const [divisionHead, setDivisionHead] = useState();
  const [preaprovalDate, setPreaprovalDate] = useState('');
  const [attendeeCount, setAttendeeCount] = useState(0);
  const [primaryContactId, setPrimaryContactId] = useState('');
  const [showSubmitError, setShowSubmitError] = useState(false);
  const [studentCount, setStudentCount] = useState({});
  const [quantitiesFullList, setQuantitiesFullList] = useState({
    itemId: '',
    quantity: ''
  });
  const [usePublicTransit, setUsePublicTransit] = useState('');
  const [noOrganizedTransit, setNoOrganizedTransit] = useState('');
  const [hasVehicleRequest, setHasVehicleRequest] = useState('');
  const [hasBusRequest, setHasBusRequest] = useState('');
  const [busCount, setBusCount] = useState('');
  const [departureTime, setDepartureTime] = useState('');
  const [busPickupTime, setBusPickupTime] = useState('');
  const [returnTime, setReturnTime] = useState('');
  const [instructions, setInstructions] = useState('');
  const [vehicles, setVehicles] = useState('');
  const [numberNeeded, setNumberNeeded] = useState('');
  const [notes, setNotes] = useState('');
  const [categoryId, setcategoryId] = useState('');
  const [numberOfLunches, setNumberOfLunches] = useState('');
  const [diningCount, setDiningCount] = useState('');
  const [eatingAway, setEatingAway] = useState('');
  const [pickupTime, setPickupTime] = useState('');
  const [menu, setMenu] = useState([]);
  const [menuItems, setMenuItems] = useState();
  const [chaparoneDynamic, setChaparoneDynamic] = useState([]);
  const [finalArray, setFinalArray] = useState([]);
  const [open, setOpen] = useState(false);
  const [serverError, setServerError] = useState('');
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [hasLunch, setHasLunch] = useState('');
  const [chaparonesArray, setChaparonesArray] = useState([]);
  const [verifyCellphone, setVerifyCellphone] = useState('');
  const [verifyEmail, setVerifyEmail] = useState('');
  const [next, setNext] = useState(0);

  let route = useParams();
  const navigate = useNavigate();
  const [eventId, setEventId] = useState(''); //7p48PPQ58zWJ
  useEffect(() => {
    setEventId(route?.eventId);
  }, [route?.eventId]);

  const [userSelfContact] = rtkApiSlice.useUserSelfContactMutation();

  const userSelfContacts = rtkApiSlice.useUserSelfContactsQuery();
  const eventLocationReponse = rtkApiSlice.useEventQuery({
    url: `/events${process.env.REACT_APP_ENDPOINT_LOCATION}`
  });
  const employeesResponse = rtkApiSlice.useEmployeesQuery();
  const eventCaterReponse = rtkApiSlice.useEventQuery({
    url: `/events${process.env.REACT_APP_ENDPOINT_MENU}`
  });
  useEffect(() => {
    setContacts(userSelfContacts?.data);
    setRooms(eventLocationReponse?.data);
    setEmployees(employeesResponse?.data);
    setMenu(eventCaterReponse?.data);
  }, [
    userSelfContacts,
    eventLocationReponse,
    employeesResponse,
    eventCaterReponse
  ]);

  const [userEventActions, userEventActionsReponse] =
    rtkApiSlice.useUserEventActionsMutation();
  //event details
  const eventReponse = rtkApiSlice.useEventQuery({ url: `/events/${eventId}` }),
    eventHandler = data => {
      if (data && data?.length > 0 && eventId) {
        setEventDetails(data);
        setEventName(data?.summary);
        setDescription(data?.description);
        setLeader(data?.leaderId);
        data?.selectedCustomLocations?.length &&
          data?.selectedLocations &&
          (data?.selectedLocations?.length >
          data?.selectedCustomLocations?.length
            ? setLocationArray(data?.selectedLocations)
            : setLocationArray(data?.selectedCustomLocations));
        setRoomDynamic(data?.selectedLocations);
        setCustomDyamicRoom(data?.selectedCustomLocations);
        setStartDate(data?.start?.substring(0, 10));
        setStartTime(data?.start?.substring(11, 16));
        setEndDate(data?.end?.substring(0, 10));
        setEndTime(data?.end?.substring(11, 16));
        setEventName(data?.summary);
        setDescription(data?.description);
        setPreaprovalDate(data?.preaprovalDate);
        setDivisionHead(true);
      }
    };
  useEffect(() => {
    eventHandler(eventReponse?.data);
  }, [eventId, userEventActionsReponse?.data]);

  //location
  const eventCustomLocationReponse = rtkApiSlice.useCustomLocationQuery();
  useEffect(() => {
    setCustom(eventCustomLocationReponse?.data);
  }, [eventCustomLocationReponse?.data]);

  //Vehicles
  const eventVehiclesReponse = rtkApiSlice.useVehiclesQuery(
    process.env.REACT_APP_ENDPOINT_VEHICLES
  );
  useEffect(() => {
    setVehicles(eventVehiclesReponse?.data);
  }, [eventVehiclesReponse?.data]);

  //lunch
  const eventLunchReponse = rtkApiSlice.useEventQuery({
      url: `/events/${eventId}${process.env.REACT_APP_ENDPOINT_LUNCH}`
    }),
    lunchHandler = data => {
      if (data && eventId) {
        setNumberOfLunches(data?.numberOfLunches);
        setDiningCount(data?.diningInCount);
        setEatingAway(data?.eatingAway);
        setMenuItems(data?.menuItems);
        setPickupTime(data?.pickupTime);
      }
    };
  useEffect(() => {
    lunchHandler(eventLunchReponse?.data);
  }, [eventLunchReponse?.data]);

  //Transportation
  const eventTransportationReponse = rtkApiSlice.useEventQuery({
      url: `/events/${eventId}${process.env.REACT_APP_ENDPOINT_TRANSPORTATION}`
    }),
    transportationHandler = data => {
      if (data && eventId) {
        setHasBusRequest(data?.hasBusRequest);
        setHasVehicleRequest(data?.hasVehicleRequest);
        setNoOrganizedTransit(data?.noOrganizedTransit);
        setUsePublicTransit(data?.usePublicTransit);
      }
    };
  useEffect(() => {
    transportationHandler(eventTransportationReponse?.data);
  }, [eventTransportationReponse?.data]);

  //Buses
  const eventBusesReponse = rtkApiSlice.useEventQuery({
      url: `/events/${eventId}${process.env.REACT_APP_ENDPOINT_BUSES}`
    }),
    busesHandler = data => {
      if (data && eventId) {
        setBusCount(data?.busCount);
        setDepartureTime(data?.departureTime?.substring(0, 5));
        setBusPickupTime(data?.busPickupTime?.substring(0, 5));
        setReturnTime(data?.returnTime?.substring(0, 5));
        setInstructions(data?.instructions);
      }
    };
  useEffect(() => {
    busesHandler(eventBusesReponse?.data);
  }, [eventBusesReponse?.data]);

  //Vehicles - events
  const eventVehicleReponse = rtkApiSlice.useEventQuery({
      url: `/events/${eventId}${process.env.REACT_APP_ENDPOINT_VEHICLE}`
    }),
    vehicleHandler = data => {
      if (data && eventId) {
        // setCategory(data[0]?.category);
        setcategoryId(data[0]?.categoryId);
        setNumberNeeded(data[0]?.numberNeeded);
        setNotes(data[0]?.notes);
      }
    };
  useEffect(() => {
    vehicleHandler(eventVehicleReponse?.data);
  }, [eventVehicleReponse?.data]);

  //field trip - events
  const eventFieldTripeReponse = rtkApiSlice.useEventQuery({
      url: `/events/${eventId}${process.env.REACT_APP_ENDPOINT_FIELD_TRIPE}`
    }),
    fieldTripeHandler = data => {
      if (data && eventId) {
        // setPrimaryContact(data?.primaryContact);//
        setPrimaryContactId(data?.primaryContact?.id);
        setVerifyEmail(data?.primaryContact?.email);
        setVerifyCellphone(data?.primaryContact?.phone);
        setStudentCount(data?.studentCount);
        setHasLunch(data?.hasLunch);
        setChaparonesArray(data?.chaparoneIds);
        setChaparoneDynamic(data?.chaparoneIds);
      }
    };
  useEffect(() => {
    fieldTripeHandler(eventFieldTripeReponse?.data);
  }, [eventFieldTripeReponse?.data]);

  const [eventUpdate, eventUpdateResponse] =
    rtkApiSlice.useEventUpdateMutation();

  const updateContactInfo = async id => {
    if (id && primaryContactId) {
      var data = {
        primaryContactId: primaryContactId,
        studentCount: studentCount
      };
      await userEventActions({
        body: data,
        params: `/${id}/field-trip`
      });
    }
  };
  const updateChaperoneInfo = async () => {
    if (primaryContactId) {
      await userEventActions({
        body: chaparoneDynamic,
        params: `/${eventId}/field-trip/chaperones`
      });
    }
  };

  const submit = async () => {
    var data = {
      summary: eventName,
      description: description,
      type: 'Field Trip',
      start: startDate + 'T' + startTime + ':00',
      end: endDate + 'T' + endTime + ':00',
      creatorId: userId,
      leaderId: leader,
      preaprovalDate: preaprovalDate,
      attendeeCount: parseInt(attendeeCount),
      selectedLocations: selectedLocations,
      selectedCustomLocations:
        customDynamicRoom[0] !== '' ? customDynamicRoom : []
    };
    setSubmitting(true);

    if (quantitiesFullList?.itemId) {
      quantitiesFullList?.map((outter, outterIndex) => {
        <div key={outterIndex}></div>;
        if (outter.quantity > 0) {
          finalArray.push({
            itemId: outter.itemId,
            quantity: parseInt(outter.quantity)
          });
        }
        return null;
      });
    }

    if (
      divisionHead &&
      eventName &&
      description &&
      startDate &&
      endDate &&
      leader &&
      preaprovalDate &&
      endTime &&
      startTime
    ) {
      if (eventId && !isTemplate) {
        console.log('is supposed to work ');

        const { data: res } = await eventUpdate({
          body: data,
          params: `/${eventId}`,
          method: 'PUT'
        });
        console.log('res 1', res);

        if (res) {
          if (hasBusRequest) {
            submitHiredOutsideBusses(res.id);
          }
          if (hasVehicleRequest) {
            submitWinsorVehicles(res.id);
          }
          // if (showLunch) {
          //   submitLunch(res.id);
          // }
          if (chaparoneDynamic.length > 0) {
            updateChaperoneInfo();
          }
          updateContactInfo(res.id);
          completeUpdate();
        }
      } else {
        console.log('in post ');

        const { data: res } = await userEventActions(data);

        if (res) {
          if (hasBusRequest) {
            submitHiredOutsideBusses(res.id);
          }
          if (hasVehicleRequest) {
            submitWinsorVehicles(res.id);
          }
          // if (showLunch) {
          //   submitLunch(res.id);
          // }
          if (chaparoneDynamic.length > 0) {
            updateChaperoneInfo();
          }
          updateContactInfo(res.id);
          completeSubmission(res.id);
        }
      }
      if (file) {
        handleFileUpload(file, accessToken, eventId);
      }
      setShowSubmitError(false);
      //
    } else {
      setShowSubmitError(true);
    }
  };

  const completeUpdate = async () => {
    if (eventId) {
      const { error, isSuccess, isError } = await eventUpdate({
        url: `/events/${eventId}/complete-update`
      });
      if (isSuccess) {
        navigate('/event-forms');
      }
      if (isError) {
        setServerError(error);
        handleClickOpen();
      }
    }
  };
  const completeSubmission = async id => {
    if (id) {
      const { isSuccess } = await eventUpdate({
        url: `/events/${id}/complete-submission`
      });
      if (isSuccess) {
        navigate('/event-forms');
      }
    }
  };

  const submitHiredOutsideBusses = async id => {
    var data = {
      usePublicTransit: usePublicTransit,
      noOrganizedTransit: noOrganizedTransit,
      hiredBusses: {
        busCount: busCount,
        departureTime: departureTime + ':00',
        busPickupTime: busPickupTime + ':00',
        returnTime: returnTime + ':00',
        instructions: instructions
      }
    };
    if (id) {
      await userEventActions({
        body: data,
        params: `/${id}/field-trip/transportation`
      });
    }
  };

  const submitWinsorVehicles = async id => {
    var data = {
      usePublicTransit: usePublicTransit,
      noOrganizedTransit: noOrganizedTransit,
      vehicleRequests: [
        {
          categoryId: categoryId,
          number: numberNeeded,
          notes: notes
        }
      ]
    };
    if (id) {
      await userEventActions({
        body: data,
        params: `/${id}/field-trip/transportation`
      });
    }
  };

  const submitLunch = async id => {
    var data = {
      numberOfLunches: numberOfLunches,
      diningCount: diningCount,
      eatingAway: eatingAway,
      pickupTime: pickupTime,
      menuItemIds: quantitiesFullList
    };
    if (id) {
      await userEventActions({
        body: data,
        params: `/${id}/field-trip/lunch`
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetFile = event => {
    setFile(event.target?.files[0]);
  };

  return (
    <Grid>
      <Stack marginBottom={10}>
        <ErrorMessage
          open={open}
          handleClose={handleClose}
          serverError={serverError}
        />
        {(next === 0 || next === 1) && (
          <Box style={{ marginBottom: 50, marginTop: 50 }}>
            <MiddleHeader
              title={eventName ? eventName : 'Field Trip'}
              variant='h4'
            />
            <MiddleHeader title='Basic Event Details' />
          </Box>
        )}
        <FieldTripBasicInfo
          rooms={rooms}
          submitting={submitting}
          setRoom={setRoom}
          custom={custom}
          setCustomRoomValidation={setCustomRoomValidation}
          customRoomValidation={customRoomValidation}
          locationArray={locationArray}
          setLocationArray={setLocationArray}
          roomDynamic={roomDynamic}
          setRoomDynamic={setRoomDynamic}
          selectedLocations={selectedLocations}
          setSelectedLocations={setSelectedLocations}
          customDynamicRoom={customDynamicRoom}
          setCustomDyamicRoom={setCustomDyamicRoom}
          roomValidation={roomValidation}
          setRoomValidation={setRoomValidation}
          next={next}
          setNext={setNext}
          handleInput={({
            eventName,
            description,
            startDate,
            endDate,
            startTime,
            endTime
          }) => {
            setEventName(eventName);
            setDescription(description);
            setStartDate(startDate);
            setEndDate(endDate);
            setStartTime(startTime);
            setEndTime(endTime);
          }}
        />

        <FieldTripAuxillaryInfo
          handleSetFile={handleSetFile}
          eventId={eventId}
          eventDetails={eventDetails}
          submitting={submitting}
          employees={employees}
          next={next}
          setNext={setNext}
          handleInput={({
            leader,
            divisionHead,
            preaprovalDate,
            attendeeCount
          }) => {
            setLeader(leader);
            setDivisionHead(divisionHead);
            setPreaprovalDate(preaprovalDate);
            setAttendeeCount(attendeeCount);
          }}
        />

        {(next === 2 || next === 3) && (
          <Box style={{ marginBottom: 50, marginTop: 50 }}>
            <MiddleHeader title='Field Trip Details' variant='h4' />
          </Box>
        )}

        <FieldTripDetailsContact
          customRoomValidation={customRoomValidation}
          userSelfContact={userSelfContact}
          studentCount={studentCount}
          primaryContactId={primaryContactId}
          setPrimaryContactId={setPrimaryContactId}
          contacts={contacts}
          verifyCellphone={verifyCellphone}
          setVerifyCellphone={setVerifyCellphone}
          verifyEmail={verifyEmail}
          setVerifyEmail={setVerifyEmail}
          next={next}
          setNext={setNext}
        />

        <FieldTripDetailsChaperone
          eventId={eventId}
          customRoomValidation={customRoomValidation}
          userSelfContact={userSelfContact}
          contacts={contacts}
          menu={menu}
          menuItems={menuItems}
          vehicles={vehicles}
          primaryContactId={primaryContactId}
          quantitiesFullList={quantitiesFullList}
          setQuantitiesFullList={setQuantitiesFullList}
          studentCount={studentCount}
          setStudentCount={setStudentCount}
          usePublicTransit={usePublicTransit}
          setUsePublicTransit={setUsePublicTransit}
          noOrganizedTransit={noOrganizedTransit}
          setNoOrganizedTransit={setNoOrganizedTransit}
          hasVehicleRequest={hasVehicleRequest}
          setHasVehicleRequest={setHasVehicleRequest}
          hasBusRequest={hasBusRequest}
          setHasBusRequest={setHasBusRequest}
          busCount={busCount}
          setBusCount={setBusCount}
          departureTime={departureTime}
          setDepartureTime={setDepartureTime}
          busPickupTime={busPickupTime}
          setBusPickupTime={setBusPickupTime}
          returnTime={returnTime}
          setReturnTime={setReturnTime}
          instructions={instructions}
          setInstructions={setInstructions}
          numberNeeded={numberNeeded}
          setNumberNeeded={setNumberNeeded}
          notes={notes}
          setNotes={setNotes}
          categoryId={categoryId}
          setcategoryId={setcategoryId}
          numberOfLunches={numberOfLunches}
          setNumberOfLunches={setNumberOfLunches}
          diningCount={diningCount}
          setDiningCount={setDiningCount}
          eatingAway={eatingAway}
          setEatingAway={setEatingAway}
          pickupTime={pickupTime}
          setPickupTime={setPickupTime}
          hasLunch={hasLunch}
          setHasLunch={setHasLunch}
          chaparonesArray={chaparonesArray}
          setChaparonesArray={setChaparonesArray}
          next={next}
          setNext={setNext}
        />
        {next === 3 && (
          <>
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'flex-end'}
              marginBottom={3}>
              <Button onClick={e => setNext(2)}>Back</Button>
              <Button onClick={e => submit()}>Submit</Button>
            </Box>
            {submitting && showSubmitError ? (
              <Typography fontSize={9} color={'red'}>
                Must Complete all Required Fields
              </Typography>
            ) : null}
          </>
        )}
      </Stack>
    </Grid>
  );
}

export default FieldTripForm;