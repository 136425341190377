export const getUserRoles = (data = []) => {
  return {
    isStudent: data.includes('Student'),
    isTeacher: data.includes('Teacher'),
    isAdvisor: data.includes('Advisor'),
    isWinsorAdminAssistant: data.includes('Winsor - Admin Asst.'),
    isWinsorRegistrar: data.includes('Winsor - Registrar'),
    isNonTeachingStaff: data.includes('Non-Teaching Staff'),
    isSecurity: data.includes('Security'),
    isSystemAdmin: data.includes('System Admin'),
    isEventsAdmin: data.includes('Winsor - Events Admin')
  };
};
