import dayjs from 'dayjs';

const weekdays = {
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday'
};

const months = {
  jan: 'January',
  feb: 'Feburary',
  mar: 'March',
  apr: 'April',
  may: 'May',
  jun: 'June',
  jul: 'July',
  aug: 'August',
  sep: 'September',
  oct: 'October',
  nov: 'November',
  dec: 'December'
};

function dayOfWeekName(DateOfWeek) {
  if (!DateOfWeek) {
    return DateOfWeek;
  }
  const dateToArray = DateOfWeek.toDate()
    .toDateString()
    .toLowerCase()
    .split(' ');
  const dayOfWeek = dateToArray[0];
  return weekdays[dayOfWeek];
}

function nameOfMonth(DateOfWeek) {
  if (!DateOfWeek) {
    return DateOfWeek;
  }
  const dateToArray = DateOfWeek.toDate()
    .toDateString()
    .toLowerCase()
    .split(' ');
  const monthOfday = dateToArray[1];
  return months[monthOfday];
}

function generateDateWeekly(firstDateOfWeek, lastDateOfWeek) {
  let days = {};
  let daysInMonth = {}; //this is separated to capture existing flow of the system

  for (
    let index = firstDateOfWeek.day();
    index < lastDateOfWeek.day();
    index++
  ) {
    const workDay = dayOfWeekName(lastDateOfWeek.day(index));
    const dayOfMonth = nameOfMonth(firstDateOfWeek.day(index));
    if (workDay) {
      days[workDay] = firstDateOfWeek.day(index).date();
      daysInMonth[workDay] = dayOfMonth;
    }
  }

  return { days, daysInMonth };
}

export function currentWeekDays(
  month = dayjs().month(),
  year = dayjs().year()
) {
  const firstDateOfWeek = dayjs().year(year).month(month).startOf('week');
  const lastDateOfWeek = dayjs().year(year).month(month).endOf('week');

  return generateDateWeekly(firstDateOfWeek, lastDateOfWeek);
}

export function navigationToPreviousWeekDays(
  month = dayjs().month(),
  year = dayjs().year(),
  days = 7
) {
  const firstDateOfWeeks = dayjs().year(year).month(month).startOf('weeks');
  const lastDateOfWeeks = dayjs().year(year).month(month).endOf('weeks');

  const firstDateOfWeek = firstDateOfWeeks.subtract(days, 'days'),
    lastDateOfWeek = lastDateOfWeeks.subtract(days, 'days');
  return generateDateWeekly(firstDateOfWeek, lastDateOfWeek);
}

export function navigationToNextWeekDays(
  month = dayjs().month(),
  year = dayjs().year(),
  days = 7
) {
  const firstDateOfWeeks = dayjs().year(year).month(month).startOf('weeks');
  const lastDateOfWeeks = dayjs().year(year).month(month).endOf('weeks');

  const firstDateOfWeek = firstDateOfWeeks.add(days, 'days'),
    lastDateOfWeek = lastDateOfWeeks.add(days, 'days');
  return generateDateWeekly(firstDateOfWeek, lastDateOfWeek);
}
